[
  {
    "ko": "Backovica",
    "mbr": 300900,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bedenicka",
    "mbr": 300918,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bedenik",
    "mbr": 300926,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bjelovar",
    "mbr": 300934,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bjelovar-Sredice",
    "mbr": 300942,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Nove Plavnice-Hrgovljani",
    "mbr": 300969,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Brezovac",
    "mbr": 300977,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ciglena",
    "mbr": 300985,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Cadavac",
    "mbr": 300993,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dautan",
    "mbr": 301019,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Diklenica",
    "mbr": 301027,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Drljanovac",
    "mbr": 301035,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Galovac",
    "mbr": 301043,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Gornje Plavnice",
    "mbr": 301051,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Sredice Gornje",
    "mbr": 301060,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Križ Gornji",
    "mbr": 301078,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Gudovac",
    "mbr": 301086,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Gornje Rovišce",
    "mbr": 301094,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kakinac",
    "mbr": 301108,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kapela",
    "mbr": 301116,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Klokocevac",
    "mbr": 301124,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kobasicari",
    "mbr": 301132,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Veliko Korenovo",
    "mbr": 301159,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kozarevac Racanski",
    "mbr": 301167,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kraljevac",
    "mbr": 301175,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Lasovac",
    "mbr": 301183,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Malo Trojstvo",
    "mbr": 301191,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Trojstveni Markovac",
    "mbr": 301205,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Meduraca",
    "mbr": 301213,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mosti",
    "mbr": 301221,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Nevinac",
    "mbr": 301230,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Nova Raca",
    "mbr": 301248,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Obrovnica",
    "mbr": 301256,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Orlovac",
    "mbr": 301264,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Orovac",
    "mbr": 301272,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Pavlin Kloštar",
    "mbr": 301299,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Podgorci",
    "mbr": 301302,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Predavac",
    "mbr": 301329,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Prespa",
    "mbr": 301337,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Prgomelje",
    "mbr": 301345,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Pupelica",
    "mbr": 301353,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Rajic Gudovacki",
    "mbr": 301361,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ravneš",
    "mbr": 301370,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ribnjacka",
    "mbr": 301388,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Rovišce",
    "mbr": 301396,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Sasovac",
    "mbr": 301400,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Severin",
    "mbr": 301418,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Slovinska Kovacica",
    "mbr": 301426,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Stare Plavnice",
    "mbr": 301434,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Šandrovac",
    "mbr": 301442,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Tomaš",
    "mbr": 301469,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Velika Pisanica",
    "mbr": 301477,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Veliko Trojstvo",
    "mbr": 301485,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Višnjevac",
    "mbr": 301493,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Zdelice",
    "mbr": 301507,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Zrinski Topolovac",
    "mbr": 301515,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Žabjak",
    "mbr": 301523,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ždralovi",
    "mbr": 301531,
    "okn": "Bjelovar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Blatnica",
    "mbr": 303747,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bojana",
    "mbr": 303755,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bosiljevo",
    "mbr": 303763,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Cerina",
    "mbr": 303771,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Čazma",
    "mbr": 303780,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dapci",
    "mbr": 303798,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Daskatica",
    "mbr": 303801,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dereza",
    "mbr": 303810,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Draganec",
    "mbr": 303828,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Đurdic",
    "mbr": 303836,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ivanska",
    "mbr": 303844,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Križic",
    "mbr": 303852,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Laminac",
    "mbr": 303879,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Lipovcani",
    "mbr": 303887,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Martinac",
    "mbr": 303895,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Miklouš",
    "mbr": 303909,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Narta",
    "mbr": 303917,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Petricka",
    "mbr": 303925,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Pobjenik",
    "mbr": 303933,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Samarica",
    "mbr": 303941,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Sišcani",
    "mbr": 303950,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Srijedska",
    "mbr": 303968,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Stara Plošcica",
    "mbr": 303976,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Štefanje",
    "mbr": 303984,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Šušnjara",
    "mbr": 303992,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Vagovina",
    "mbr": 304000,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Vrtlinska",
    "mbr": 304018,
    "okn": "Čazma",
    "puk": "Bjelovar"
  },
  {
    "ko": "Vukovije",
    "mbr": 304026,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bijela",
    "mbr": 304034,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Blagorodovac",
    "mbr": 304042,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Borki",
    "mbr": 304069,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bastajski Brdani",
    "mbr": 304077,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Brestovac",
    "mbr": 304085,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Cjepidlake",
    "mbr": 304093,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Daruvar",
    "mbr": 304107,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dežanovac",
    "mbr": 304115,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dobra Kuca",
    "mbr": 304123,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Doljani",
    "mbr": 304131,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Donji Daruvar",
    "mbr": 304140,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Donji Sredani",
    "mbr": 304158,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Golubinjak",
    "mbr": 304166,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Gornji Daruvar",
    "mbr": 304174,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Gornji Sredani",
    "mbr": 304182,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Govede Polje",
    "mbr": 304204,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Imsovac",
    "mbr": 304212,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kip",
    "mbr": 304239,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Klisa",
    "mbr": 304247,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Koncanica",
    "mbr": 304255,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Korenicani",
    "mbr": 304263,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kreštelovac",
    "mbr": 304271,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Krivaja",
    "mbr": 304280,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mali Bastaji",
    "mbr": 304298,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Markovac",
    "mbr": 304301,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Miljanovac",
    "mbr": 304310,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Đulovac",
    "mbr": 304328,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Pakrani",
    "mbr": 304336,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Potocani",
    "mbr": 304344,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Puklica",
    "mbr": 304352,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Removac",
    "mbr": 304379,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Sirac",
    "mbr": 304387,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Sokolovac",
    "mbr": 304395,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Šibovac",
    "mbr": 304409,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Trojeglava",
    "mbr": 304417,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Veliki Bastaji",
    "mbr": 304433,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Vrbovac",
    "mbr": 304441,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Vrijeska",
    "mbr": 304450,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Zaile",
    "mbr": 304468,
    "okn": "Daruvar",
    "puk": "Bjelovar"
  },
  {
    "ko": "Uljanik",
    "mbr": 304425,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Berek",
    "mbr": 309613,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Bršljanica",
    "mbr": 309621,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ciglenica",
    "mbr": 309630,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dišnik",
    "mbr": 309648,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Garešnica",
    "mbr": 309656,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Gornja Garešnica",
    "mbr": 309664,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Hercegovac",
    "mbr": 309672,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Hrastovac",
    "mbr": 309699,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kajgana",
    "mbr": 309702,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kaniška Iva",
    "mbr": 309729,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kapelica",
    "mbr": 309737,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ilovski Klokocevac",
    "mbr": 309745,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Kostanjevac",
    "mbr": 309753,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ladislav",
    "mbr": 309761,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mlinska",
    "mbr": 309770,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Nova Plošcica",
    "mbr": 309788,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Palešnik",
    "mbr": 309796,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Pašijan",
    "mbr": 309800,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Veliki Prokop",
    "mbr": 309818,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Ruškovac",
    "mbr": 309826,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Šimljana",
    "mbr": 309834,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Šimljanik",
    "mbr": 309842,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Tomašica",
    "mbr": 309869,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Trnovitica",
    "mbr": 309877,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Trnoviticki Popovac",
    "mbr": 309885,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Velika Trnava",
    "mbr": 309893,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Vukovje",
    "mbr": 309907,
    "okn": "Garešnica",
    "puk": "Bjelovar"
  },
  {
    "ko": "Brzaja",
    "mbr": 311375,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Cremušina",
    "mbr": 311383,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dapcevica",
    "mbr": 311391,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Donja Kovacica",
    "mbr": 311405,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Dražica",
    "mbr": 311413,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Gornja Kovacica",
    "mbr": 311421,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Grbavac",
    "mbr": 311430,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Grubišno Polje",
    "mbr": 311448,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mala Barna",
    "mbr": 311456,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mala Jasenovaca",
    "mbr": 311464,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mala Peratovica",
    "mbr": 311472,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mala Pisanica",
    "mbr": 311499,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mali Grdevac",
    "mbr": 311502,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Mali Zdenci",
    "mbr": 311529,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Pavlovac",
    "mbr": 311537,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Rašenica",
    "mbr": 311545,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Sibenik",
    "mbr": 311553,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Topolovica",
    "mbr": 311561,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Turcevic Polje",
    "mbr": 311570,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Velika Barna",
    "mbr": 311588,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Velika Peratovica",
    "mbr": 311596,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Veliki Grdevac",
    "mbr": 311600,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Veliki Zdenci",
    "mbr": 311618,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Zrinska",
    "mbr": 311626,
    "okn": "Grubišno Polje",
    "puk": "Bjelovar"
  },
  {
    "ko": "Badlican",
    "mbr": 302767,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Belica",
    "mbr": 302775,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Benkovec",
    "mbr": 302783,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Bogdanovec",
    "mbr": 302791,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Čakovec",
    "mbr": 302813,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Crecan",
    "mbr": 302830,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Dekanovec",
    "mbr": 302856,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Domašinec",
    "mbr": 302864,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Dragoslavec",
    "mbr": 302953,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Držimurec",
    "mbr": 302970,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Ferketinec",
    "mbr": 302988,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gardinovec",
    "mbr": 302996,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gornja Dubrava",
    "mbr": 303011,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gornji Hrašcan",
    "mbr": 303020,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gornji Kraljevec",
    "mbr": 303038,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gornji Mihaljevec",
    "mbr": 303046,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gornji Pustakovec",
    "mbr": 303054,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gornji Vidovec",
    "mbr": 303062,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Gradišcak",
    "mbr": 303089,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Hlapicina",
    "mbr": 303119,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Ivanovec",
    "mbr": 303135,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Jurcevec",
    "mbr": 303151,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Krištanovec",
    "mbr": 303178,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Križovec",
    "mbr": 303186,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Kuršanec",
    "mbr": 303194,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Lopatinec",
    "mbr": 303208,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Macinec",
    "mbr": 303216,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Mackovec",
    "mbr": 303224,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Mala Subotica",
    "mbr": 303232,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Sveti Martin na Muri",
    "mbr": 303267,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Martinuševec",
    "mbr": 303275,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Mihovljan",
    "mbr": 303283,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Mursko Središce",
    "mbr": 303291,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Nedelišce",
    "mbr": 303305,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Novakovec",
    "mbr": 303313,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Novo Selo Rok",
    "mbr": 303321,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Orehovica",
    "mbr": 303348,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Palovec",
    "mbr": 303364,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Peklenica",
    "mbr": 303372,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Podbrest",
    "mbr": 303399,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Podturen",
    "mbr": 303402,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Preseka",
    "mbr": 303437,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Pretetinec",
    "mbr": 303445,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Prhovec",
    "mbr": 303453,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Pribislavec",
    "mbr": 303461,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Robadje",
    "mbr": 303470,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Savska Ves",
    "mbr": 303488,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Selnica",
    "mbr": 303496,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Sivica",
    "mbr": 303500,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Slakovec",
    "mbr": 303518,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Stanetinec",
    "mbr": 303526,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Strahoninec",
    "mbr": 303534,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Strelec",
    "mbr": 303542,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Šandorovec",
    "mbr": 303569,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Šenkovec",
    "mbr": 303577,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Štefanec",
    "mbr": 303585,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Štrigova",
    "mbr": 303593,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Štrukovec",
    "mbr": 303607,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Totovec",
    "mbr": 303615,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Trnovec",
    "mbr": 303623,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Turcišce",
    "mbr": 303631,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Sveti Urban",
    "mbr": 303640,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Vratišinec",
    "mbr": 303658,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Vukanovec",
    "mbr": 303666,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Vularija",
    "mbr": 303674,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Zasadbreg",
    "mbr": 303682,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Zebanec",
    "mbr": 303704,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Železna Gora",
    "mbr": 303712,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Žiškovec",
    "mbr": 303739,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Pušcine",
    "mbr": 336998,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Dunjkovec",
    "mbr": 337005,
    "okn": "Čakovec",
    "puk": "Čakovec"
  },
  {
    "ko": "Cirkovljan",
    "mbr": 302805,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Cehovec",
    "mbr": 302821,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Cukovec",
    "mbr": 302848,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Donja Dubrava",
    "mbr": 302872,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Donji Hrašcan",
    "mbr": 302899,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Donji Kraljevec",
    "mbr": 302902,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Donji Mihaljevec",
    "mbr": 302929,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Donji Pustakovec",
    "mbr": 302937,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Donji Vidovec",
    "mbr": 302945,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Draškovec",
    "mbr": 302961,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Gorican",
    "mbr": 303003,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Hemuševec",
    "mbr": 303097,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Hodošan",
    "mbr": 303127,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Sveti Juraj u Trnju",
    "mbr": 303143,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Kotoriba",
    "mbr": 303160,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Sveta Marija",
    "mbr": 303259,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Oporovec",
    "mbr": 303330,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Palinovec",
    "mbr": 303356,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Prelog",
    "mbr": 303429,
    "okn": "Prelog",
    "puk": "Čakovec"
  },
  {
    "ko": "Blato",
    "mbr": 314633,
    "okn": "Blato",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Smokvica",
    "mbr": 314749,
    "okn": "Blato",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Vela Luka",
    "mbr": 314765,
    "okn": "Blato",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Lastovo",
    "mbr": 316717,
    "okn": "Blato",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Babino Polje",
    "mbr": 306100,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Banici",
    "mbr": 306118,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Blato",
    "mbr": 306126,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Boljenovici",
    "mbr": 306134,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Brašina",
    "mbr": 306142,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Brijesta",
    "mbr": 306169,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Broce",
    "mbr": 306177,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Brotnice",
    "mbr": 306185,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Brsecine",
    "mbr": 306193,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Buici",
    "mbr": 306207,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Cavtat",
    "mbr": 306215,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Cajkovica",
    "mbr": 306223,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Celopeci",
    "mbr": 306231,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Cepikuce",
    "mbr": 306240,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Cesvinica",
    "mbr": 306258,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Cibaca",
    "mbr": 306266,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Cilipi",
    "mbr": 306274,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Dancanje",
    "mbr": 306282,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Doli",
    "mbr": 306304,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Donji Brgat",
    "mbr": 306339,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Majkovi Donji",
    "mbr": 306347,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Drvenik",
    "mbr": 306355,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Duba Konavoska",
    "mbr": 306363,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Duba Stonska",
    "mbr": 306380,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Dubravica",
    "mbr": 306398,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Dubravka",
    "mbr": 306401,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Dubrovnik",
    "mbr": 306410,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Dunave",
    "mbr": 306428,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Đonta Doli",
    "mbr": 306436,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Đurinici",
    "mbr": 306444,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Gabrili",
    "mbr": 306452,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Gornji Brgat",
    "mbr": 306487,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Majkovi Gornji",
    "mbr": 306495,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Govedari",
    "mbr": 306509,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Grbavac",
    "mbr": 306517,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Gromaca",
    "mbr": 306525,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Gruda",
    "mbr": 306533,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Hodilje",
    "mbr": 306541,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Imotica",
    "mbr": 306550,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Janjina",
    "mbr": 306568,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Jasenice",
    "mbr": 306576,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Kliševo",
    "mbr": 306584,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Knežica",
    "mbr": 306592,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Kolocep",
    "mbr": 306606,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Komaji",
    "mbr": 306614,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Komolac",
    "mbr": 306622,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Korita",
    "mbr": 306649,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Kuna Konavoska",
    "mbr": 306657,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Lisac",
    "mbr": 306673,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Lopud",
    "mbr": 306681,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Lovorno",
    "mbr": 306690,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Ljubac",
    "mbr": 306703,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Ljuta",
    "mbr": 306711,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Makoše",
    "mbr": 306720,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Maranovici",
    "mbr": 306738,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Martinovici",
    "mbr": 306746,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Mihanici",
    "mbr": 306754,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Mocici",
    "mbr": 306762,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Mokošica",
    "mbr": 306789,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Mravinjac",
    "mbr": 306797,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Mravinjica",
    "mbr": 306819,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Mrcevo",
    "mbr": 306827,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Obod",
    "mbr": 306835,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Obuljeno",
    "mbr": 306843,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Orašac",
    "mbr": 306851,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Osobjava",
    "mbr": 306878,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Osojnik",
    "mbr": 306886,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Ošlje",
    "mbr": 306894,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Palje Brdo",
    "mbr": 306908,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Petraca",
    "mbr": 306916,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Petrovo Selo",
    "mbr": 306924,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Plat",
    "mbr": 306959,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Plocice",
    "mbr": 306967,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Podimoc",
    "mbr": 306975,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Poljice",
    "mbr": 306991,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Popova Luka",
    "mbr": 307009,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Popovici",
    "mbr": 307017,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Pridvorje",
    "mbr": 307033,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Prijevor",
    "mbr": 307041,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Prožura",
    "mbr": 307068,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Putnikovic",
    "mbr": 307076,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Radovcici",
    "mbr": 307084,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Rožat",
    "mbr": 307092,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Slano",
    "mbr": 307106,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Smokovljani",
    "mbr": 307114,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Soline",
    "mbr": 307122,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Sparagovici",
    "mbr": 307149,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Sreser",
    "mbr": 307157,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Ston",
    "mbr": 307165,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Stravca",
    "mbr": 307173,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Stupa",
    "mbr": 307181,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Sudurad",
    "mbr": 307190,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Sustjepan",
    "mbr": 307203,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Šilješki",
    "mbr": 307211,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Šipanska Luka",
    "mbr": 307220,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Šumet",
    "mbr": 307238,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Tocionik",
    "mbr": 307246,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Tomislavovac",
    "mbr": 307254,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Topolo",
    "mbr": 307262,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Trnova",
    "mbr": 307289,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Trnovica",
    "mbr": 307297,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Trsteno",
    "mbr": 307335,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Uskoplje",
    "mbr": 307343,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Visocani",
    "mbr": 307351,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Vitaljina",
    "mbr": 307360,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Vodovada",
    "mbr": 307378,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Zabrde",
    "mbr": 307386,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Zastolje",
    "mbr": 307394,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Zaton",
    "mbr": 307408,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Zaton Doli",
    "mbr": 307416,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Zavrelje",
    "mbr": 307424,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Žuljana",
    "mbr": 307432,
    "okn": "Dubrovnik",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Donja Vrucica",
    "mbr": 306312,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Duba Pelješka",
    "mbr": 306371,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Gornja Vrucica",
    "mbr": 306479,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Kuna Pelješka",
    "mbr": 306665,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Oskorušno",
    "mbr": 306860,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Pijavicino",
    "mbr": 306932,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Podobuce",
    "mbr": 306983,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Potomje",
    "mbr": 307025,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Prizdrina",
    "mbr": 307050,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Trpanj",
    "mbr": 307319,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Trstenik",
    "mbr": 307327,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Cara",
    "mbr": 314641,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Korčula",
    "mbr": 314650,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Kucište",
    "mbr": 314668,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Lumbarda",
    "mbr": 314676,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Nakovanj",
    "mbr": 314684,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Orebic",
    "mbr": 314692,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Podgorje",
    "mbr": 314706,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Pupnat",
    "mbr": 314714,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Racišce",
    "mbr": 314722,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Stankovic",
    "mbr": 314757,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Viganj",
    "mbr": 314773,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Žrnovo",
    "mbr": 314781,
    "okn": "Korčula",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Borovci",
    "mbr": 317128,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Desne",
    "mbr": 317136,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Dobranje",
    "mbr": 317144,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Metković",
    "mbr": 317152,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Opuzen",
    "mbr": 317179,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Slivno",
    "mbr": 317187,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Struge",
    "mbr": 317195,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Vid",
    "mbr": 317209,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Vidonje",
    "mbr": 317217,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Opuzen I",
    "mbr": 336807,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Opuzen II",
    "mbr": 336815,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Krvavac II",
    "mbr": 336840,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Kobiljaca",
    "mbr": 337226,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Otric-Seoci",
    "mbr": 337234,
    "okn": "Metković",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Bacina",
    "mbr": 312894,
    "okn": "Ploče",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Pasicina",
    "mbr": 312932,
    "okn": "Ploče",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Plina",
    "mbr": 312959,
    "okn": "Ploče",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Ploče",
    "mbr": 312967,
    "okn": "Ploče",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Komin",
    "mbr": 336823,
    "okn": "Ploče",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Rogotin",
    "mbr": 336831,
    "okn": "Ploče",
    "puk": "Dubrovnik"
  },
  {
    "ko": "Boricevac",
    "mbr": 304999,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Buševic",
    "mbr": 305014,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Dnopolje",
    "mbr": 305049,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Dobroselo",
    "mbr": 305057,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Doljani",
    "mbr": 305065,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Donji Lapac",
    "mbr": 305073,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Gornji Lapac",
    "mbr": 305103,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Kestenovac",
    "mbr": 305120,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Kruge",
    "mbr": 305138,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Melinovac",
    "mbr": 305154,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Nebljusi",
    "mbr": 305162,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Oraovac",
    "mbr": 305197,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Seoce",
    "mbr": 305227,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Štrbci",
    "mbr": 305251,
    "okn": "Donji Lapac",
    "puk": "Gospić"
  },
  {
    "ko": "Barlete",
    "mbr": 310581,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Baške Oštarije",
    "mbr": 310590,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Bilaj",
    "mbr": 310603,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Brezik",
    "mbr": 310611,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Brušane",
    "mbr": 310620,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Bužim",
    "mbr": 310638,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Cesarica",
    "mbr": 310646,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Debelo Brdo",
    "mbr": 310654,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Divoselo",
    "mbr": 310662,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Donje Pazarište",
    "mbr": 310689,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Donji Kosinj",
    "mbr": 310697,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Drenovac Raducki",
    "mbr": 310719,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Gornje Pazarište",
    "mbr": 310727,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Gornji Kosinj",
    "mbr": 310735,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Gospić",
    "mbr": 310743,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Kaluderovac",
    "mbr": 310751,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Karlobag",
    "mbr": 310760,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Klanac",
    "mbr": 310778,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Konjsko Brdo",
    "mbr": 310786,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Krušcica",
    "mbr": 310794,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Kruškovac",
    "mbr": 310808,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Kukljic",
    "mbr": 310816,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Kvarte",
    "mbr": 310824,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Licki Citluk",
    "mbr": 310832,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Licki Novi",
    "mbr": 310859,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Licki Osik",
    "mbr": 310867,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Licki Ribnik",
    "mbr": 310875,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Lipe",
    "mbr": 310883,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Lipovo Polje",
    "mbr": 310891,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Lukovo Šugarje",
    "mbr": 310905,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Medak",
    "mbr": 310913,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Mlakva",
    "mbr": 310921,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Mogoric",
    "mbr": 310930,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Mušaluk",
    "mbr": 310948,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Ostrvica",
    "mbr": 310956,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Pavlovac Vrebacki",
    "mbr": 310964,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Perušic",
    "mbr": 310972,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Pocitelj",
    "mbr": 310999,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Smiljan",
    "mbr": 311014,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Studenci",
    "mbr": 311022,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Široka Kula",
    "mbr": 311049,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Trnovac",
    "mbr": 311057,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Vrebac",
    "mbr": 311065,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Perušic I",
    "mbr": 337668,
    "okn": "Gospić",
    "puk": "Gospić"
  },
  {
    "ko": "Bjelopolje",
    "mbr": 330388,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Bunic",
    "mbr": 330396,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Canak",
    "mbr": 330400,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Debelo Brdo",
    "mbr": 330418,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Jošan",
    "mbr": 330426,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Komic",
    "mbr": 330434,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Kozjan",
    "mbr": 330442,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Krbava",
    "mbr": 330469,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Krbavica",
    "mbr": 330477,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Kurjak",
    "mbr": 330485,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Licko Petrovo Selo",
    "mbr": 330493,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Mekinjar",
    "mbr": 330507,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Mutilic",
    "mbr": 330515,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Plitvicka Jezera",
    "mbr": 330523,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Podlapaca",
    "mbr": 330531,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Prijeboj",
    "mbr": 330540,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Smoljanac",
    "mbr": 330558,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Srednja Gora",
    "mbr": 330566,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Svrackovo Selo",
    "mbr": 330574,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Šalamunic",
    "mbr": 330582,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Korenica",
    "mbr": 330604,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Trnavac",
    "mbr": 330612,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Udbina",
    "mbr": 330639,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Vaganac",
    "mbr": 330647,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Visuc",
    "mbr": 330655,
    "okn": "Korenica",
    "puk": "Gospić"
  },
  {
    "ko": "Raduc",
    "mbr": 311006,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Licko Cerje",
    "mbr": 311081,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Kik",
    "mbr": 311154,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Lovinac",
    "mbr": 311189,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Gornja Ploca",
    "mbr": 311235,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Ricice",
    "mbr": 311260,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Smokric",
    "mbr": 311286,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Sveti Rok",
    "mbr": 311294,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Vranik",
    "mbr": 311332,
    "okn": "Lovinac",
    "puk": "Gospić"
  },
  {
    "ko": "Barbati",
    "mbr": 321494,
    "okn": "Novalja",
    "puk": "Gospić"
  },
  {
    "ko": "Novalja",
    "mbr": 321524,
    "okn": "Novalja",
    "puk": "Gospić"
  },
  {
    "ko": "Lun",
    "mbr": 324426,
    "okn": "Novalja",
    "puk": "Gospić"
  },
  {
    "ko": "Novalja-nova",
    "mbr": 338117,
    "okn": "Novalja",
    "puk": "Gospić"
  },
  {
    "ko": "Babin Potok",
    "mbr": 320773,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Brinje",
    "mbr": 320781,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Brlog",
    "mbr": 320790,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Brloška Dubrava",
    "mbr": 320803,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Covici",
    "mbr": 320811,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Dabar",
    "mbr": 320820,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Doljani",
    "mbr": 320838,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Jezerane",
    "mbr": 320846,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Kompolje",
    "mbr": 320854,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Križpolje",
    "mbr": 320862,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Kuterevo",
    "mbr": 320889,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Licko Lešce",
    "mbr": 320897,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Letinac",
    "mbr": 320919,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Lipice",
    "mbr": 320927,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Otočac",
    "mbr": 320935,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Prokike",
    "mbr": 320943,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Prozor",
    "mbr": 320951,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Ramljani",
    "mbr": 320960,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Rudopolje",
    "mbr": 320978,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Sinac",
    "mbr": 320986,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Hrvatsko Polje",
    "mbr": 320994,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Stajnica",
    "mbr": 321001,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Škare",
    "mbr": 321010,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Švica",
    "mbr": 321028,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Turjanski",
    "mbr": 321036,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Vodotec",
    "mbr": 321044,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Vojvoduša",
    "mbr": 321052,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Vrhovine",
    "mbr": 321079,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Zalužnica",
    "mbr": 321087,
    "okn": "Otočac",
    "puk": "Gospić"
  },
  {
    "ko": "Jablanac",
    "mbr": 325228,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Sveti Juraj",
    "mbr": 325236,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Klada",
    "mbr": 325244,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Krasno",
    "mbr": 325252,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Krivi Put",
    "mbr": 325279,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Melnice",
    "mbr": 325287,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Prizna",
    "mbr": 325295,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Senj",
    "mbr": 325309,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Starigrad",
    "mbr": 325317,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Stinica",
    "mbr": 325325,
    "okn": "Senj",
    "puk": "Gospić"
  },
  {
    "ko": "Adamovec",
    "mbr": 325333,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Blaguša",
    "mbr": 325341,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Đurdekovec",
    "mbr": 325350,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Glavnica",
    "mbr": 325368,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Goranec",
    "mbr": 325376,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Kašina",
    "mbr": 325384,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Sesvetski Kraljevec",
    "mbr": 325392,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Lužan",
    "mbr": 325406,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Planina",
    "mbr": 325414,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Sesvete",
    "mbr": 325422,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Šašinovec",
    "mbr": 325449,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Vugrovec",
    "mbr": 325457,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Vurnovec",
    "mbr": 325465,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Centar",
    "mbr": 335240,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Šestine",
    "mbr": 335258,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Crnomerec",
    "mbr": 335266,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Mikulici",
    "mbr": 335274,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Cucerje",
    "mbr": 335282,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Dubrava",
    "mbr": 335304,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Granešina",
    "mbr": 335312,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Maksimir",
    "mbr": 335339,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Markuševec",
    "mbr": 335347,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Remete",
    "mbr": 335355,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Gracani",
    "mbr": 335363,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Blato",
    "mbr": 335371,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Brezovica",
    "mbr": 335380,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Cehi",
    "mbr": 335398,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Demerje",
    "mbr": 335401,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Dragonožec",
    "mbr": 335410,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Horvati",
    "mbr": 335428,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Jakuševec",
    "mbr": 335436,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Klara",
    "mbr": 335444,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Kupinecki Kraljevec",
    "mbr": 335452,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Odra",
    "mbr": 335479,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Odranski Obrež",
    "mbr": 335487,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Zaprudski Otok",
    "mbr": 335495,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Starjak",
    "mbr": 335509,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Trpuci",
    "mbr": 335525,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Pešcenica",
    "mbr": 335533,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Resnik",
    "mbr": 335541,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Žitnjak",
    "mbr": 335550,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Gornje Vrapce",
    "mbr": 335568,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Gornji Stenjevec",
    "mbr": 335576,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Podsused",
    "mbr": 335584,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Stenjevec",
    "mbr": 335592,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Vrapce",
    "mbr": 335606,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Rudeš",
    "mbr": 335614,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Trešnjevka",
    "mbr": 335622,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Trnje",
    "mbr": 335649,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Lucko",
    "mbr": 337021,
    "okn": "GU Grada Zagreba",
    "puk": "GU Grada Zagreba"
  },
  {
    "ko": "Barilovic",
    "mbr": 307459,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Belaj",
    "mbr": 307467,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Bosiljevo",
    "mbr": 307475,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Brajakovo Brdo",
    "mbr": 307483,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Bukovlje",
    "mbr": 307491,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Cerovac Barilovicki",
    "mbr": 307505,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Donji Skrad",
    "mbr": 307513,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Donji Zvecaj",
    "mbr": 307521,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Erdelj",
    "mbr": 307548,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Generalski Stol",
    "mbr": 307556,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Glavica",
    "mbr": 307564,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornje Prilišce",
    "mbr": 307572,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornji Zvecaj",
    "mbr": 307599,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Grabrk",
    "mbr": 307602,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Gršcaki",
    "mbr": 307629,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Hrsina",
    "mbr": 307637,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Jarce Polje",
    "mbr": 307645,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Koransko Selo",
    "mbr": 307653,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Korenic Brdo",
    "mbr": 307661,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Kosijersko Selo",
    "mbr": 307670,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Kozalj Vrh",
    "mbr": 307688,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Ladešici",
    "mbr": 307696,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Lešce",
    "mbr": 307700,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Lipa",
    "mbr": 307718,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Lišnica",
    "mbr": 307726,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Maletici",
    "mbr": 307734,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Mateško Selo",
    "mbr": 307742,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Modruš Potok",
    "mbr": 307769,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Mracin",
    "mbr": 307777,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Mrežnica",
    "mbr": 307785,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Mrežnicki Novaki",
    "mbr": 307793,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Mrzlo Polje Mrežnicko",
    "mbr": 307807,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Mrzljaki",
    "mbr": 307815,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Novo Selo Bosiljevsko",
    "mbr": 307823,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Otok na Dobri",
    "mbr": 307831,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Perjasica",
    "mbr": 307840,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Pišcetke",
    "mbr": 307858,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Podrebar",
    "mbr": 307866,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Poloj",
    "mbr": 307874,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Potok Bosiljevski",
    "mbr": 307882,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Pribanjci",
    "mbr": 307904,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Radocaji",
    "mbr": 307912,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Rosopajnik",
    "mbr": 307939,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Svojic",
    "mbr": 307947,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Umol",
    "mbr": 307955,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Vinski Vrh",
    "mbr": 307963,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Vodena Draga",
    "mbr": 307971,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Vukova Gorica",
    "mbr": 307980,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Zagradci",
    "mbr": 307998,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Žubrinci",
    "mbr": 308005,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Duga Resa 2",
    "mbr": 338125,
    "okn": "Duga Resa",
    "puk": "Karlovac"
  },
  {
    "ko": "Banska Selnica",
    "mbr": 312983,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Banski Kovacevac",
    "mbr": 312991,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Banski Moravci",
    "mbr": 313009,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Blatnica Pokupska",
    "mbr": 313017,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Budacka Rijeka",
    "mbr": 313025,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Buric Selo",
    "mbr": 313033,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Cerovac Vukmanicki",
    "mbr": 313041,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Donje Mekušje",
    "mbr": 313050,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Donje Pokupje",
    "mbr": 313068,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Donji Budacki",
    "mbr": 313076,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Donji Sjenicak",
    "mbr": 313084,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Draganic",
    "mbr": 313092,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornje Mekušje",
    "mbr": 313106,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornje Stative",
    "mbr": 313114,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornji Budacki",
    "mbr": 313122,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornji Sjenicak",
    "mbr": 313149,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornji Skrad",
    "mbr": 313157,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Kamensko",
    "mbr": 313165,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Karlovac I",
    "mbr": 313173,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Karlovac II",
    "mbr": 313181,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Krnjak",
    "mbr": 313190,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Luka Pokupska",
    "mbr": 313203,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Mahicno",
    "mbr": 313211,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Mala Švarca",
    "mbr": 313220,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Poljana Vojnićka",
    "mbr": 313238,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Popovic Brdo",
    "mbr": 313246,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Recica",
    "mbr": 313254,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Sjenicak Lasinjski",
    "mbr": 313262,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Skakavac",
    "mbr": 313289,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Šišljavic",
    "mbr": 313297,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Trebinja",
    "mbr": 313319,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Turanj",
    "mbr": 313327,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Tušilovic",
    "mbr": 313335,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Utinja",
    "mbr": 313343,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Velika Crkvina",
    "mbr": 313351,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Velika Jelsa",
    "mbr": 313360,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Vukmanic",
    "mbr": 313378,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Zadobarje",
    "mbr": 313386,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Zagorje",
    "mbr": 313394,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Zagrad",
    "mbr": 313408,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Zimic",
    "mbr": 313416,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Desni Štefanki",
    "mbr": 333581,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Desno Sredicko",
    "mbr": 333590,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Lasinja",
    "mbr": 333697,
    "okn": "Karlovac",
    "puk": "Karlovac"
  },
  {
    "ko": "Blata",
    "mbr": 319171,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Carevo Polje",
    "mbr": 319180,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Cerovnik",
    "mbr": 319198,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Corkova Uvala",
    "mbr": 319201,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Donje Dubrave",
    "mbr": 319210,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Drežnica",
    "mbr": 319228,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornje Dubrave",
    "mbr": 319236,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Hreljin Ogulinski",
    "mbr": 319244,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Janja Gora",
    "mbr": 319252,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Jasenak",
    "mbr": 319279,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Jezero",
    "mbr": 319287,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Josipdol",
    "mbr": 319295,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Kunic",
    "mbr": 319309,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Latin",
    "mbr": 319317,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Licka Jesenica",
    "mbr": 319325,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Mededak",
    "mbr": 319333,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Modruš",
    "mbr": 319341,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Potok Musulinski",
    "mbr": 319350,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Ogulin",
    "mbr": 319368,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Oštarije",
    "mbr": 319376,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Otok Oštarijski",
    "mbr": 319384,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Sveti Petar",
    "mbr": 319392,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Plaški",
    "mbr": 319406,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Ponikve",
    "mbr": 319414,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Popovo Selo",
    "mbr": 319422,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Pothum Plašcanski",
    "mbr": 319449,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Saborsko",
    "mbr": 319457,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Skradnik",
    "mbr": 319465,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Tounj",
    "mbr": 319473,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Trojvrh",
    "mbr": 319481,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Tržic Tounjski",
    "mbr": 319490,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Vajin Vrh",
    "mbr": 319503,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Vitunj",
    "mbr": 319511,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Zagorje",
    "mbr": 319520,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Zdenac",
    "mbr": 319538,
    "okn": "Ogulin",
    "puk": "Karlovac"
  },
  {
    "ko": "Brašljevica",
    "mbr": 321095,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Bratovanci",
    "mbr": 321109,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Breznik",
    "mbr": 321117,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Brlog Ozaljski",
    "mbr": 321125,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Bubnjarci",
    "mbr": 321133,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Donji Lovic",
    "mbr": 321141,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Dvorište Vivodinsko",
    "mbr": 321150,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Ferenci",
    "mbr": 321168,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Grice",
    "mbr": 321176,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Ilovac",
    "mbr": 321184,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Jaškovo",
    "mbr": 321192,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Jurovo",
    "mbr": 321206,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Lipnik",
    "mbr": 321214,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Lovic Prekriški",
    "mbr": 321222,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Malinci",
    "mbr": 321249,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Martinski Vrh",
    "mbr": 321257,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Novaki Ozaljski",
    "mbr": 321265,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Ozalj",
    "mbr": 321273,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Oštri Vrh Ozaljski",
    "mbr": 321281,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Podbrežje",
    "mbr": 321290,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Pokupje",
    "mbr": 321303,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Police Pirišce",
    "mbr": 321311,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Pravutina",
    "mbr": 321320,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Ribnik",
    "mbr": 321338,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Sekulici",
    "mbr": 321346,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Slapno",
    "mbr": 321354,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Sracak",
    "mbr": 321362,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Svetice",
    "mbr": 321389,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Sveticko Hrašce",
    "mbr": 321397,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Tomašnica",
    "mbr": 321419,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Trg",
    "mbr": 321427,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Vivodina",
    "mbr": 321435,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Vrhovac",
    "mbr": 321443,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Zajacko Selo",
    "mbr": 321451,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Zaluka",
    "mbr": 321460,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Zorkovac",
    "mbr": 321478,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Žakanje",
    "mbr": 321486,
    "okn": "Ozalj",
    "puk": "Karlovac"
  },
  {
    "ko": "Blagaj",
    "mbr": 329037,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Bogovolja",
    "mbr": 329045,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Brocanac",
    "mbr": 329053,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Cetingrad",
    "mbr": 329061,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Cetinski Varoš",
    "mbr": 329070,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Cvijanovic Brdo",
    "mbr": 329088,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Cvitovic",
    "mbr": 329096,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Donje Primišlje",
    "mbr": 329100,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Donji Ladevac",
    "mbr": 329118,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Drežnik",
    "mbr": 329126,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Furjan",
    "mbr": 329134,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Glinice",
    "mbr": 329142,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Gnojnice",
    "mbr": 329169,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Gojkovac",
    "mbr": 329177,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornje Primišlje",
    "mbr": 329185,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornji Ladevac",
    "mbr": 329193,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Kremen",
    "mbr": 329207,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Kruškovaca",
    "mbr": 329215,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Kuzma",
    "mbr": 329223,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Maljevac",
    "mbr": 329231,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Mašvina",
    "mbr": 329240,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Gornja Mocila",
    "mbr": 329258,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Nikšic",
    "mbr": 329266,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Nova Kršlja",
    "mbr": 329274,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Ponor",
    "mbr": 329282,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Popovac",
    "mbr": 329304,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Radovica",
    "mbr": 329312,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Rakovica",
    "mbr": 329339,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Sadilovac",
    "mbr": 329347,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Slunj",
    "mbr": 329355,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Šljivnjak",
    "mbr": 329363,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Tobolic",
    "mbr": 329371,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Tocak",
    "mbr": 329380,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Tržic Primišljanski",
    "mbr": 329398,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Veljun",
    "mbr": 329401,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Zbjeg Mocilski",
    "mbr": 329410,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Slunj 1",
    "mbr": 337773,
    "okn": "Slunj",
    "puk": "Karlovac"
  },
  {
    "ko": "Bukovica Utinjska",
    "mbr": 332771,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Kestenovac",
    "mbr": 332780,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Klokoc",
    "mbr": 332798,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Kolaric",
    "mbr": 332801,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Krstinja",
    "mbr": 332810,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Kupljensko",
    "mbr": 332828,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Miholjsko",
    "mbr": 332836,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Radonja",
    "mbr": 332844,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Selakova Poljana",
    "mbr": 332852,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Svinica Krstinjska",
    "mbr": 332879,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Široka Rijeka",
    "mbr": 332887,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Utinja Vrelo",
    "mbr": 332895,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Vojnić",
    "mbr": 332909,
    "okn": "Vojnić",
    "puk": "Karlovac"
  },
  {
    "ko": "Sveta Ana",
    "mbr": 309222,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Budancevica",
    "mbr": 309249,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Budrovac",
    "mbr": 309257,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Cepelovac",
    "mbr": 309265,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Đurdevac I",
    "mbr": 309281,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Đurdevac II",
    "mbr": 309290,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Ferdinandovac",
    "mbr": 309303,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Hampovica",
    "mbr": 309320,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kalinovac",
    "mbr": 309338,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kloštar Podravski",
    "mbr": 309354,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kozarevac",
    "mbr": 309362,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Lepa Greda",
    "mbr": 309389,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Micetinac",
    "mbr": 309419,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Miholjanec",
    "mbr": 309427,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Molve",
    "mbr": 309435,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Novo Virje",
    "mbr": 309443,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Podravske Sesvete",
    "mbr": 309486,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Prugovac",
    "mbr": 309494,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Severovci",
    "mbr": 309516,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Sirova Katalena",
    "mbr": 309524,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Suha Katalena",
    "mbr": 309559,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Šemovci",
    "mbr": 309567,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Virje",
    "mbr": 309591,
    "okn": "Đurdevac",
    "puk": "Koprivnica"
  },
  {
    "ko": "Ždala",
    "mbr": 309605,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Botinovac",
    "mbr": 314145,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Botovo",
    "mbr": 314153,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Branjska",
    "mbr": 314161,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Koprivnicki Bregi",
    "mbr": 314170,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Delovi",
    "mbr": 314188,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Drnje",
    "mbr": 314196,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Duga Rijeka",
    "mbr": 314200,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Đelekovec",
    "mbr": 314218,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Glogovac",
    "mbr": 314226,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Gola",
    "mbr": 314234,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Gorica",
    "mbr": 314242,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Gotalovo",
    "mbr": 314269,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Herešin",
    "mbr": 314277,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Hlebine",
    "mbr": 314285,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Hudovljani",
    "mbr": 314293,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Imbriovec",
    "mbr": 314307,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Jagnjedovec",
    "mbr": 314315,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Javorovac",
    "mbr": 314323,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Koledinec",
    "mbr": 314331,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Koprivnica",
    "mbr": 314340,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Koprivnicki Ivanec",
    "mbr": 314358,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kunovec",
    "mbr": 314366,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kutnjak",
    "mbr": 314374,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kuzminec",
    "mbr": 314382,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Legrad",
    "mbr": 314404,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Lepavina",
    "mbr": 314412,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Mala Mucna",
    "mbr": 314439,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Novacka",
    "mbr": 314447,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Novigrad Podravski",
    "mbr": 314455,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Peteranec",
    "mbr": 314463,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Plavšinac",
    "mbr": 314471,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Prkos",
    "mbr": 314480,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Rasinja",
    "mbr": 314498,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Reka",
    "mbr": 314501,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Selnica Podravska",
    "mbr": 314510,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Sigetec",
    "mbr": 314528,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Sokolovac",
    "mbr": 314536,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Srijem",
    "mbr": 314544,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Subotica Podravska",
    "mbr": 314552,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Torcec",
    "mbr": 314579,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Velika Mucna",
    "mbr": 314587,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Veliki Grabicani",
    "mbr": 314595,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Veliki Otok",
    "mbr": 314609,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Veliki Poganac",
    "mbr": 314617,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Zablatje",
    "mbr": 314625,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Jagnjedovec-grad",
    "mbr": 337242,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kunovec Breg",
    "mbr": 337374,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Bakovcica",
    "mbr": 337382,
    "okn": "Koprivnica",
    "puk": "Koprivnica"
  },
  {
    "ko": "Apatovec",
    "mbr": 315273,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Bockovec",
    "mbr": 315281,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Bojnikovec",
    "mbr": 315290,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Borje",
    "mbr": 315303,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Carevdar",
    "mbr": 315311,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Cirkvena",
    "mbr": 315320,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Cubinec",
    "mbr": 315338,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Sveti Petar Cvrstec",
    "mbr": 315346,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Dijankovec",
    "mbr": 315354,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Donja Brckovcina",
    "mbr": 315362,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Dubovec",
    "mbr": 315389,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Đurdic",
    "mbr": 315397,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Erdovec",
    "mbr": 315419,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Fincevec",
    "mbr": 315427,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Fodrovec",
    "mbr": 315435,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Glogovnica",
    "mbr": 315443,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Gornja Rijeka",
    "mbr": 315451,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Gregurovec",
    "mbr": 315460,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Gušcerovec",
    "mbr": 315478,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Sveta Helena",
    "mbr": 315486,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Hrsovo",
    "mbr": 315494,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kalnik",
    "mbr": 315508,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kamešnica",
    "mbr": 315516,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Kloštar Vojakovacki",
    "mbr": 315524,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Križevci",
    "mbr": 315532,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Lemeš",
    "mbr": 315559,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Lukacevec",
    "mbr": 315567,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Majurec",
    "mbr": 315575,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Marinovec",
    "mbr": 315583,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Meda",
    "mbr": 315591,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Miholec",
    "mbr": 315605,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Mikovec",
    "mbr": 315613,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Novi Glog",
    "mbr": 315621,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Sveti Petar Orehovec",
    "mbr": 315630,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Osijek Vojakovacki",
    "mbr": 315648,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Podvinje Miholecko",
    "mbr": 315656,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Pofuki",
    "mbr": 315664,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Potocec",
    "mbr": 315672,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Potok Kalnicki",
    "mbr": 315699,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Rašcani",
    "mbr": 315702,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Raven",
    "mbr": 315729,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Ruševac",
    "mbr": 315737,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Špiranec",
    "mbr": 315753,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Štrigovec",
    "mbr": 315761,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Trema",
    "mbr": 315770,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Vojakovac",
    "mbr": 315788,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Vojakovacke Sesvete",
    "mbr": 315796,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Vojnovec Kalnicki",
    "mbr": 315800,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Zaistovec",
    "mbr": 315818,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Sveti Ivan Žabno",
    "mbr": 315826,
    "okn": "Križevci",
    "puk": "Koprivnica"
  },
  {
    "ko": "Andraševec",
    "mbr": 304735,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Donja Stubica",
    "mbr": 304743,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Dubovec",
    "mbr": 304751,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Globocec",
    "mbr": 304760,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Gornja Stubica",
    "mbr": 304778,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Gusakovec",
    "mbr": 304786,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Hum Stubicki",
    "mbr": 304794,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Kraljev Vrh",
    "mbr": 304816,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Laz Bistricki",
    "mbr": 304824,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Lepa Ves",
    "mbr": 304832,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Marija Bistrica",
    "mbr": 304859,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Modrovec",
    "mbr": 304867,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Mokrice",
    "mbr": 304875,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Oroslavje",
    "mbr": 304883,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Poljanica Bistricka",
    "mbr": 304891,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Pustodol",
    "mbr": 304905,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Selnica",
    "mbr": 304913,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Slani Potok",
    "mbr": 304921,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Strmec Stubicki",
    "mbr": 304930,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Stubicka Slatina",
    "mbr": 304948,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Sveti Matej",
    "mbr": 304956,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Stubicko Podgorje",
    "mbr": 304964,
    "okn": "Donja Stubica",
    "puk": "Krapina"
  },
  {
    "ko": "Crešnjevec",
    "mbr": 313629,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Klanjec",
    "mbr": 313637,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Kraljevec na Sutli",
    "mbr": 313645,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Sveti Križ",
    "mbr": 313653,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Miljana",
    "mbr": 313661,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Novi Dvori Klanjecki",
    "mbr": 313670,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Poljana Sutlanska",
    "mbr": 313688,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Radakovo",
    "mbr": 313696,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Ravno Brezje",
    "mbr": 313700,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Razvor",
    "mbr": 313718,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Tomaševec",
    "mbr": 313726,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Tuhelj",
    "mbr": 313734,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Zagorska Sela",
    "mbr": 313742,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Gornji Cemehovec",
    "mbr": 336564,
    "okn": "Klanjec",
    "puk": "Krapina"
  },
  {
    "ko": "Đurmanec",
    "mbr": 315133,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Gornja Pacetina",
    "mbr": 315141,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Hlevnica",
    "mbr": 315150,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Jesenje",
    "mbr": 315168,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Krapina",
    "mbr": 315176,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Krapina-grad",
    "mbr": 315184,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Petrovsko",
    "mbr": 315192,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Putkovec",
    "mbr": 315206,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Radoboj",
    "mbr": 315214,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Svedruža",
    "mbr": 315222,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Šemnica",
    "mbr": 315249,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Škaricevo",
    "mbr": 315257,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Velika Ves",
    "mbr": 315265,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Donja Šemnica",
    "mbr": 337790,
    "okn": "Krapina",
    "puk": "Krapina"
  },
  {
    "ko": "Brezno",
    "mbr": 323861,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Cigrovec",
    "mbr": 323870,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Desinic",
    "mbr": 323888,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Druškovec Humski",
    "mbr": 323896,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Gorjakovo",
    "mbr": 323900,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Hum na Sutli",
    "mbr": 323918,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Jelenjak",
    "mbr": 323926,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Kostel",
    "mbr": 323934,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Košnica",
    "mbr": 323942,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Lupinjak",
    "mbr": 323969,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Plemenšcina",
    "mbr": 323977,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Pregrada",
    "mbr": 323985,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Prišlin",
    "mbr": 323993,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Sopot",
    "mbr": 324019,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Velika Horvatska",
    "mbr": 324027,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Vinagora",
    "mbr": 324035,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Vrbanec",
    "mbr": 324043,
    "okn": "Pregrada",
    "puk": "Krapina"
  },
  {
    "ko": "Bedekovcina",
    "mbr": 334324,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Cret",
    "mbr": 334332,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Donja Pacetina",
    "mbr": 334359,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Dubrovcan",
    "mbr": 334367,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Gubaševo",
    "mbr": 334375,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Jezero Klanjecko",
    "mbr": 334383,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Klokovec",
    "mbr": 334391,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Komor",
    "mbr": 334405,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Krapinske Toplice",
    "mbr": 334413,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Mala Erpenja",
    "mbr": 334421,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Mirkovec",
    "mbr": 334430,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Poznanovec",
    "mbr": 334448,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Pustodol",
    "mbr": 334456,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Selno",
    "mbr": 334464,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Strmec",
    "mbr": 334472,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Špickovina",
    "mbr": 334499,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Švaljkovec",
    "mbr": 334502,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Velika Erpenja",
    "mbr": 334529,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Veliko Trgovišce",
    "mbr": 334537,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Vrtnjakovec",
    "mbr": 334545,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Zabok",
    "mbr": 334553,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Sveti Križ Zacretje",
    "mbr": 334561,
    "okn": "Zabok",
    "puk": "Krapina"
  },
  {
    "ko": "Belec",
    "mbr": 335967,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Budinšcina",
    "mbr": 335975,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Donja Batina",
    "mbr": 335983,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Gotalovec",
    "mbr": 335991,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Hrašcina",
    "mbr": 336009,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Hrašcinski Kraljevec",
    "mbr": 336017,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Jertovec",
    "mbr": 336025,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Konjšcina",
    "mbr": 336033,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Lovrecan",
    "mbr": 336041,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Mace",
    "mbr": 336050,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Martinci Zlatarski",
    "mbr": 336068,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Mihovljan",
    "mbr": 336076,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Oštrc",
    "mbr": 336084,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Peršaves",
    "mbr": 336092,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Pešceno",
    "mbr": 336106,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Petrova Gora",
    "mbr": 336114,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Podgrade Bistricko",
    "mbr": 336122,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Purga",
    "mbr": 336149,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Sušobreg",
    "mbr": 336157,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Tugonica",
    "mbr": 336165,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Veleškovec",
    "mbr": 336173,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Veternica",
    "mbr": 336181,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Vojnovec Loborski",
    "mbr": 336190,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Zajezda",
    "mbr": 336203,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Zlatar",
    "mbr": 336211,
    "okn": "Zlatar",
    "puk": "Krapina"
  },
  {
    "ko": "Baranjsko Petrovo Selo",
    "mbr": 300012,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Batina",
    "mbr": 300039,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Beli Manastir",
    "mbr": 300047,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Bilje",
    "mbr": 300055,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Bolman",
    "mbr": 300063,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Branjin Vrh",
    "mbr": 300071,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Branjina",
    "mbr": 300080,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Ceminac",
    "mbr": 300098,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Darda",
    "mbr": 300101,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Draž",
    "mbr": 300110,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Duboševica",
    "mbr": 300128,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Gajic",
    "mbr": 300136,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Grabovac",
    "mbr": 300144,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Jagodnjak",
    "mbr": 300152,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Kamenac",
    "mbr": 300179,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Karanac",
    "mbr": 300187,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Kneževi Vinogradi",
    "mbr": 300195,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Kopacevo",
    "mbr": 300209,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Kotlina",
    "mbr": 300217,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Kozarac",
    "mbr": 300225,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Luc",
    "mbr": 300233,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Lug",
    "mbr": 300241,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Petlovac",
    "mbr": 300250,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Podolje",
    "mbr": 300268,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Popovac",
    "mbr": 300276,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Suza",
    "mbr": 300284,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Šumarina",
    "mbr": 300292,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Topolje",
    "mbr": 300306,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Torjanci",
    "mbr": 300314,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Vardarac",
    "mbr": 300322,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Zmajevac I",
    "mbr": 300349,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Zmajevac II",
    "mbr": 300357,
    "okn": "Beli Manastir",
    "puk": "Osijek"
  },
  {
    "ko": "Benicanci",
    "mbr": 305286,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Bockinci",
    "mbr": 305294,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Camagajevci",
    "mbr": 305308,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Crnkovci",
    "mbr": 305316,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Donji Miholjac",
    "mbr": 305324,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Sveti Đurad",
    "mbr": 305332,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Golinci",
    "mbr": 305359,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Kapelna",
    "mbr": 305367,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Kucanci",
    "mbr": 305375,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Kunišinci",
    "mbr": 305383,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Lacici",
    "mbr": 305391,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Marijanci",
    "mbr": 305405,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Miholjacki Poreč",
    "mbr": 305413,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Moslavina Podravska",
    "mbr": 305421,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Podgajci Podravski",
    "mbr": 305430,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Radikovci",
    "mbr": 305448,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Rakitovica",
    "mbr": 305456,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Šljivoševci",
    "mbr": 305464,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Viljevo",
    "mbr": 305472,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Števkovica",
    "mbr": 337587,
    "okn": "Donji Miholjac",
    "puk": "Osijek"
  },
  {
    "ko": "Bracevci",
    "mbr": 308722,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Breznica Đakovacka",
    "mbr": 308749,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Bucje",
    "mbr": 308757,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Budrovci",
    "mbr": 308765,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Cenkovo",
    "mbr": 308773,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Dragotin",
    "mbr": 308781,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Drenje",
    "mbr": 308790,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Đakovo",
    "mbr": 308803,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Đurdanci",
    "mbr": 308811,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Forkuševci",
    "mbr": 308820,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Gašinci",
    "mbr": 308838,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Gorjani",
    "mbr": 308846,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Hrkanovci Đakovacki",
    "mbr": 308854,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Ivanovci Đakovacki",
    "mbr": 308862,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Kešinci",
    "mbr": 308889,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Kondric",
    "mbr": 308897,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Koritna",
    "mbr": 308919,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Krndija",
    "mbr": 308927,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Lapovci",
    "mbr": 308935,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Levanjska Varoš",
    "mbr": 308943,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Majar",
    "mbr": 308951,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Merolino Sikirevacko",
    "mbr": 308960,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Mrzovic",
    "mbr": 308978,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Music",
    "mbr": 308986,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Nabrde",
    "mbr": 308994,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Novi Perkovci",
    "mbr": 309001,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Paucje",
    "mbr": 309010,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Piškorevci",
    "mbr": 309028,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Podgorje Bracevacko",
    "mbr": 309036,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Potnjani",
    "mbr": 309044,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Preslatinci",
    "mbr": 309052,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Pridvorje",
    "mbr": 309079,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Punitovci",
    "mbr": 309087,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Satnica Đakovacka",
    "mbr": 309095,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Selci Đakovacki",
    "mbr": 309109,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Semeljci",
    "mbr": 309117,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Slatinik Drenjski",
    "mbr": 309125,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Slobodna Vlast",
    "mbr": 309133,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Strizivojna",
    "mbr": 309141,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Svetoblažje",
    "mbr": 309150,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Široko Polje",
    "mbr": 309168,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Tomašanci",
    "mbr": 309176,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Trnava",
    "mbr": 309184,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Viškovci",
    "mbr": 309192,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Vrbica",
    "mbr": 309206,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Vucevci",
    "mbr": 309214,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Mandicevac",
    "mbr": 336432,
    "okn": "Đakovo",
    "puk": "Osijek"
  },
  {
    "ko": "Beljevina",
    "mbr": 317225,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Bokšic",
    "mbr": 317233,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Breznica Našicka",
    "mbr": 317241,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Budimci",
    "mbr": 317250,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Ceremošnjak",
    "mbr": 317268,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Donja Moticina",
    "mbr": 317276,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Fericanci",
    "mbr": 317284,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Gazije",
    "mbr": 317292,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Gornja Moticina",
    "mbr": 317306,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Gradac Našicki",
    "mbr": 317314,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Granice",
    "mbr": 317322,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Klokocevci",
    "mbr": 317349,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Koška",
    "mbr": 317357,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Kršinci",
    "mbr": 317365,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Londžica",
    "mbr": 317373,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Martin",
    "mbr": 317381,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Našice",
    "mbr": 317390,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Niza",
    "mbr": 317403,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Ostrošinci",
    "mbr": 317411,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Poganovci",
    "mbr": 317446,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Pribiševci",
    "mbr": 317454,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Razbojište",
    "mbr": 317462,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Seona",
    "mbr": 317489,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Lug Suboticki",
    "mbr": 317519,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Šaptinovci",
    "mbr": 317535,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Vukojevci",
    "mbr": 317543,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Zoljan",
    "mbr": 317551,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Topoline",
    "mbr": 336408,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Normanci",
    "mbr": 336416,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Đurdenovac",
    "mbr": 336572,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Stipanovci",
    "mbr": 336661,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Kelešinka",
    "mbr": 336670,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Podgorac",
    "mbr": 336688,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Bijela Loza",
    "mbr": 336696,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Ledenik",
    "mbr": 336700,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Andrijevac",
    "mbr": 336718,
    "okn": "Našice",
    "puk": "Osijek"
  },
  {
    "ko": "Ada",
    "mbr": 320471,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Aljmaš",
    "mbr": 320480,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Antunovac",
    "mbr": 320498,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Beketinci",
    "mbr": 320501,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Bijelo Brdo",
    "mbr": 320510,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Cepin",
    "mbr": 320528,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Cokadinci",
    "mbr": 320536,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Dalj",
    "mbr": 320544,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Dopsin",
    "mbr": 320552,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Erdut",
    "mbr": 320579,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Ernestinovo",
    "mbr": 320587,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Hrastin",
    "mbr": 320595,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Josipovac",
    "mbr": 320609,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Ivanovac",
    "mbr": 320617,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Koprivna",
    "mbr": 320625,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Laslovo",
    "mbr": 320633,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Cepinski Martinci",
    "mbr": 320641,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Orlovnjak",
    "mbr": 320650,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Osijek",
    "mbr": 320668,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Palaca",
    "mbr": 320676,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Paulin Dvor",
    "mbr": 320684,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Briješce",
    "mbr": 320692,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Sarvaš",
    "mbr": 320706,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Šodolovci",
    "mbr": 320714,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Tenja",
    "mbr": 320722,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Tvrdavica-Podravlje",
    "mbr": 320749,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Vladislavci",
    "mbr": 320757,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Vuka",
    "mbr": 320765,
    "okn": "Osijek",
    "puk": "Osijek"
  },
  {
    "ko": "Belišce",
    "mbr": 330809,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Bistrinci",
    "mbr": 330817,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Bizovac",
    "mbr": 330825,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Bocanjevci",
    "mbr": 330833,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Brodanci",
    "mbr": 330841,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Gat",
    "mbr": 330850,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Habjanovci",
    "mbr": 330868,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Harkanovci",
    "mbr": 330876,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Ivanovci",
    "mbr": 330884,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Ladimirevci",
    "mbr": 330892,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Marjancaci",
    "mbr": 330906,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Nard",
    "mbr": 330914,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Petrijevci",
    "mbr": 330922,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Satnica",
    "mbr": 330949,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Šag",
    "mbr": 330957,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Tiborjanci",
    "mbr": 330965,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Valpovo",
    "mbr": 330973,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Veliškovci",
    "mbr": 330981,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Vinogradci",
    "mbr": 330990,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Zelcin",
    "mbr": 331007,
    "okn": "Valpovo",
    "puk": "Osijek"
  },
  {
    "ko": "Antunovac",
    "mbr": 321567,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Badljevina",
    "mbr": 321575,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Banovac",
    "mbr": 321583,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Batinjani",
    "mbr": 321591,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Bjelajci",
    "mbr": 321605,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Bjelanovac",
    "mbr": 321613,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Branešci",
    "mbr": 321621,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Brekinska",
    "mbr": 321630,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Brezine",
    "mbr": 321648,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Budici",
    "mbr": 321656,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Bujavica",
    "mbr": 321664,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Bukovcani",
    "mbr": 321672,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Cikote",
    "mbr": 321699,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Caglic",
    "mbr": 321702,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Dereza",
    "mbr": 321729,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Dobrovac",
    "mbr": 321737,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Donja Obrijež",
    "mbr": 321745,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Dragovic",
    "mbr": 321753,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Filipovac",
    "mbr": 321761,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Gaj",
    "mbr": 321770,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Gornja Obrijež",
    "mbr": 321788,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Grahovljani",
    "mbr": 321796,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Grdevica",
    "mbr": 321800,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Jagma",
    "mbr": 321818,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Kapetanovo Polje",
    "mbr": 321826,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Klisa",
    "mbr": 321834,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Korita",
    "mbr": 321842,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Kovacevac",
    "mbr": 321869,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Kraguj",
    "mbr": 321877,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Kricke",
    "mbr": 321885,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Kukunjevac",
    "mbr": 321893,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Kusonje",
    "mbr": 321907,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Lipik",
    "mbr": 321915,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Lipovac",
    "mbr": 321923,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Livadani",
    "mbr": 321931,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Marino Selo",
    "mbr": 321940,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Španovica",
    "mbr": 321958,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Omanovac",
    "mbr": 321966,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Ožegovci",
    "mbr": 321974,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Pakrac",
    "mbr": 321982,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Ploštine",
    "mbr": 322008,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Poljana",
    "mbr": 322016,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Prekopakra",
    "mbr": 322024,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Prgomelje",
    "mbr": 322032,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Rogulje",
    "mbr": 322059,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Skenderovci",
    "mbr": 322067,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Strižicevac",
    "mbr": 322075,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Subocka",
    "mbr": 322083,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Šeovica",
    "mbr": 322091,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Šumetlica",
    "mbr": 322105,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Tisovac",
    "mbr": 322113,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Toranj",
    "mbr": 322121,
    "okn": "Pakrac",
    "puk": "Požega"
  },
  {
    "ko": "Alilovci",
    "mbr": 326950,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Ašikovci",
    "mbr": 326968,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Bektež",
    "mbr": 326976,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Bertelovci",
    "mbr": 326984,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Blacko",
    "mbr": 326992,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Bolomace",
    "mbr": 327000,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Bratuljevci",
    "mbr": 327018,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Brestovac",
    "mbr": 327026,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Brodski Drenovac",
    "mbr": 327034,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Bucje",
    "mbr": 327042,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Buk",
    "mbr": 327069,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Busnovi",
    "mbr": 327077,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Cerovac",
    "mbr": 327085,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Ciglenik",
    "mbr": 327093,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Caglin",
    "mbr": 327107,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Cešljakovci",
    "mbr": 327115,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Daranovci",
    "mbr": 327123,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Dervišaga",
    "mbr": 327131,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Deževci",
    "mbr": 327140,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Djedina Rijeka",
    "mbr": 327158,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Doljanovci",
    "mbr": 327166,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Donji Emovci",
    "mbr": 327174,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Draga",
    "mbr": 327182,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Drškovci",
    "mbr": 327204,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Duboka",
    "mbr": 327212,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Eminovci",
    "mbr": 327239,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Frkljevci",
    "mbr": 327247,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Gornji Gucani",
    "mbr": 327255,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Gornji Vrhovci",
    "mbr": 327263,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Grabarje",
    "mbr": 327271,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Gradac",
    "mbr": 327280,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Gradište",
    "mbr": 327298,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Imrijevci",
    "mbr": 327301,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Ivandol",
    "mbr": 327310,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Jaguplije",
    "mbr": 327328,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Jakšic",
    "mbr": 327336,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Jurkovac",
    "mbr": 327344,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Kalinic",
    "mbr": 327352,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Kaptol",
    "mbr": 327379,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Komorica",
    "mbr": 327387,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Komušina",
    "mbr": 327395,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Koprivna",
    "mbr": 327409,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Požeška Koprivnica",
    "mbr": 327417,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Kujnik",
    "mbr": 327425,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Kula",
    "mbr": 327433,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Kutjevo",
    "mbr": 327441,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Kuzmica",
    "mbr": 327450,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Lakušija",
    "mbr": 327468,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Latinovac",
    "mbr": 327476,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Cosine Laze",
    "mbr": 327484,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Lukac",
    "mbr": 327492,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Migalovci",
    "mbr": 327506,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Mihaljevci",
    "mbr": 327514,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Mijaci",
    "mbr": 327522,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Mitrovac",
    "mbr": 327549,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Novo Selo",
    "mbr": 327557,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Novo Zvecevo",
    "mbr": 327565,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Nurkovac",
    "mbr": 327573,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Oljasi",
    "mbr": 327581,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Orljavac",
    "mbr": 327590,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Paka",
    "mbr": 327603,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Perenci",
    "mbr": 327611,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Pleternica",
    "mbr": 327620,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Podgorje",
    "mbr": 327638,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Podsrece",
    "mbr": 327646,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Poljanska",
    "mbr": 327654,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Poreč",
    "mbr": 327662,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Radovanci",
    "mbr": 327689,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Ratkovica",
    "mbr": 327697,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Ruševo",
    "mbr": 327719,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Sapna",
    "mbr": 327727,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Sažije",
    "mbr": 327735,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Seoci",
    "mbr": 327743,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Sesvete",
    "mbr": 327751,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Požega",
    "mbr": 327760,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Sloboština",
    "mbr": 327778,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Sovski Dol",
    "mbr": 327786,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Stara Lipa",
    "mbr": 327794,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Stražeman",
    "mbr": 327808,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Sulkovci",
    "mbr": 327816,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Šeovci",
    "mbr": 327824,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Sibokovac",
    "mbr": 327832,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Škrabutnik",
    "mbr": 327859,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Šnjegavic",
    "mbr": 327867,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Štitnjak",
    "mbr": 327875,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Tominovac",
    "mbr": 327883,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Toranj",
    "mbr": 327891,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Trapari",
    "mbr": 327905,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Trenkovo",
    "mbr": 327913,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Treštanovci",
    "mbr": 327921,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Tulnik",
    "mbr": 327930,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Ugarci",
    "mbr": 327948,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Velika",
    "mbr": 327956,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Veliki Bilac",
    "mbr": 327964,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Venje",
    "mbr": 327972,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Vetovo",
    "mbr": 327999,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Vidovci",
    "mbr": 328006,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Vilic Selo",
    "mbr": 328014,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Vranic",
    "mbr": 328022,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Gradski Vrhovci",
    "mbr": 328049,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Cecavacki Vucjak",
    "mbr": 328057,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Zagrade",
    "mbr": 328065,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Zakorenje",
    "mbr": 328073,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Zarilac",
    "mbr": 328081,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Dolac",
    "mbr": 336769,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Završje",
    "mbr": 336777,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Svetinja",
    "mbr": 336785,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Knežci",
    "mbr": 336793,
    "okn": "Požega",
    "puk": "Požega"
  },
  {
    "ko": "Brdo",
    "mbr": 301779,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Brtonigla",
    "mbr": 301787,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Buje",
    "mbr": 301795,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Cepic",
    "mbr": 301809,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Donja Mirna",
    "mbr": 301817,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Grožnjan",
    "mbr": 301825,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Kaštel",
    "mbr": 301833,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Kostanjica",
    "mbr": 301841,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Krasica",
    "mbr": 301850,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Kršete",
    "mbr": 301868,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Kuberton",
    "mbr": 301876,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Lovrecica",
    "mbr": 301884,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Materada",
    "mbr": 301892,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Merišce",
    "mbr": 301906,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Momjan",
    "mbr": 301914,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Nova Vas",
    "mbr": 301922,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Novigrad",
    "mbr": 301949,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Oprtalj",
    "mbr": 301957,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Petrovija",
    "mbr": 301965,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Savudrija",
    "mbr": 301981,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Šterna",
    "mbr": 301990,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Kucibreg",
    "mbr": 302007,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Triban",
    "mbr": 302015,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Umag",
    "mbr": 302023,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Završje",
    "mbr": 302031,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Zrenj",
    "mbr": 302040,
    "okn": "Buje",
    "puk": "Pula"
  },
  {
    "ko": "Brgudac",
    "mbr": 302058,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Buzet",
    "mbr": 302066,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Buzet-Stari grad",
    "mbr": 302074,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Crnica",
    "mbr": 302082,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Dane",
    "mbr": 302104,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Hum",
    "mbr": 302139,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Jelovice",
    "mbr": 302147,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Lanišce",
    "mbr": 302155,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Marcenegla",
    "mbr": 302171,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Racice",
    "mbr": 302180,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Roc",
    "mbr": 302198,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Senj",
    "mbr": 302201,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Slum",
    "mbr": 302210,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Sovinjak",
    "mbr": 302228,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Sovišcina",
    "mbr": 302236,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Svi Sveti",
    "mbr": 302244,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Salež",
    "mbr": 302252,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Trstenik",
    "mbr": 302279,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Vodice",
    "mbr": 302287,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Vrh",
    "mbr": 302295,
    "okn": "Buzet",
    "puk": "Pula"
  },
  {
    "ko": "Brdo",
    "mbr": 316393,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Cere",
    "mbr": 316407,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Cepic",
    "mbr": 316415,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Diminici",
    "mbr": 316423,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Grobnik",
    "mbr": 316431,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Jesenovik",
    "mbr": 316440,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Kožljak",
    "mbr": 316458,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Krbune",
    "mbr": 316466,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Kršan",
    "mbr": 316474,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Kunj",
    "mbr": 316482,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Labin",
    "mbr": 316504,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Letaj",
    "mbr": 316512,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Mala Kraska",
    "mbr": 316539,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Martinski",
    "mbr": 316547,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Most Raša",
    "mbr": 316555,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Nedešcina",
    "mbr": 316563,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Nova Vas",
    "mbr": 316571,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Novi Labin",
    "mbr": 316580,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Pican",
    "mbr": 316598,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Plomin",
    "mbr": 316601,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Prkušnica",
    "mbr": 316610,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Rabac",
    "mbr": 316628,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Ripenda",
    "mbr": 316636,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Santalezi",
    "mbr": 316644,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Skitaca",
    "mbr": 316652,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Šumber",
    "mbr": 316679,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Šušnjevica",
    "mbr": 316687,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Trget",
    "mbr": 316695,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Tupljak",
    "mbr": 316709,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Labin-Presika",
    "mbr": 337749,
    "okn": "Labin",
    "puk": "Pula"
  },
  {
    "ko": "Belaj",
    "mbr": 322130,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Beram",
    "mbr": 322148,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Boljun",
    "mbr": 322156,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Borut",
    "mbr": 322164,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Brest pod Uckom",
    "mbr": 322172,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Brkac",
    "mbr": 322199,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Butoniga",
    "mbr": 322202,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Cerovlje",
    "mbr": 322229,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Dolenja Vas",
    "mbr": 322237,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Draguc",
    "mbr": 322245,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Gologorica",
    "mbr": 322253,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Gorenja Vas",
    "mbr": 322261,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Gracišce",
    "mbr": 322270,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Gradinje",
    "mbr": 322288,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Grdoselo",
    "mbr": 322296,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Grimalda",
    "mbr": 322300,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Kaldir",
    "mbr": 322318,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Karojba",
    "mbr": 322326,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Kašcerga",
    "mbr": 322334,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Kringa",
    "mbr": 322342,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Kršikla",
    "mbr": 322369,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Lesišcina",
    "mbr": 322377,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Lindar",
    "mbr": 322385,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Motovun",
    "mbr": 322393,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Muntrilj",
    "mbr": 322407,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Novaki Motovunski",
    "mbr": 322415,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Novaki Pazinski",
    "mbr": 322423,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Paz",
    "mbr": 322431,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Pazin",
    "mbr": 322440,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Sveti Petar u Šumi",
    "mbr": 322458,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Previž",
    "mbr": 322466,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Rakotule",
    "mbr": 322474,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Semic",
    "mbr": 322482,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Škopljak",
    "mbr": 322504,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Tibole",
    "mbr": 322512,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Tinjan",
    "mbr": 322539,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Trviž",
    "mbr": 322547,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Vranja",
    "mbr": 322555,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Zabrežani",
    "mbr": 322563,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Zamask",
    "mbr": 322571,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Zarecje",
    "mbr": 322580,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Zumesk",
    "mbr": 322598,
    "okn": "Pazin",
    "puk": "Pula"
  },
  {
    "ko": "Bacva",
    "mbr": 323594,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Baderna",
    "mbr": 323608,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Dracevac",
    "mbr": 323616,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Frata",
    "mbr": 323624,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Funtana",
    "mbr": 323632,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Fuškulin",
    "mbr": 323659,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Gradina",
    "mbr": 323667,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Kaštelir",
    "mbr": 323675,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Labinci",
    "mbr": 323683,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Lim",
    "mbr": 323691,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Lovrec",
    "mbr": 323705,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Mugeba",
    "mbr": 323713,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Mušalež",
    "mbr": 323721,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Nova Vas",
    "mbr": 323730,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Poreč",
    "mbr": 323748,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Sveti Ivan",
    "mbr": 323756,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Sveti Vital",
    "mbr": 323764,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Tar",
    "mbr": 323772,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Vabriga",
    "mbr": 323799,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Višnjan",
    "mbr": 323802,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Vižinada",
    "mbr": 323829,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Vrsar",
    "mbr": 323837,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Varvari",
    "mbr": 323845,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Žbandaj",
    "mbr": 323853,
    "okn": "Poreč",
    "puk": "Pula"
  },
  {
    "ko": "Barban",
    "mbr": 324051,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Bokordici",
    "mbr": 324060,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Brioni",
    "mbr": 324078,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Fažana",
    "mbr": 324086,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Filipana",
    "mbr": 324094,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Galižana",
    "mbr": 324108,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Gocan",
    "mbr": 324116,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Juršici",
    "mbr": 324124,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Kavran",
    "mbr": 324132,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Krnica",
    "mbr": 324159,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Ližnjan",
    "mbr": 324167,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Loborika",
    "mbr": 324175,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Marcana",
    "mbr": 324183,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Medulin",
    "mbr": 324191,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Muntic",
    "mbr": 324205,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Peroj",
    "mbr": 324213,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Pomer",
    "mbr": 324221,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Premantura",
    "mbr": 324230,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Prnjani",
    "mbr": 324248,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Pula",
    "mbr": 324256,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Rakalj",
    "mbr": 324264,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Smoljanci",
    "mbr": 324272,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Sutivanac",
    "mbr": 324299,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Svetvincenat",
    "mbr": 324302,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Šajini",
    "mbr": 324329,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Šišan",
    "mbr": 324337,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Štinjan",
    "mbr": 324345,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Štokovci",
    "mbr": 324353,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Valtura",
    "mbr": 324361,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Vodnjan",
    "mbr": 324370,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Štinjan-nova",
    "mbr": 338273,
    "okn": "Pula",
    "puk": "Pula"
  },
  {
    "ko": "Bale",
    "mbr": 324876,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Kanfanar",
    "mbr": 324884,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Krajcar Breg",
    "mbr": 324892,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Mrgani",
    "mbr": 324906,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Rovinj",
    "mbr": 324914,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Rovinjsko Selo",
    "mbr": 324922,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Sošici",
    "mbr": 324949,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Žminj",
    "mbr": 324957,
    "okn": "Rovinj",
    "puk": "Pula"
  },
  {
    "ko": "Čabar",
    "mbr": 302694,
    "okn": "Čabar",
    "puk": "Rijeka"
  },
  {
    "ko": "Gerovo",
    "mbr": 302708,
    "okn": "Čabar",
    "puk": "Rijeka"
  },
  {
    "ko": "Goraci",
    "mbr": 302716,
    "okn": "Čabar",
    "puk": "Rijeka"
  },
  {
    "ko": "Hrib",
    "mbr": 302724,
    "okn": "Čabar",
    "puk": "Rijeka"
  },
  {
    "ko": "Plešce",
    "mbr": 302732,
    "okn": "Čabar",
    "puk": "Rijeka"
  },
  {
    "ko": "Prezid",
    "mbr": 302759,
    "okn": "Čabar",
    "puk": "Rijeka"
  },
  {
    "ko": "Beli",
    "mbr": 302317,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Cres",
    "mbr": 302325,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Cres-grad",
    "mbr": 302333,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Dragozetici",
    "mbr": 302350,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Lubenice",
    "mbr": 302368,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Martinšcica",
    "mbr": 302392,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Orlec",
    "mbr": 302414,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Pernat",
    "mbr": 302449,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Podol",
    "mbr": 302457,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Predošcica",
    "mbr": 302465,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Stivan",
    "mbr": 302481,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Valun",
    "mbr": 302538,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Vrana",
    "mbr": 302554,
    "okn": "Cres",
    "puk": "Rijeka"
  },
  {
    "ko": "Belgrad",
    "mbr": 302562,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Bribir",
    "mbr": 302589,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Crikvenica",
    "mbr": 302597,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Dramalj",
    "mbr": 302619,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Drivenik",
    "mbr": 302627,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Grižane",
    "mbr": 302635,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Jadranovo",
    "mbr": 302643,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Krmpote",
    "mbr": 302651,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Ledenice",
    "mbr": 302660,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Novi",
    "mbr": 302678,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Selce",
    "mbr": 302686,
    "okn": "Crikvenica",
    "puk": "Rijeka"
  },
  {
    "ko": "Begovo Razdolje",
    "mbr": 304476,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Belo",
    "mbr": 304484,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Brod Moravice",
    "mbr": 304492,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Brod na Kupi",
    "mbr": 304506,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Bukov Vrh",
    "mbr": 304514,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Crni Lug",
    "mbr": 304522,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Delnice",
    "mbr": 304549,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Divjake",
    "mbr": 304557,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Fužine",
    "mbr": 304565,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Grbajel",
    "mbr": 304573,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Lic",
    "mbr": 304581,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Lokve",
    "mbr": 304590,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Mrkopalj",
    "mbr": 304603,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Mrzla Vodica",
    "mbr": 304611,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Ravna Gora",
    "mbr": 304620,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Razdrto",
    "mbr": 304638,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Razloge",
    "mbr": 304646,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Stara Sušica",
    "mbr": 304654,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Stari Laz",
    "mbr": 304662,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Sunger",
    "mbr": 304689,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Turke",
    "mbr": 304697,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Vrata",
    "mbr": 304719,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Završje",
    "mbr": 304727,
    "okn": "Delnice",
    "puk": "Rijeka"
  },
  {
    "ko": "Baška",
    "mbr": 315834,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Batomalj",
    "mbr": 315842,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Bogovici",
    "mbr": 315869,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Dobrinj",
    "mbr": 315877,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Draga Bašcanska",
    "mbr": 315885,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Garica",
    "mbr": 315893,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Kornic",
    "mbr": 315907,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Krk",
    "mbr": 315915,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Krk-grad",
    "mbr": 315923,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Linardici",
    "mbr": 315931,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Miholjice",
    "mbr": 315940,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Omišalj",
    "mbr": 315958,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Poljica",
    "mbr": 315966,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Punat",
    "mbr": 315974,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Skrbcici",
    "mbr": 315982,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Soline",
    "mbr": 316008,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Stara Baška",
    "mbr": 316016,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Sužan",
    "mbr": 316024,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Sveti Anton",
    "mbr": 316032,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Vrbnik",
    "mbr": 316059,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Vrh",
    "mbr": 316067,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Baška-nova",
    "mbr": 337285,
    "okn": "Krk",
    "puk": "Rijeka"
  },
  {
    "ko": "Belej",
    "mbr": 302309,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Cunski",
    "mbr": 302341,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Mali Lošinj",
    "mbr": 302376,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Mali Lošinj-grad",
    "mbr": 302384,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Nerezine",
    "mbr": 302406,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Osor",
    "mbr": 302422,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Punta Križa",
    "mbr": 302473,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Susak",
    "mbr": 302490,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Sveti Jakov",
    "mbr": 302503,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Unije",
    "mbr": 302511,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Ustrine",
    "mbr": 302520,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Veli Lošinj",
    "mbr": 302546,
    "okn": "Mali Lošinj",
    "puk": "Rijeka"
  },
  {
    "ko": "Brdce",
    "mbr": 319821,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Bregi",
    "mbr": 319830,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Brgud",
    "mbr": 319848,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Brsec",
    "mbr": 319856,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Jurdani",
    "mbr": 319864,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Jušici",
    "mbr": 319872,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Kalac",
    "mbr": 319899,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Kraj",
    "mbr": 319902,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Kuceli",
    "mbr": 319929,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Lipa",
    "mbr": 319937,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Lovran",
    "mbr": 319945,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Martina",
    "mbr": 319953,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Matulji",
    "mbr": 319961,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Mošcenice",
    "mbr": 319970,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Mošcenicka Draga",
    "mbr": 319988,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Mune",
    "mbr": 319996,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Opatija",
    "mbr": 320005,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Opric",
    "mbr": 320013,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Pasjak",
    "mbr": 320021,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Perenici",
    "mbr": 320030,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Pobri",
    "mbr": 320048,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Poljane",
    "mbr": 320056,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Puharska",
    "mbr": 320064,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Puži",
    "mbr": 320072,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Rukavac Donji",
    "mbr": 320099,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Rukavac Gornji",
    "mbr": 320102,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Rupa",
    "mbr": 320129,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Šapjane",
    "mbr": 320137,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Tuliševica",
    "mbr": 320145,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Vasanska",
    "mbr": 320153,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Veprinac",
    "mbr": 320161,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Volosko",
    "mbr": 320170,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Zvoneca",
    "mbr": 320188,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Žejane",
    "mbr": 320196,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Mihotici",
    "mbr": 337170,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Ika-Opric",
    "mbr": 337765,
    "okn": "Opatija",
    "puk": "Rijeka"
  },
  {
    "ko": "Banjol",
    "mbr": 324388,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Barbat",
    "mbr": 324396,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Kampor",
    "mbr": 324400,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Lopar",
    "mbr": 324418,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Rab-Mundanije",
    "mbr": 324434,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Supetarska Draga",
    "mbr": 324442,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Sveti Grgur",
    "mbr": 336874,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Goli Otok",
    "mbr": 336882,
    "okn": "Rab",
    "puk": "Rijeka"
  },
  {
    "ko": "Bakar",
    "mbr": 324469,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Bakarac",
    "mbr": 324477,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Breza",
    "mbr": 324485,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Cernik-Cavle",
    "mbr": 324493,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Draga",
    "mbr": 324507,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Dražice",
    "mbr": 324515,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Drenova",
    "mbr": 324523,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Grobnik",
    "mbr": 324531,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Hreljin",
    "mbr": 324540,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Jelenje",
    "mbr": 324558,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Jelenje-Lepenice",
    "mbr": 324566,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Kastav",
    "mbr": 324574,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Klana",
    "mbr": 324582,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Kostrena-Barbara",
    "mbr": 324604,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Kostrena-Lucija",
    "mbr": 324612,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Kraljevica",
    "mbr": 324639,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Krasica",
    "mbr": 324647,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Kukuljanovo",
    "mbr": 324655,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Lisac",
    "mbr": 324663,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Marcelji",
    "mbr": 324671,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Marinici",
    "mbr": 324680,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Pašac",
    "mbr": 324698,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Podhum",
    "mbr": 324701,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Podrvanj",
    "mbr": 324710,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Praputnjak",
    "mbr": 324728,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Recina",
    "mbr": 324736,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Ružic Selo",
    "mbr": 324744,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Srdoci",
    "mbr": 324752,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Stari Grad",
    "mbr": 324779,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Studena",
    "mbr": 324787,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Sušak",
    "mbr": 324795,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Škalnica",
    "mbr": 324809,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Škrljevo",
    "mbr": 324817,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Šmrika",
    "mbr": 324825,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Veli Dol",
    "mbr": 324833,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Viškovo",
    "mbr": 324841,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Zamet",
    "mbr": 324850,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Zlobin",
    "mbr": 324868,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Bakar Sv. Kuzam",
    "mbr": 337889,
    "okn": "Rijeka",
    "puk": "Rijeka"
  },
  {
    "ko": "Blaževci",
    "mbr": 333344,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Gomirje",
    "mbr": 333352,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Gomirsko Vrbovsko",
    "mbr": 333379,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Klanac",
    "mbr": 333387,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Lukovdol",
    "mbr": 333395,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Draga Lukovdolska",
    "mbr": 333409,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Mali Jadrc",
    "mbr": 333417,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Osojnik",
    "mbr": 333425,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Plemenitaš",
    "mbr": 333433,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Rim",
    "mbr": 333441,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Severin na Kupi",
    "mbr": 333450,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Moravice",
    "mbr": 333468,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Veliki Jadrc",
    "mbr": 333476,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Vrbovsko",
    "mbr": 333484,
    "okn": "Vrbovsko",
    "puk": "Rijeka"
  },
  {
    "ko": "Brdani",
    "mbr": 308196,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Buinja",
    "mbr": 308200,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Cavlovica",
    "mbr": 308218,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Core",
    "mbr": 308226,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Divuša",
    "mbr": 308234,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Dobretin",
    "mbr": 308242,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Draga",
    "mbr": 308269,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Draškovac",
    "mbr": 308277,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Dvor",
    "mbr": 308285,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Gage",
    "mbr": 308293,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Glavicani",
    "mbr": 308307,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Golubovac",
    "mbr": 308315,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Goricka",
    "mbr": 308323,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Grabovica",
    "mbr": 308331,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Grmušani",
    "mbr": 308340,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Gvozdansko",
    "mbr": 308358,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Javnica",
    "mbr": 308366,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Javoranj",
    "mbr": 308374,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Javornik",
    "mbr": 308382,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Kobiljak",
    "mbr": 308404,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Komora",
    "mbr": 308412,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Kosna",
    "mbr": 308439,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Kozibrod",
    "mbr": 308447,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Kuljani",
    "mbr": 308455,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Lotine",
    "mbr": 308463,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Ljeskovac",
    "mbr": 308471,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Ljubina",
    "mbr": 308480,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Majdan",
    "mbr": 308498,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Matijevici",
    "mbr": 308501,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Oraovica",
    "mbr": 308510,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Pedalj",
    "mbr": 308528,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Rogulje",
    "mbr": 308536,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Rujevac",
    "mbr": 308544,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Socanica",
    "mbr": 308552,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Stanic Polje",
    "mbr": 308579,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Struga",
    "mbr": 308587,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Stupnica",
    "mbr": 308595,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Šakanlije",
    "mbr": 308609,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Šegestin",
    "mbr": 308617,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Švrakarica",
    "mbr": 308625,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Udetin",
    "mbr": 308633,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Uncani",
    "mbr": 308641,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Volinja",
    "mbr": 308650,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Vrpolje",
    "mbr": 308668,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Zakopa",
    "mbr": 308676,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Zamlaca",
    "mbr": 308684,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Zrin",
    "mbr": 308692,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Zut",
    "mbr": 308706,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Žirovac",
    "mbr": 308714,
    "okn": "Dvor",
    "puk": "Sisak"
  },
  {
    "ko": "Balinac",
    "mbr": 309915,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Bijele Vode",
    "mbr": 309923,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Bišcanovo",
    "mbr": 309931,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Bojna",
    "mbr": 309940,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Borovita",
    "mbr": 309958,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Baturi",
    "mbr": 309966,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Brestik",
    "mbr": 309974,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Brezovo Polje",
    "mbr": 309982,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Brnjeuška",
    "mbr": 310000,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Brubno",
    "mbr": 310018,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Bucica",
    "mbr": 310026,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Buzeta",
    "mbr": 310034,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Dabrina",
    "mbr": 310042,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Desni Degoj",
    "mbr": 310069,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Dolnjaki",
    "mbr": 310077,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Donja Trstenica",
    "mbr": 310085,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Donje Selište",
    "mbr": 310093,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Donji Selkovac",
    "mbr": 310107,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Dragotina",
    "mbr": 310115,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Drenovac Banski",
    "mbr": 310123,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Dvorišce",
    "mbr": 310131,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Glina",
    "mbr": 310140,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Gornje Selište",
    "mbr": 310158,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Gornji Selkovac",
    "mbr": 310166,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Gracanica Šišinecka",
    "mbr": 310174,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Hader",
    "mbr": 310182,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Hajtic",
    "mbr": 310204,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Ilovacak",
    "mbr": 310212,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Jame",
    "mbr": 310239,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Joševica",
    "mbr": 310247,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Kihalac",
    "mbr": 310255,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Klasnic",
    "mbr": 310263,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Kozaperovica",
    "mbr": 310271,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Maja",
    "mbr": 310280,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Majske Poljane",
    "mbr": 310298,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Majski Trtnik",
    "mbr": 310301,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Mala Solina",
    "mbr": 310310,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Mali Gradac",
    "mbr": 310328,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Marinbrod",
    "mbr": 310336,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Martinovici",
    "mbr": 310344,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Novo Selo Glinsko",
    "mbr": 310352,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Obljaj",
    "mbr": 310379,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Pokupska Slatina",
    "mbr": 310387,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Prekopa",
    "mbr": 310395,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Prijeka",
    "mbr": 310409,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Ravno Rašce",
    "mbr": 310417,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Roviška",
    "mbr": 310425,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Skela",
    "mbr": 310433,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Stankovac",
    "mbr": 310441,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Svracica",
    "mbr": 310450,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Šaševa",
    "mbr": 310468,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Šatornja",
    "mbr": 310476,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Šibine",
    "mbr": 310484,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Taborište",
    "mbr": 310492,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Trtnik Glinski",
    "mbr": 310506,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Turcenica",
    "mbr": 310514,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Velika Solina",
    "mbr": 310522,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Veliki Gradac",
    "mbr": 310549,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Viduševac",
    "mbr": 310557,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Vlahovic",
    "mbr": 310565,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Zaloj",
    "mbr": 310573,
    "okn": "Glina",
    "puk": "Sisak"
  },
  {
    "ko": "Babina Rijeka",
    "mbr": 314790,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Bacin",
    "mbr": 314803,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Bjelovac",
    "mbr": 314811,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Borojevici",
    "mbr": 314820,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Cerovljani",
    "mbr": 314838,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Cukur",
    "mbr": 314846,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Donja Velešnja",
    "mbr": 314854,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Dubica",
    "mbr": 314862,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Gornja Velešnja",
    "mbr": 314889,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Gornji Hrastovac",
    "mbr": 314897,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Graboštani",
    "mbr": 314919,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Knezovljani",
    "mbr": 314927,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Komogovina",
    "mbr": 314935,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Kostajnica",
    "mbr": 314943,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Majur",
    "mbr": 314951,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Krcevo",
    "mbr": 314960,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Kukuruzari",
    "mbr": 314978,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Lovca",
    "mbr": 314986,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Mecencani",
    "mbr": 314994,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Meminska",
    "mbr": 315001,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Mracaj",
    "mbr": 315010,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Panjani",
    "mbr": 315028,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Prevršac",
    "mbr": 315036,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Rausovac",
    "mbr": 315044,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Selište Kostajnicko",
    "mbr": 315052,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Slabinja",
    "mbr": 315079,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Stubalj",
    "mbr": 315087,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Svinica",
    "mbr": 315095,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Umetic",
    "mbr": 315109,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Utolica",
    "mbr": 315117,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Živaja",
    "mbr": 315125,
    "okn": "Hrvatska Kostajnica",
    "puk": "Sisak"
  },
  {
    "ko": "Banova Jaruga",
    "mbr": 316075,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Batina",
    "mbr": 316083,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Caire",
    "mbr": 316091,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Gornja Jelenska",
    "mbr": 316105,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Grabrov Potok",
    "mbr": 316113,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Gracenica",
    "mbr": 316121,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Husain",
    "mbr": 316130,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Ilova",
    "mbr": 316148,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Jamarica",
    "mbr": 316156,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Janja Lipa",
    "mbr": 316164,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Katolicko Selišce",
    "mbr": 316172,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Kutina",
    "mbr": 316199,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Kutinica",
    "mbr": 316202,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Ludina",
    "mbr": 316229,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Meduric",
    "mbr": 316237,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Mikleuška",
    "mbr": 316245,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Okoli",
    "mbr": 316253,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Osekovo",
    "mbr": 316261,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Popovaca",
    "mbr": 316270,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Potok",
    "mbr": 316288,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Repušnica",
    "mbr": 316296,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Ruškovica",
    "mbr": 316300,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Selište",
    "mbr": 316318,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Stružec",
    "mbr": 316326,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Stupovaca",
    "mbr": 316334,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Šartovac",
    "mbr": 316342,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Vidrenjak",
    "mbr": 316369,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Voloder",
    "mbr": 316377,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Zbjegovaca",
    "mbr": 316385,
    "okn": "Kutina",
    "puk": "Sisak"
  },
  {
    "ko": "Borovac",
    "mbr": 318710,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Brestaca",
    "mbr": 318728,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Brocice",
    "mbr": 318736,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Rajic Donji",
    "mbr": 318744,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Drenov Bok",
    "mbr": 318752,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Goleši",
    "mbr": 318779,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Rajic Gornji",
    "mbr": 318787,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Jablanac",
    "mbr": 318795,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Jasenovac",
    "mbr": 318809,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Jazavica",
    "mbr": 318817,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Košutarica",
    "mbr": 318825,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Kozarice",
    "mbr": 318833,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Krapje",
    "mbr": 318841,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Kricke",
    "mbr": 318850,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Krivaj",
    "mbr": 318868,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Lipovljani",
    "mbr": 318876,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Lovska",
    "mbr": 318884,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Mlaka",
    "mbr": 318892,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Novi Grabovac",
    "mbr": 318906,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Novska",
    "mbr": 318914,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Paklenica",
    "mbr": 318922,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Piljenice",
    "mbr": 318949,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Plesmo",
    "mbr": 318957,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Puska",
    "mbr": 318965,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Roždanik",
    "mbr": 318973,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Subocka",
    "mbr": 318981,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Stari Grabovac",
    "mbr": 318990,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Subocki Grad",
    "mbr": 319007,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Uštica",
    "mbr": 319015,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Kraljeva Velika",
    "mbr": 319023,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Vocarica",
    "mbr": 319031,
    "okn": "Novska",
    "puk": "Sisak"
  },
  {
    "ko": "Bacuga",
    "mbr": 322601,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Begovici",
    "mbr": 322610,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Bijelnik",
    "mbr": 322628,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Blinja",
    "mbr": 322636,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Brest Pokupski",
    "mbr": 322644,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Donja Budicina",
    "mbr": 322652,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Cepeliš",
    "mbr": 322679,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Cuntic",
    "mbr": 322687,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Deanovici",
    "mbr": 322695,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Dolcani",
    "mbr": 322709,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Dragotinci",
    "mbr": 322717,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Glinska Poljana",
    "mbr": 322725,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Gora",
    "mbr": 322733,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Gorske Mokrice",
    "mbr": 322741,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Graberje",
    "mbr": 322750,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Grabovac Banski",
    "mbr": 322768,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Hrastovica",
    "mbr": 322776,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Jabukovac",
    "mbr": 322784,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Jošavica",
    "mbr": 322792,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Klinac",
    "mbr": 322806,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Kraljevcani",
    "mbr": 322814,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Križ Hrastovacki",
    "mbr": 322822,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Letovanci",
    "mbr": 322849,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Lušcani",
    "mbr": 322857,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Mackovo Selo",
    "mbr": 322865,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Madžari",
    "mbr": 322873,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Medurace",
    "mbr": 322881,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Mlinoga",
    "mbr": 322890,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Mošcenica",
    "mbr": 322903,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Moštanica",
    "mbr": 322911,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Nova Drencina",
    "mbr": 322920,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Novo Selište",
    "mbr": 322938,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Pastuša",
    "mbr": 322946,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Pecki",
    "mbr": 322954,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Petkovac",
    "mbr": 322962,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Petrinja",
    "mbr": 322989,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Prnjavor Cunticki",
    "mbr": 322997,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Sibic",
    "mbr": 323004,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Slana",
    "mbr": 323012,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Strašnik",
    "mbr": 323039,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Stražbenica",
    "mbr": 323047,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Taborište",
    "mbr": 323055,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Tremušnjak",
    "mbr": 323063,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Veliki Šušnjar",
    "mbr": 323071,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Vratecko",
    "mbr": 323080,
    "okn": "Petrinja",
    "puk": "Sisak"
  },
  {
    "ko": "Bestrma",
    "mbr": 326020,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Bistrac",
    "mbr": 326038,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Blinjski Kut",
    "mbr": 326046,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Bobovac",
    "mbr": 326054,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Bok Palanjecki",
    "mbr": 326062,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Brdani",
    "mbr": 326089,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Brkiševina",
    "mbr": 326097,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Budaševo-Topolovac",
    "mbr": 326119,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Cerje Letovanicko",
    "mbr": 326127,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Crkveni Bok",
    "mbr": 326135,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Crnac",
    "mbr": 326143,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Capljani",
    "mbr": 326151,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Cigoc",
    "mbr": 326160,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Desno Trebarjevo",
    "mbr": 326178,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Desno Željezno",
    "mbr": 326186,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Donje Komarevo",
    "mbr": 326194,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Donji Hrastovac",
    "mbr": 326208,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Drljaca",
    "mbr": 326216,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Dužica",
    "mbr": 326224,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Galdovo",
    "mbr": 326232,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Farkašic",
    "mbr": 326259,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Gornje Komarevo",
    "mbr": 326267,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Gradusa Posavska",
    "mbr": 326275,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Greda",
    "mbr": 326283,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Greda Sunjska",
    "mbr": 326291,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Gušce",
    "mbr": 326305,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Hrastelnica",
    "mbr": 326313,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Ivanjski Bok",
    "mbr": 326321,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Jasenovcani",
    "mbr": 326330,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Jazvenik",
    "mbr": 326348,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Jezero Posavsko",
    "mbr": 326356,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Kinjacka",
    "mbr": 326364,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Kostreši Šaški",
    "mbr": 326372,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Kratecko",
    "mbr": 326399,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Krivaj Sunjski",
    "mbr": 326402,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Lekenik",
    "mbr": 326429,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Letina",
    "mbr": 326437,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Letovanic",
    "mbr": 326445,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Lijeva Luka",
    "mbr": 326453,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Lonja",
    "mbr": 326461,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Mahovo",
    "mbr": 326470,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Mala Gradusa",
    "mbr": 326488,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Mala Paukova",
    "mbr": 326496,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Martinska Ves",
    "mbr": 326500,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Martinska Ves Lijeva",
    "mbr": 326518,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Mužilovcica",
    "mbr": 326526,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Novi Sisak",
    "mbr": 326534,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Novo Selo",
    "mbr": 326542,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Novoselci",
    "mbr": 326569,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Odra",
    "mbr": 326577,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Palanjek",
    "mbr": 326585,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Papici",
    "mbr": 326593,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Pešcenica",
    "mbr": 326607,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Petrinjci",
    "mbr": 326615,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Pobrdani",
    "mbr": 326623,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Poljana Lekenicka",
    "mbr": 326631,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Pracno",
    "mbr": 326640,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Prelošcica",
    "mbr": 326658,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Radonja Luka",
    "mbr": 326666,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Sela",
    "mbr": 326674,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Selišce",
    "mbr": 326682,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Setuš",
    "mbr": 326704,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Sjeverovac",
    "mbr": 326712,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Slovinci",
    "mbr": 326739,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Stara Drencina",
    "mbr": 326747,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Sisak Stari",
    "mbr": 326755,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Staro Selo",
    "mbr": 326763,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Staza",
    "mbr": 326771,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Strmen",
    "mbr": 326780,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Stupno",
    "mbr": 326798,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Sunja",
    "mbr": 326801,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Svinjicko",
    "mbr": 326810,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Šaš",
    "mbr": 326828,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Šišinec",
    "mbr": 326836,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Timarci",
    "mbr": 326844,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Tišina Erdedska",
    "mbr": 326852,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Turopoljski Lekenik",
    "mbr": 326879,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Vedro Polje",
    "mbr": 326887,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Velika Gradusa",
    "mbr": 326895,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Vukojevac",
    "mbr": 326909,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Vukoševac",
    "mbr": 326917,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Žažina",
    "mbr": 326925,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Žircica",
    "mbr": 326933,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Žreme",
    "mbr": 326941,
    "okn": "Sisak",
    "puk": "Sisak"
  },
  {
    "ko": "Batinova Kosa",
    "mbr": 333492,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Blatuša",
    "mbr": 333506,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Bovic",
    "mbr": 333514,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Brnjavac",
    "mbr": 333522,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Bukovica",
    "mbr": 333549,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Cremušnica",
    "mbr": 333557,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Crni Potok",
    "mbr": 333565,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Cemernica",
    "mbr": 333573,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Dugo Selo Lasinjsko",
    "mbr": 333603,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Golinja",
    "mbr": 333611,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Gornja Trstenica",
    "mbr": 333620,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Gredani",
    "mbr": 333638,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Hrvatsko Selo",
    "mbr": 333646,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Katinovac",
    "mbr": 333654,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Kirin",
    "mbr": 333662,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Kozarac",
    "mbr": 333689,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Mala Vranovina",
    "mbr": 333719,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Malicka",
    "mbr": 333727,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Ostrožin",
    "mbr": 333735,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Pecka",
    "mbr": 333743,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Perna",
    "mbr": 333751,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Pješcanica",
    "mbr": 333760,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Podgorje",
    "mbr": 333778,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Ponikvari",
    "mbr": 333786,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Slavsko Polje",
    "mbr": 333794,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Staro Selo Topusko",
    "mbr": 333808,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Stipan",
    "mbr": 333816,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Šljivovac",
    "mbr": 333824,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Topusko",
    "mbr": 333832,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Trepca",
    "mbr": 333859,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Velika Vranovina",
    "mbr": 333867,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Vorkapic",
    "mbr": 333875,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Vrginmost",
    "mbr": 333883,
    "okn": "Topusko",
    "puk": "Sisak"
  },
  {
    "ko": "Adžamovci",
    "mbr": 317560,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bacin Dol",
    "mbr": 317578,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Banicevac",
    "mbr": 317586,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Batrina",
    "mbr": 317594,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Benkovac",
    "mbr": 317608,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bijela Stijena",
    "mbr": 317616,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bili Brig",
    "mbr": 317624,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bobare",
    "mbr": 317632,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bodegraj",
    "mbr": 317659,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bodovaljci",
    "mbr": 317667,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Brdani",
    "mbr": 317675,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Cage",
    "mbr": 317683,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Cernik",
    "mbr": 317691,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Crnogovci",
    "mbr": 317705,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Caprginci",
    "mbr": 317713,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Covac",
    "mbr": 317721,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Davor",
    "mbr": 317730,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Dolina",
    "mbr": 317748,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Donji Bogicevci",
    "mbr": 317756,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Donji Lipovac",
    "mbr": 317764,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Donji Varoš",
    "mbr": 317772,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Dragalic",
    "mbr": 317799,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Dragovci",
    "mbr": 317802,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Dubovac",
    "mbr": 317829,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Giletinci",
    "mbr": 317837,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Godinjak",
    "mbr": 317845,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Golobrdac",
    "mbr": 317853,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gorice",
    "mbr": 317861,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Trnava",
    "mbr": 317870,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gornji Bogicevci",
    "mbr": 317888,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Drežnik",
    "mbr": 317896,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gornji Lipovac",
    "mbr": 317900,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gornji Varoš",
    "mbr": 317918,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gredani",
    "mbr": 317926,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gunjavci",
    "mbr": 317934,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Komarnica",
    "mbr": 317942,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Kosovac",
    "mbr": 317969,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Kovacevac",
    "mbr": 317977,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Ladevac",
    "mbr": 317985,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Laze",
    "mbr": 317993,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Lještani",
    "mbr": 318019,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Ljupina",
    "mbr": 318027,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Mackovac",
    "mbr": 318035,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Magic Mala",
    "mbr": 318043,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Mašic",
    "mbr": 318051,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Medari",
    "mbr": 318060,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Nova Gradiška",
    "mbr": 318078,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Nova Kapela",
    "mbr": 318086,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Novi Varoš",
    "mbr": 318094,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Okucani",
    "mbr": 318108,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Opatovac",
    "mbr": 318116,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Orubica",
    "mbr": 318124,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Oštri Vrh",
    "mbr": 318132,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Podvrško",
    "mbr": 318159,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Poljane",
    "mbr": 318167,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Prvca",
    "mbr": 318175,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Ratkovac",
    "mbr": 318183,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Rešetari",
    "mbr": 318191,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Rogolji",
    "mbr": 318205,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Seoce",
    "mbr": 318213,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Sicice",
    "mbr": 318221,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Sice",
    "mbr": 318230,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Smrtic",
    "mbr": 318248,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Srednji Lipovac",
    "mbr": 318256,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Stara Kapela",
    "mbr": 318264,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Staro Petrovo Selo",
    "mbr": 318272,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Šagovina Cernicka",
    "mbr": 318299,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Šagovina Mašicka",
    "mbr": 318302,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Širinci",
    "mbr": 318329,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Štivica",
    "mbr": 318337,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Šumetlica",
    "mbr": 318345,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Tisovac",
    "mbr": 318353,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Trnakovac",
    "mbr": 318361,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Uskoci",
    "mbr": 318370,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Vrbje",
    "mbr": 318388,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Vrbova",
    "mbr": 318396,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Vrbovljani",
    "mbr": 318400,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Žuberkovac",
    "mbr": 318418,
    "okn": "Nova Gradiška",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Banovci",
    "mbr": 328090,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bebrina",
    "mbr": 328103,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Becic",
    "mbr": 328111,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Beravci",
    "mbr": 328120,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bicko Selo",
    "mbr": 328138,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Brodski Stupnik",
    "mbr": 328146,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Brodski Varoš",
    "mbr": 328154,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Brodsko Vinogorje",
    "mbr": 328162,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Bukovlje",
    "mbr": 328189,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Ciglenik",
    "mbr": 328197,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Crni Potok",
    "mbr": 328219,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Cajkovci",
    "mbr": 328227,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Divoševci",
    "mbr": 328235,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Donja Bebrina",
    "mbr": 328243,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Donji Andrijevci",
    "mbr": 328251,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Dubocac",
    "mbr": 328260,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Dubovik",
    "mbr": 328278,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Garcin",
    "mbr": 328286,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Glogovica",
    "mbr": 328294,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gornja Bebrina",
    "mbr": 328308,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gornji Andrijevci",
    "mbr": 328316,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Grabarje",
    "mbr": 328324,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gromacnik",
    "mbr": 328332,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Gundinci",
    "mbr": 328359,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Jaruge",
    "mbr": 328367,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Ježevik",
    "mbr": 328375,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Kaniža",
    "mbr": 328383,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Kindrovo",
    "mbr": 328391,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Klakar",
    "mbr": 328405,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Klokocevik",
    "mbr": 328413,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Korduševci",
    "mbr": 328421,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Kruševica",
    "mbr": 328430,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Kujnik",
    "mbr": 328448,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Kupina",
    "mbr": 328456,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Stupnicki Kuti",
    "mbr": 328464,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Trnjanski Kuti",
    "mbr": 328472,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Lovcic",
    "mbr": 328499,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Lužani",
    "mbr": 328502,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Mala Kopanica",
    "mbr": 328529,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Malino",
    "mbr": 328537,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Matkovic Mala",
    "mbr": 328545,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Novi Grad",
    "mbr": 328553,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Novo Topolje",
    "mbr": 328561,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Odvorci",
    "mbr": 328570,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Oprisavci",
    "mbr": 328588,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Oriovac",
    "mbr": 328596,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Oriovcic",
    "mbr": 328600,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Podcrkavlje",
    "mbr": 328618,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Podvinje",
    "mbr": 328626,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Poljanci",
    "mbr": 328634,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Pricac",
    "mbr": 328642,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Prnjavor",
    "mbr": 328669,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Radovanje",
    "mbr": 328677,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Rastušje",
    "mbr": 328685,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Rušcica",
    "mbr": 328693,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Sapci",
    "mbr": 328707,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Selna",
    "mbr": 328715,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Sibinj",
    "mbr": 328723,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Sikirevci",
    "mbr": 328731,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Slatinik Mali",
    "mbr": 328740,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Slavonski Brod",
    "mbr": 328758,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Slavonski Kobaš",
    "mbr": 328766,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Slobodnica",
    "mbr": 328774,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Sredanci",
    "mbr": 328782,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Stari Perkovci",
    "mbr": 328804,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Stari Slatinik",
    "mbr": 328812,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Staro Topolje",
    "mbr": 328839,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Stružani",
    "mbr": 328847,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Svilaj",
    "mbr": 328855,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Slavonski Šamac",
    "mbr": 328863,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Šumece",
    "mbr": 328871,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Šušnjevci",
    "mbr": 328880,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Tomica",
    "mbr": 328898,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Trnjani",
    "mbr": 328901,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Velika Kopanica",
    "mbr": 328910,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Vranovci",
    "mbr": 328928,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Vrba",
    "mbr": 328936,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Vrhovina",
    "mbr": 328944,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Vrpolje",
    "mbr": 328952,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Zadubravlje",
    "mbr": 328979,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Zbjeg",
    "mbr": 328987,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Brodski Zdenci",
    "mbr": 328995,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Zoljani",
    "mbr": 329002,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Živike",
    "mbr": 329029,
    "okn": "Slavonski Brod",
    "puk": "Slavonski Brod"
  },
  {
    "ko": "Brusje",
    "mbr": 311642,
    "okn": "Hvar",
    "puk": "Split"
  },
  {
    "ko": "Grablje",
    "mbr": 311685,
    "okn": "Hvar",
    "puk": "Split"
  },
  {
    "ko": "Hvar",
    "mbr": 311693,
    "okn": "Hvar",
    "puk": "Split"
  },
  {
    "ko": "Aržano",
    "mbr": 311804,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Biorine",
    "mbr": 311812,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Cista",
    "mbr": 311839,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Dobranje",
    "mbr": 311847,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Glavina Gornja",
    "mbr": 311855,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Imotski-Glavina",
    "mbr": 311863,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Krstatice",
    "mbr": 311871,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Lokvicic",
    "mbr": 311880,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Lovrec",
    "mbr": 311898,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Medovdolac",
    "mbr": 311901,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Opanci",
    "mbr": 311910,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Podbablje",
    "mbr": 311928,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Poljica",
    "mbr": 311936,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Postranje",
    "mbr": 311944,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Proložac",
    "mbr": 311952,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Ricice",
    "mbr": 311979,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Runovic",
    "mbr": 311987,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Slivno",
    "mbr": 311995,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Studenci",
    "mbr": 312002,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Svib",
    "mbr": 312029,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Vinjani",
    "mbr": 312037,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Zagvozd",
    "mbr": 312045,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Župa",
    "mbr": 312053,
    "okn": "Imotski",
    "puk": "Split"
  },
  {
    "ko": "Bogdanovici",
    "mbr": 313424,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Divojevici",
    "mbr": 313432,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kaštel Gomilica",
    "mbr": 313459,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kaštel Kambelovac",
    "mbr": 313467,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kaštel Lukšic",
    "mbr": 313475,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kaštel Novi",
    "mbr": 313483,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kaštel Stari",
    "mbr": 313491,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kaštel Sućurac",
    "mbr": 313505,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kaštel Štafilic",
    "mbr": 313513,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Kladnjice",
    "mbr": 313521,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Labin",
    "mbr": 313530,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Lecevica",
    "mbr": 313548,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Prgomet",
    "mbr": 313556,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Primorski Dolac",
    "mbr": 313564,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Radošic",
    "mbr": 313572,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Sitno",
    "mbr": 313599,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Trolokve",
    "mbr": 313602,
    "okn": "Kaštel Sućurac",
    "puk": "Split"
  },
  {
    "ko": "Brist",
    "mbr": 312908,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Gradac",
    "mbr": 312916,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Podaca",
    "mbr": 312975,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Bast-Baška Voda",
    "mbr": 316997,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Brela",
    "mbr": 317004,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Drašnice",
    "mbr": 317012,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Drvenik",
    "mbr": 317039,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Igrane",
    "mbr": 317047,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Kotišina",
    "mbr": 317055,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Makarska-Makar",
    "mbr": 317063,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Podgora",
    "mbr": 317071,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Tucepi",
    "mbr": 317080,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Veliko Brdo",
    "mbr": 317098,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Zaostrog",
    "mbr": 317101,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Živogošce",
    "mbr": 317110,
    "okn": "Makarska",
    "puk": "Split"
  },
  {
    "ko": "Blato na Cetini",
    "mbr": 319546,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Cisla",
    "mbr": 319554,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Donji Dolac",
    "mbr": 319562,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Dubrava",
    "mbr": 319589,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Duce",
    "mbr": 319597,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Gata",
    "mbr": 319619,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Gornji Dolac",
    "mbr": 319627,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Grabovac",
    "mbr": 319635,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Jesenice",
    "mbr": 319643,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Katuni",
    "mbr": 319651,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Kostanje",
    "mbr": 319660,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Kreševo",
    "mbr": 319678,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Kucice",
    "mbr": 319686,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Nova Sela",
    "mbr": 319694,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Omiš",
    "mbr": 319708,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Podgrade",
    "mbr": 319716,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Rogoznica",
    "mbr": 319724,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Slime",
    "mbr": 319732,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Srijane",
    "mbr": 319759,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Svinišce",
    "mbr": 319767,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Trnbusi",
    "mbr": 319775,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Tugare",
    "mbr": 319783,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Zakucac",
    "mbr": 319791,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Zvecanje",
    "mbr": 319805,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Žeževica",
    "mbr": 319813,
    "okn": "Omiš",
    "puk": "Split"
  },
  {
    "ko": "Bajagic",
    "mbr": 325473,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Bisko",
    "mbr": 325481,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Brnaze",
    "mbr": 325490,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Budimiri",
    "mbr": 325503,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Caporice",
    "mbr": 325511,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Dabar",
    "mbr": 325520,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Donji Bitelic",
    "mbr": 325538,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Ercegovci",
    "mbr": 325546,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Gala",
    "mbr": 325554,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Garjak",
    "mbr": 325562,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Glavice",
    "mbr": 325589,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Gljev",
    "mbr": 325597,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Gornji Bitelic",
    "mbr": 325619,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Grab",
    "mbr": 325627,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Hrvace",
    "mbr": 325635,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Ježevic",
    "mbr": 325643,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Kamensko",
    "mbr": 325651,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Karakašica",
    "mbr": 325660,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Koljane",
    "mbr": 325678,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Kosore",
    "mbr": 325686,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Košute",
    "mbr": 325694,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Kraj",
    "mbr": 325708,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Krušvar",
    "mbr": 325716,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Lucane",
    "mbr": 325724,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Maovice",
    "mbr": 325732,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Obrovac Sinjski",
    "mbr": 325759,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Otišic",
    "mbr": 325767,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Otok",
    "mbr": 325775,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Podosoje",
    "mbr": 325783,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Potravlje",
    "mbr": 325791,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Prisoje",
    "mbr": 325805,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Radošic",
    "mbr": 325813,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Ruda",
    "mbr": 325821,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Satric",
    "mbr": 325830,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Sicane",
    "mbr": 325848,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Sinj",
    "mbr": 325856,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Strizirep",
    "mbr": 325864,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Sušci",
    "mbr": 325872,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Tijarica",
    "mbr": 325899,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Turjaci",
    "mbr": 325902,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Udovicici",
    "mbr": 325929,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Ugljane",
    "mbr": 325937,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Vedrine",
    "mbr": 325945,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Velic",
    "mbr": 325953,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Vinalic",
    "mbr": 325961,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Vojnić Sinjski",
    "mbr": 325970,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Voštane",
    "mbr": 325988,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Vrlika",
    "mbr": 325996,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Vrpolje",
    "mbr": 326003,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Vucipolje",
    "mbr": 326011,
    "okn": "Sinj",
    "puk": "Split"
  },
  {
    "ko": "Blaca",
    "mbr": 329428,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Bracevic",
    "mbr": 329436,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Veliki Brocanac",
    "mbr": 329444,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Brštanovo",
    "mbr": 329452,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Crivac",
    "mbr": 329479,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Donje Ogorje",
    "mbr": 329487,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Donje Postinje",
    "mbr": 329495,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Donji Muc",
    "mbr": 329509,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Dugobabe",
    "mbr": 329517,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Dugopolje",
    "mbr": 329525,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Gizdavac",
    "mbr": 329533,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Gornje Ogorje",
    "mbr": 329541,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Gornje Postinje",
    "mbr": 329550,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Gornji Muc",
    "mbr": 329568,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Klis",
    "mbr": 329576,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Konjsko",
    "mbr": 329584,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Koprivno",
    "mbr": 329592,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Korušce",
    "mbr": 329606,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Kotlenice",
    "mbr": 329614,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Kucine",
    "mbr": 329622,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Milešine",
    "mbr": 329649,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Mravince",
    "mbr": 329657,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Neoric",
    "mbr": 329665,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Nisko",
    "mbr": 329673,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Pribude",
    "mbr": 329681,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Prugovo",
    "mbr": 329690,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Ramljane",
    "mbr": 329703,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Solin",
    "mbr": 329711,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Sutina",
    "mbr": 329720,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Vucevica",
    "mbr": 329738,
    "okn": "Solin",
    "puk": "Split"
  },
  {
    "ko": "Donje Selo",
    "mbr": 329746,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Gornje Selo",
    "mbr": 329754,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Grohote",
    "mbr": 329762,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Kamen",
    "mbr": 329789,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Sitno",
    "mbr": 329819,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Slatine",
    "mbr": 329827,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Split",
    "mbr": 329835,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Srednje Selo",
    "mbr": 329843,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Srinjine",
    "mbr": 329851,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Stobrec",
    "mbr": 329860,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Žrnovnica",
    "mbr": 329878,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Gornja Podstrana",
    "mbr": 336548,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Donja Podstrana",
    "mbr": 336556,
    "okn": "Split",
    "puk": "Split"
  },
  {
    "ko": "Bogomolje",
    "mbr": 311634,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Dol",
    "mbr": 311669,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Gdinj",
    "mbr": 311677,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Jelsa",
    "mbr": 311707,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Pitve",
    "mbr": 311715,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Stari Grad",
    "mbr": 311723,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Sucuraj",
    "mbr": 311731,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Svirce",
    "mbr": 311740,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Vrbanj",
    "mbr": 311758,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Vrboska",
    "mbr": 311766,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Vrisnik",
    "mbr": 311774,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Zastražišce",
    "mbr": 311782,
    "okn": "Stari Grad",
    "puk": "Split"
  },
  {
    "ko": "Bobovišca",
    "mbr": 301540,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Bol",
    "mbr": 301558,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Dol",
    "mbr": 301566,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Donji Humac",
    "mbr": 301574,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Dracevica",
    "mbr": 301582,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Gornji Humac",
    "mbr": 301604,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Milna",
    "mbr": 301612,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Mirca",
    "mbr": 301639,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Nerežišca",
    "mbr": 301647,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Novo Selo",
    "mbr": 301655,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Postira",
    "mbr": 301663,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Povlja",
    "mbr": 301671,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Pražnica",
    "mbr": 301680,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Pucišca",
    "mbr": 301698,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Selca",
    "mbr": 301701,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Splitska",
    "mbr": 301710,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Sumartin",
    "mbr": 301728,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Supetar",
    "mbr": 301736,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Sutivan",
    "mbr": 301744,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Škrip",
    "mbr": 301752,
    "okn": "Supetar",
    "puk": "Split"
  },
  {
    "ko": "Blizna",
    "mbr": 330663,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Bristivica",
    "mbr": 330671,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Seget Donji",
    "mbr": 330680,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Drvenik",
    "mbr": 330698,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Seget Gornji",
    "mbr": 330701,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Ljubitovica",
    "mbr": 330710,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Marina",
    "mbr": 330728,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Mitlo-Vinovac",
    "mbr": 330736,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Okrug",
    "mbr": 330744,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Prapatnica",
    "mbr": 330752,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Trogir",
    "mbr": 330779,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Vinišce",
    "mbr": 330787,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Žedno",
    "mbr": 330795,
    "okn": "Trogir",
    "puk": "Split"
  },
  {
    "ko": "Komiža",
    "mbr": 332755,
    "okn": "Vis",
    "puk": "Split"
  },
  {
    "ko": "Vis",
    "mbr": 332763,
    "okn": "Vis",
    "puk": "Split"
  },
  {
    "ko": "Dragljane",
    "mbr": 333891,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Dusina",
    "mbr": 333905,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Kozica",
    "mbr": 333913,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Orah",
    "mbr": 333921,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Poljica Kozicka",
    "mbr": 333930,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Rašcane",
    "mbr": 333948,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Ravca",
    "mbr": 333956,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Stilja",
    "mbr": 333964,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Vrgorac",
    "mbr": 333972,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Vrgorac I",
    "mbr": 333999,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Zavojane",
    "mbr": 334006,
    "okn": "Vrgorac",
    "puk": "Split"
  },
  {
    "ko": "Badanj",
    "mbr": 305499,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Baljci",
    "mbr": 305502,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Biocic",
    "mbr": 305529,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Bogatic Prominski",
    "mbr": 305537,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Bogatic Miljevacki",
    "mbr": 305545,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Brištani",
    "mbr": 305553,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Cavoglave",
    "mbr": 305561,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Citluk",
    "mbr": 305570,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Cvrljevo",
    "mbr": 305588,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Donje Utore",
    "mbr": 305596,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Donje Vinovo",
    "mbr": 305600,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Drinovci",
    "mbr": 305618,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Drniš",
    "mbr": 305626,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Gornje Utore",
    "mbr": 305634,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Gornje Vinovo",
    "mbr": 305642,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Gradac",
    "mbr": 305669,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Kadina Glavica",
    "mbr": 305677,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Kaocine",
    "mbr": 305685,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Karalic",
    "mbr": 305693,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Kljake",
    "mbr": 305707,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Kljuc",
    "mbr": 305715,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Kricke",
    "mbr": 305723,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Lukar",
    "mbr": 305731,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Ljubostinje",
    "mbr": 305740,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Matase",
    "mbr": 305758,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Miocic",
    "mbr": 305766,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Mirlovic Polje",
    "mbr": 305774,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Mirlovic Zagora",
    "mbr": 305782,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Mosec",
    "mbr": 305804,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Mratovo",
    "mbr": 305812,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Nevest",
    "mbr": 305839,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Oklaj",
    "mbr": 305847,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Ostrogašica",
    "mbr": 305855,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Otavice",
    "mbr": 305863,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Pakovo Selo",
    "mbr": 305871,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Parcic",
    "mbr": 305880,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Planjane",
    "mbr": 305898,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Podumci",
    "mbr": 305901,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Pokrovnik",
    "mbr": 305910,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Puljane",
    "mbr": 305928,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Radonic",
    "mbr": 305936,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Razvode",
    "mbr": 305944,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Ružic",
    "mbr": 305952,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Sedramic",
    "mbr": 305979,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Siveric",
    "mbr": 305987,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Suknovci",
    "mbr": 305995,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Širitovci",
    "mbr": 306002,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Štikovo",
    "mbr": 306029,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Tepljuh",
    "mbr": 306037,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Trbounje",
    "mbr": 306045,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Umljanovic",
    "mbr": 306053,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Unešic",
    "mbr": 306061,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Velušic",
    "mbr": 306070,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Visoka",
    "mbr": 306088,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Žitnic",
    "mbr": 306096,
    "okn": "Drniš",
    "puk": "Šibenik"
  },
  {
    "ko": "Parcic",
    "mbr": 300608,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Biovicino Selo",
    "mbr": 313769,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Biskupija",
    "mbr": 313777,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Cetina",
    "mbr": 313785,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Civljane",
    "mbr": 313793,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Ervenik Donji",
    "mbr": 313807,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Đevrske",
    "mbr": 313815,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Golubic",
    "mbr": 313823,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Ervenik Gornji",
    "mbr": 313831,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Ivoševci",
    "mbr": 313840,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Kijevo",
    "mbr": 313858,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Kistanje",
    "mbr": 313866,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Knin",
    "mbr": 313874,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Kninsko Polje",
    "mbr": 313882,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Kolašac",
    "mbr": 313904,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Modrino Selo",
    "mbr": 313912,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Mokro Polje",
    "mbr": 313939,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Nunic",
    "mbr": 313947,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Ocestovo",
    "mbr": 313955,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Orlic",
    "mbr": 313963,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Oton",
    "mbr": 313971,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Padene",
    "mbr": 313980,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Plavno",
    "mbr": 313998,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Polaca",
    "mbr": 314005,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Radljevac",
    "mbr": 314013,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Raducic",
    "mbr": 314021,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Ramljane",
    "mbr": 314030,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Ridane",
    "mbr": 314048,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Smrdelje",
    "mbr": 314056,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Strmica",
    "mbr": 314064,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Turic",
    "mbr": 314072,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Uzdolje",
    "mbr": 314099,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Vrbnik",
    "mbr": 314102,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Zvjerinac",
    "mbr": 314129,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Žagrovic",
    "mbr": 314137,
    "okn": "Knin",
    "puk": "Šibenik"
  },
  {
    "ko": "Ostrovica",
    "mbr": 300594,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Bilice",
    "mbr": 329886,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Boraja",
    "mbr": 329894,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Bratiškovci",
    "mbr": 329908,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Bribir",
    "mbr": 329916,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Crnica",
    "mbr": 329924,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Cista",
    "mbr": 329932,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Danilo Biranj",
    "mbr": 329959,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Danilo Kraljice",
    "mbr": 329967,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Donje Polje",
    "mbr": 329975,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Dubrava",
    "mbr": 329983,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Dubravice",
    "mbr": 329991,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Gorica",
    "mbr": 330019,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Grebaštica",
    "mbr": 330027,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Jadrtovac",
    "mbr": 330035,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Jezera",
    "mbr": 330043,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Konjevrate",
    "mbr": 330051,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Kornati",
    "mbr": 330060,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Krapanj",
    "mbr": 330078,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Lepenica",
    "mbr": 330086,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Lozovac",
    "mbr": 330094,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Mandalina",
    "mbr": 330108,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Martinska",
    "mbr": 330116,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Murter Betina",
    "mbr": 330124,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Piramatovci",
    "mbr": 330132,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Pirovac",
    "mbr": 330159,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Primošten",
    "mbr": 330167,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Prvic",
    "mbr": 330175,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Racice",
    "mbr": 330183,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Rogoznica",
    "mbr": 330191,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Rupe",
    "mbr": 330205,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Sevid",
    "mbr": 330213,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Sitno Donje",
    "mbr": 330221,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Skradin",
    "mbr": 330230,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Slivno",
    "mbr": 330248,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Srima",
    "mbr": 330256,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Šibenik",
    "mbr": 330264,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Tisno",
    "mbr": 330272,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Tribunj",
    "mbr": 330299,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Vacani",
    "mbr": 330302,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Velika Glava",
    "mbr": 330329,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Vodice",
    "mbr": 330337,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Vrpolje",
    "mbr": 330345,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Zaton-Raslina",
    "mbr": 330353,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Zlarin",
    "mbr": 330361,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Žirje",
    "mbr": 330370,
    "okn": "Šibenik",
    "puk": "Šibenik"
  },
  {
    "ko": "Bednja",
    "mbr": 312061,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Cerje Tužno",
    "mbr": 312070,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Calinec",
    "mbr": 312088,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Donja Višnjica",
    "mbr": 312096,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Donja Voca",
    "mbr": 312100,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Donje Ladanje",
    "mbr": 312118,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Druškovec",
    "mbr": 312126,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Gornja Višnjica",
    "mbr": 312134,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Gornja Voca",
    "mbr": 312142,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Ivanec",
    "mbr": 312169,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Jerovec",
    "mbr": 312177,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Kamenica",
    "mbr": 312185,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Kaniža",
    "mbr": 312193,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Klenovnik",
    "mbr": 312207,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Lepoglava",
    "mbr": 312215,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Ocura",
    "mbr": 312223,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Maruševec",
    "mbr": 312231,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Radovan",
    "mbr": 312240,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Rinkovec",
    "mbr": 312258,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Šaša",
    "mbr": 312266,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Trakošcan",
    "mbr": 312274,
    "okn": "Ivanec",
    "puk": "Varaždin"
  },
  {
    "ko": "Bolfan",
    "mbr": 316725,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Cukovec",
    "mbr": 316733,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Martijanec",
    "mbr": 316741,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Sveti Đurd",
    "mbr": 316750,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Gornji Martijanec",
    "mbr": 316768,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Hrastovljan",
    "mbr": 316776,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Hrastovsko",
    "mbr": 316784,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Hrženica",
    "mbr": 316792,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Križovljan",
    "mbr": 316806,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Ludbreg",
    "mbr": 316814,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Komarnica Ludbreška",
    "mbr": 316822,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Sesvete Ludbreške",
    "mbr": 316849,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Karlovec Ludbreški",
    "mbr": 316857,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Sigetec Ludbreški",
    "mbr": 316865,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Lunjkovec",
    "mbr": 316873,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Mali Bukovec",
    "mbr": 316881,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Sveti Petar",
    "mbr": 316890,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Kapela Podravska",
    "mbr": 316903,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Novo Selo Podravsko",
    "mbr": 316911,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Selnik",
    "mbr": 316920,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Slanje",
    "mbr": 316938,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Slokovec",
    "mbr": 316946,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Struga",
    "mbr": 316954,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Veliki Bukovec",
    "mbr": 316962,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Vinogradi Ludbreški",
    "mbr": 316989,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Segovina",
    "mbr": 337633,
    "okn": "Ludbreg",
    "puk": "Varaždin"
  },
  {
    "ko": "Sudovec",
    "mbr": 315745,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Bela",
    "mbr": 318426,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Bisag",
    "mbr": 318434,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Breznica",
    "mbr": 318442,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Butkovec",
    "mbr": 318469,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Canjevo",
    "mbr": 318477,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Donje Makojišce",
    "mbr": 318485,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Drenovec",
    "mbr": 318507,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Đurinovec",
    "mbr": 318515,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Grana",
    "mbr": 318523,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Hrastovec Toplicki",
    "mbr": 318531,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Kapela Kalnicka",
    "mbr": 318540,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Kamena Gorica",
    "mbr": 318558,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Kljuc",
    "mbr": 318566,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Leskovec Toplicki",
    "mbr": 318574,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Ljubešcica",
    "mbr": 318582,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Novi Marof",
    "mbr": 318604,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Podevcevo",
    "mbr": 318612,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Poljana",
    "mbr": 318639,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Remetinec",
    "mbr": 318647,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Svibovec",
    "mbr": 318655,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Šcepanje",
    "mbr": 318663,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Tuhovec",
    "mbr": 318671,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Varaždinske Toplice",
    "mbr": 318680,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Vinicno",
    "mbr": 318698,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Visoko",
    "mbr": 318701,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Draškovic",
    "mbr": 336424,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Ljubelj Kalnicki",
    "mbr": 337641,
    "okn": "Novi Marof",
    "puk": "Varaždin"
  },
  {
    "ko": "Babinec",
    "mbr": 331015,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Trnovec",
    "mbr": 331023,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Beletinec",
    "mbr": 331031,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Beretinec",
    "mbr": 331040,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Crešnjevo",
    "mbr": 331066,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Družbinec",
    "mbr": 331074,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Sveti Ilija",
    "mbr": 331082,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Jakopovec",
    "mbr": 331104,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Jalkovec",
    "mbr": 331112,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Jalžabet",
    "mbr": 331139,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Kaštelanec",
    "mbr": 331147,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Kelemen",
    "mbr": 331155,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Kneginec",
    "mbr": 331163,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Dubrava Križovljanska",
    "mbr": 331171,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Gornje Ladanje",
    "mbr": 331198,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Majerje",
    "mbr": 331201,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Marcan",
    "mbr": 331210,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Natkrižovljan",
    "mbr": 331228,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Nedeljanec",
    "mbr": 331236,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Novakovec",
    "mbr": 331244,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Petrijanec",
    "mbr": 331252,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Nova Ves Petrijanecka",
    "mbr": 331279,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Radovec",
    "mbr": 331287,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Sracinec",
    "mbr": 331295,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Šemovec",
    "mbr": 331309,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Tužno",
    "mbr": 331317,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Varaždin",
    "mbr": 331325,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Varaždin Breg",
    "mbr": 331333,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Vidovec",
    "mbr": 331341,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Vinica",
    "mbr": 331350,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Vinica Breg",
    "mbr": 331368,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Vratno",
    "mbr": 331376,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Zamlaca",
    "mbr": 331384,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Žabnik",
    "mbr": 331392,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Biškupec",
    "mbr": 336645,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Biškupec - II",
    "mbr": 336653,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Donji Kucan",
    "mbr": 337188,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Gornji Kucan",
    "mbr": 337196,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Kucan Marof",
    "mbr": 337200,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Zbelava",
    "mbr": 337218,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Gojanec",
    "mbr": 337544,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Poljana Biškupecka",
    "mbr": 337552,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Crnec Biškupecki",
    "mbr": 337579,
    "okn": "Varaždin",
    "puk": "Varaždin"
  },
  {
    "ko": "Bankovci",
    "mbr": 320200,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Slavonske Bare",
    "mbr": 320218,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Brezovljani",
    "mbr": 320226,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Crnac",
    "mbr": 320234,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Cacinci",
    "mbr": 320242,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Dolci",
    "mbr": 320269,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Donja Pištana",
    "mbr": 320277,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Donje Predrijevo",
    "mbr": 320285,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Duzluk",
    "mbr": 320293,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornja Pištana",
    "mbr": 320307,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Kokocak",
    "mbr": 320315,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Krajna",
    "mbr": 320323,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Kraskovic",
    "mbr": 320331,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Kutovi",
    "mbr": 320340,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Manastir Orahovica",
    "mbr": 320358,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Nova Jošava",
    "mbr": 320366,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Obradovci",
    "mbr": 320374,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Orahovica",
    "mbr": 320382,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Pušina",
    "mbr": 320404,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Slatinski Drenovac",
    "mbr": 320412,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Stara Jošava",
    "mbr": 320439,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Suha Mlaka",
    "mbr": 320447,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Šumede",
    "mbr": 320455,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Zdenci",
    "mbr": 320463,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Paušinci",
    "mbr": 337013,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Duga Meda",
    "mbr": 337897,
    "okn": "Orahovica",
    "puk": "Virovitica"
  },
  {
    "ko": "Dinjevac",
    "mbr": 309273,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Grabrovnica",
    "mbr": 309311,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Kladare",
    "mbr": 309346,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Mala Crešnjevica",
    "mbr": 309397,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Otrovanec",
    "mbr": 309451,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Pitomača I",
    "mbr": 309460,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Pitomača II",
    "mbr": 309478,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Sedlarica",
    "mbr": 309508,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Stari Gradac",
    "mbr": 309532,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Turnašica",
    "mbr": 309575,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Velika Crešnjevica",
    "mbr": 309583,
    "okn": "Pitomača",
    "puk": "Virovitica"
  },
  {
    "ko": "Bakic",
    "mbr": 323098,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Bistrica",
    "mbr": 323101,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Bokane",
    "mbr": 323110,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Cadavica",
    "mbr": 323128,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Ceralije",
    "mbr": 323136,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Dobrovic",
    "mbr": 323144,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Donja Bukovica",
    "mbr": 323152,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Donje Bazije",
    "mbr": 323179,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Donje Kusonje",
    "mbr": 323187,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Donji Meljani",
    "mbr": 323195,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Đuricic",
    "mbr": 323209,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornja Bukovica",
    "mbr": 323217,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornje Kusonje",
    "mbr": 323225,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornje Predrijevo",
    "mbr": 323233,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornje Viljevo",
    "mbr": 323241,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornji Meljani",
    "mbr": 323250,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornji Miholjac",
    "mbr": 323268,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Hum Vocinski",
    "mbr": 323276,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Hum Varoš",
    "mbr": 323284,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Ivanbrijeg",
    "mbr": 323292,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Kapinci",
    "mbr": 323306,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Kometnik",
    "mbr": 323314,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Kozice",
    "mbr": 323322,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Kuzma",
    "mbr": 323349,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Lisicine",
    "mbr": 323357,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Lukavac",
    "mbr": 323365,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Macute",
    "mbr": 323373,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Medinci",
    "mbr": 323381,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Mikleuš",
    "mbr": 323390,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Miljevci",
    "mbr": 323403,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Nova Bukovica",
    "mbr": 323411,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Novaki",
    "mbr": 323420,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Noskovci",
    "mbr": 323438,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Podravska Slatina",
    "mbr": 323446,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Popovac",
    "mbr": 323454,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Radosavci",
    "mbr": 323462,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Rijenci",
    "mbr": 323489,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Sekulinci",
    "mbr": 323497,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Sladojevci",
    "mbr": 323519,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Slatinski Lipovac",
    "mbr": 323527,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Smude",
    "mbr": 323535,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Sopje",
    "mbr": 323543,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Starin",
    "mbr": 323551,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Vaška",
    "mbr": 323560,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Vocin",
    "mbr": 323578,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Vraneševci",
    "mbr": 323586,
    "okn": "Slatina",
    "puk": "Virovitica"
  },
  {
    "ko": "Bacevac",
    "mbr": 332402,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Borova",
    "mbr": 332429,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Budanica",
    "mbr": 332437,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Budrovac Lukacki",
    "mbr": 332445,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Bušetina",
    "mbr": 332453,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Cabuna",
    "mbr": 332461,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Detkovac",
    "mbr": 332470,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Dugo Selo Lukacko",
    "mbr": 332488,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Gacište",
    "mbr": 332496,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Gornje Bazje",
    "mbr": 332500,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Gradina",
    "mbr": 332518,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Brezovica",
    "mbr": 332526,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Gvozdanska",
    "mbr": 332534,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Jasenaš",
    "mbr": 332542,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Kapan",
    "mbr": 332569,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Levinovac",
    "mbr": 332577,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Lozan",
    "mbr": 332585,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Lukac",
    "mbr": 332593,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Naudovac",
    "mbr": 332607,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Orešac",
    "mbr": 332615,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Pcelic",
    "mbr": 332623,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Pivnica",
    "mbr": 332631,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Rezovac",
    "mbr": 332640,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Rogovac",
    "mbr": 332658,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Rušani",
    "mbr": 332666,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Suhopolje",
    "mbr": 332674,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Špišic Bukovica",
    "mbr": 332682,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Turanovac",
    "mbr": 332704,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Virovitica",
    "mbr": 332712,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Virovitica-grad",
    "mbr": 332739,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Vukosavljevica",
    "mbr": 332747,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Požari",
    "mbr": 337625,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Antunovac",
    "mbr": 337838,
    "okn": "Virovitica",
    "puk": "Virovitica"
  },
  {
    "ko": "Ilaca",
    "mbr": 332038,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Bapska",
    "mbr": 334014,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Ilok",
    "mbr": 334111,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Lovas",
    "mbr": 334146,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Mohovo",
    "mbr": 334189,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Opatovac",
    "mbr": 334219,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Šarengrad",
    "mbr": 334260,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Tovarnik",
    "mbr": 334286,
    "okn": "Ilok",
    "puk": "Vukovar"
  },
  {
    "ko": "Andrijaševci",
    "mbr": 331961,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Antin",
    "mbr": 331970,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Apševci",
    "mbr": 331988,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Ceric",
    "mbr": 331996,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Donje Novo Selo",
    "mbr": 332003,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Đeletovci",
    "mbr": 332011,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Gaboš",
    "mbr": 332020,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Ivankovo",
    "mbr": 332046,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Jarmina",
    "mbr": 332054,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Karadžicevo",
    "mbr": 332062,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Komletinci",
    "mbr": 332089,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Korod",
    "mbr": 332097,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Lipovac",
    "mbr": 332119,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Marinci",
    "mbr": 332127,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Markušica",
    "mbr": 332135,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Mirkovci",
    "mbr": 332143,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Nijemci",
    "mbr": 332151,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Novi Jankovci",
    "mbr": 332160,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Novi Mikanovci",
    "mbr": 332178,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Nuštar",
    "mbr": 332186,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Orolik",
    "mbr": 332194,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Ostrovo",
    "mbr": 332208,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Otok",
    "mbr": 332216,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Podgrade",
    "mbr": 332224,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Privlaka",
    "mbr": 332232,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Prkovci",
    "mbr": 332259,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Retkovci",
    "mbr": 332267,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Rokovci",
    "mbr": 332275,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Slakovci",
    "mbr": 332283,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Srijemske Laze",
    "mbr": 332291,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Stari Jankovci",
    "mbr": 332305,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Stari Mikanovci",
    "mbr": 332313,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Banovci",
    "mbr": 332321,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Tordinci",
    "mbr": 332330,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Vinkovacki Banovci",
    "mbr": 332348,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Vinkovacko Novo Selo",
    "mbr": 332356,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Vinkovci I",
    "mbr": 332364,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Vinkovci II",
    "mbr": 332372,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Vodinci",
    "mbr": 332399,
    "okn": "Vinkovci",
    "puk": "Vukovar"
  },
  {
    "ko": "Berak",
    "mbr": 334022,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Bobota",
    "mbr": 334049,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Bogdanovci",
    "mbr": 334057,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Borovo",
    "mbr": 334065,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Borovo Naselje",
    "mbr": 334073,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Bršadin",
    "mbr": 334081,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Cakovci",
    "mbr": 334090,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Grabovo",
    "mbr": 334103,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Klisa",
    "mbr": 334120,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Lipovaca",
    "mbr": 334138,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Ludvinci",
    "mbr": 334154,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Mikluševci",
    "mbr": 334162,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Negoslavci",
    "mbr": 334197,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Pacetin",
    "mbr": 334227,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Petrovci",
    "mbr": 334235,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Sotin",
    "mbr": 334243,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Svinjarevci",
    "mbr": 334251,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Tompojevci",
    "mbr": 334278,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Trpinja",
    "mbr": 334294,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Vera",
    "mbr": 334308,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Vukovar",
    "mbr": 334316,
    "okn": "Vukovar",
    "puk": "Vukovar"
  },
  {
    "ko": "Babina Greda",
    "mbr": 336220,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Bošnjaci",
    "mbr": 336238,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Cerna",
    "mbr": 336246,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Drenovci",
    "mbr": 336254,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Đurici",
    "mbr": 336262,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Gradište",
    "mbr": 336289,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Gunja",
    "mbr": 336297,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Posavski Podgajci",
    "mbr": 336319,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Racinovci",
    "mbr": 336327,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Rajevo Selo",
    "mbr": 336335,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Soljani",
    "mbr": 336343,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Strošinci",
    "mbr": 336351,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Šiškovci",
    "mbr": 336360,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Štitar",
    "mbr": 336378,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Vrbanja",
    "mbr": 336386,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Županja",
    "mbr": 336394,
    "okn": "Županja",
    "puk": "Vukovar"
  },
  {
    "ko": "Banjevci",
    "mbr": 300365,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Benkovac",
    "mbr": 300373,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Bjelina",
    "mbr": 300381,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Brgud",
    "mbr": 300390,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Bruška",
    "mbr": 300403,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Bukovic",
    "mbr": 300411,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Ceranje",
    "mbr": 300420,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Dobropoljci",
    "mbr": 300438,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Donja Jagodnja",
    "mbr": 300446,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Donje Biljane",
    "mbr": 300454,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Gornja Jagodnja",
    "mbr": 300462,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Gornje Biljane",
    "mbr": 300489,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Islam Grcki",
    "mbr": 300497,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Kolarina",
    "mbr": 300527,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Korlat",
    "mbr": 300535,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Kula Atlagic",
    "mbr": 300543,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Lepuri",
    "mbr": 300551,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Lisicic",
    "mbr": 300560,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Lišane Ostrovicke",
    "mbr": 300578,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Nadin",
    "mbr": 300586,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Perušic",
    "mbr": 300616,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Polaca",
    "mbr": 300624,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Popovici",
    "mbr": 300632,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Pristeg",
    "mbr": 300659,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Radašinovci",
    "mbr": 300667,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Raštevic",
    "mbr": 300675,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Smilcic",
    "mbr": 300683,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Stankovci",
    "mbr": 300691,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Šopot",
    "mbr": 300705,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Tinj",
    "mbr": 300713,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Velim",
    "mbr": 300721,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Vukšic",
    "mbr": 300730,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Donji Karin",
    "mbr": 337323,
    "okn": "Benkovac",
    "puk": "Zadar"
  },
  {
    "ko": "Banj",
    "mbr": 300748,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Biograd na Moru",
    "mbr": 300756,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Dobropoljana",
    "mbr": 300764,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Sveti Filip i Jakov",
    "mbr": 300772,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Mrljane",
    "mbr": 300799,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Nevidane",
    "mbr": 300802,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Pakoštane",
    "mbr": 300829,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Pašman",
    "mbr": 300837,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Raštane",
    "mbr": 300845,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Tkon",
    "mbr": 300853,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Turanj",
    "mbr": 300861,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Vrana",
    "mbr": 300870,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Vrgada",
    "mbr": 300888,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Ždrelac",
    "mbr": 300896,
    "okn": "Biograd na Moru",
    "puk": "Zadar"
  },
  {
    "ko": "Begluci",
    "mbr": 304972,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Brotnja",
    "mbr": 305006,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Dabašnica",
    "mbr": 305022,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Drenovac Osredacki",
    "mbr": 305081,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Dugopolje",
    "mbr": 305090,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Kaldrma",
    "mbr": 305111,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Kupirovo",
    "mbr": 305146,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Neteka",
    "mbr": 305189,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Osredci",
    "mbr": 305219,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Srb",
    "mbr": 305235,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Suvaja",
    "mbr": 305243,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Tiškovac Licki",
    "mbr": 305260,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Zaklopac",
    "mbr": 305278,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Bruvno",
    "mbr": 311073,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Deringaj",
    "mbr": 311090,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Duboki Dol",
    "mbr": 311103,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Glogovo",
    "mbr": 311111,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Grab",
    "mbr": 311120,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Gračac",
    "mbr": 311138,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Kijani",
    "mbr": 311146,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Kom",
    "mbr": 311162,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Mazin",
    "mbr": 311197,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Omsica",
    "mbr": 311219,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Palanka",
    "mbr": 311227,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Pribudic",
    "mbr": 311243,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Prljevo",
    "mbr": 311251,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Rudopolje Bruvanjsko",
    "mbr": 311278,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Štikada",
    "mbr": 311308,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Tomingaj",
    "mbr": 311316,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Velika Popina",
    "mbr": 311324,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Vrelo",
    "mbr": 311359,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Zrmanja",
    "mbr": 311367,
    "okn": "Gračac",
    "puk": "Zadar"
  },
  {
    "ko": "Karin",
    "mbr": 300519,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Bilišane",
    "mbr": 319040,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Golubic",
    "mbr": 319058,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Jasenice",
    "mbr": 319066,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Krupa",
    "mbr": 319074,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Kruševo",
    "mbr": 319082,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Medvida",
    "mbr": 319104,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Muškovci",
    "mbr": 319112,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Obrovac",
    "mbr": 319139,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Zaton Obrovacki",
    "mbr": 319147,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Zelengrad",
    "mbr": 319155,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Žegar",
    "mbr": 319163,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Gornji Karin",
    "mbr": 337331,
    "okn": "Obrovac",
    "puk": "Zadar"
  },
  {
    "ko": "Dinjiška",
    "mbr": 321508,
    "okn": "Pag",
    "puk": "Zadar"
  },
  {
    "ko": "Kolan",
    "mbr": 321516,
    "okn": "Pag",
    "puk": "Zadar"
  },
  {
    "ko": "Pag",
    "mbr": 321532,
    "okn": "Pag",
    "puk": "Zadar"
  },
  {
    "ko": "Povljana",
    "mbr": 321559,
    "okn": "Pag",
    "puk": "Zadar"
  },
  {
    "ko": "Bibinje",
    "mbr": 334570,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Bokanjac",
    "mbr": 334588,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Božava",
    "mbr": 334596,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Brbinj",
    "mbr": 334600,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Briševo",
    "mbr": 334618,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Crno",
    "mbr": 334626,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Diklo",
    "mbr": 334634,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Dracevac Ninski",
    "mbr": 334642,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Dragove",
    "mbr": 334669,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Galovac",
    "mbr": 334677,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Gorica",
    "mbr": 334685,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Islam Latinski",
    "mbr": 334693,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Ist",
    "mbr": 334707,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Kali",
    "mbr": 334715,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Kukljica",
    "mbr": 334723,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Luka",
    "mbr": 334731,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Lukoran",
    "mbr": 334740,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Mali Iž",
    "mbr": 334758,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Molat",
    "mbr": 334766,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Murvica",
    "mbr": 334774,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Novigrad",
    "mbr": 334804,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Olib",
    "mbr": 334812,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Petrcane",
    "mbr": 334839,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Policnik",
    "mbr": 334847,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Poljana",
    "mbr": 334855,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Poljica",
    "mbr": 334863,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Posedarje",
    "mbr": 334871,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Preko",
    "mbr": 334880,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Premuda",
    "mbr": 334898,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Pridraga",
    "mbr": 334901,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Privlaka",
    "mbr": 334910,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Radovin",
    "mbr": 334928,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Rava",
    "mbr": 334936,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Ražanac",
    "mbr": 334944,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Sali",
    "mbr": 334952,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Savar",
    "mbr": 334979,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Seline",
    "mbr": 334987,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Sestrunj",
    "mbr": 334995,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Silba",
    "mbr": 335002,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Slivnica",
    "mbr": 335029,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Smokovic",
    "mbr": 335037,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Soline",
    "mbr": 335045,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Starigrad",
    "mbr": 335053,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Suhovare",
    "mbr": 335061,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Sukošan",
    "mbr": 335070,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Sutomišcica",
    "mbr": 335088,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Škabrnja",
    "mbr": 335096,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Tribanj",
    "mbr": 335100,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Ugljan",
    "mbr": 335118,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Veli Iž",
    "mbr": 335126,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Veli Rat",
    "mbr": 335134,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Vinjerac",
    "mbr": 335142,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Vir",
    "mbr": 335169,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Visocane",
    "mbr": 335177,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Vrsi",
    "mbr": 335185,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Zadar",
    "mbr": 335193,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Zapuntel",
    "mbr": 335207,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Zemunik",
    "mbr": 335215,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Zverinac",
    "mbr": 335223,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Žman",
    "mbr": 335231,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Nin-Zaton",
    "mbr": 336726,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Ninski Stanovi",
    "mbr": 336734,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Grbe",
    "mbr": 336742,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Poljica Brig",
    "mbr": 336858,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Žerava",
    "mbr": 336866,
    "okn": "Zadar",
    "puk": "Zadar"
  },
  {
    "ko": "Andrilovec",
    "mbr": 308013,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Brckovljani",
    "mbr": 308021,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Dugo Selo I",
    "mbr": 308030,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Dugo Selo II",
    "mbr": 308048,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Hrebinec",
    "mbr": 308056,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Hrušcica",
    "mbr": 308064,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Ježevo",
    "mbr": 308072,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Leprovica",
    "mbr": 308099,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Lupoglav",
    "mbr": 308102,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Novaki Oborovski",
    "mbr": 308129,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Oborovo",
    "mbr": 308137,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Okunšcak",
    "mbr": 308145,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Ostrna",
    "mbr": 308153,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Precec",
    "mbr": 308161,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Prozorje",
    "mbr": 308170,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Rugvica",
    "mbr": 308188,
    "okn": "Dugo Selo",
    "puk": "Zagreb"
  },
  {
    "ko": "Bešlinec",
    "mbr": 312282,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Breška Greda",
    "mbr": 312304,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Caginec",
    "mbr": 312312,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Hrastilnica",
    "mbr": 312339,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Ivanić-Grad",
    "mbr": 312347,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Kloštar Ivanic",
    "mbr": 312355,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Križ",
    "mbr": 312363,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Lepšic",
    "mbr": 312371,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Lijevi Dubrovcak",
    "mbr": 312380,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Nova Marca",
    "mbr": 312398,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Novoselec",
    "mbr": 312401,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Obreška",
    "mbr": 312410,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Okešinec",
    "mbr": 312428,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Opatinec",
    "mbr": 312436,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Posavski Bregi",
    "mbr": 312444,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Precno",
    "mbr": 312452,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Šarampov",
    "mbr": 312479,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Širinec",
    "mbr": 312487,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Šumecani",
    "mbr": 312495,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Šušnjari",
    "mbr": 312509,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Topolje",
    "mbr": 312517,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Trebovec",
    "mbr": 312525,
    "okn": "Ivanić-Grad",
    "puk": "Zagreb"
  },
  {
    "ko": "Brlenic",
    "mbr": 312533,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Cvetkovic",
    "mbr": 312541,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Ceglje",
    "mbr": 312550,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Desinec",
    "mbr": 312568,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Domagovic",
    "mbr": 312576,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Donja Kupcina",
    "mbr": 312584,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Gornja Kupcina",
    "mbr": 312592,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Jastrebarsko",
    "mbr": 312606,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Kalje",
    "mbr": 312614,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Kostanjevac",
    "mbr": 312649,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Kupinec",
    "mbr": 312665,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Lijevo Sredicko",
    "mbr": 312673,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Lucelnica",
    "mbr": 312681,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Mahovljic",
    "mbr": 312690,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Mirkopolje",
    "mbr": 312703,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Mrzlo Polje Žumberacko",
    "mbr": 312711,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Pecno",
    "mbr": 312720,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Petrovina",
    "mbr": 312738,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Pisarovina",
    "mbr": 312746,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Plešivica",
    "mbr": 312754,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Plešivicka Reka",
    "mbr": 312762,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Prekrižje",
    "mbr": 312789,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Pribic",
    "mbr": 312797,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Slavetic",
    "mbr": 312819,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Sošice",
    "mbr": 312827,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Sveta Jana",
    "mbr": 312835,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Velika Jamnicka",
    "mbr": 312843,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Volavje",
    "mbr": 312851,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Zdencina",
    "mbr": 312860,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Željezno Žumberacko",
    "mbr": 312878,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Žumberak",
    "mbr": 312886,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Krašic I",
    "mbr": 336599,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Krašic II",
    "mbr": 336602,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Klinca Sela",
    "mbr": 336629,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Okic",
    "mbr": 336637,
    "okn": "Jastrebarsko",
    "puk": "Zagreb"
  },
  {
    "ko": "Budinjak",
    "mbr": 324965,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Cerje Samoborsko",
    "mbr": 324973,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Domaslovec",
    "mbr": 324981,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Drežnik Podokicki",
    "mbr": 324990,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Galgovo",
    "mbr": 325007,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Grabar",
    "mbr": 325015,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Kerestinec",
    "mbr": 325023,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Klokocevec Samoborski",
    "mbr": 325031,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Konšcica",
    "mbr": 325040,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Molvice",
    "mbr": 325058,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Sveta Nedelja",
    "mbr": 325066,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Noršic Selo",
    "mbr": 325074,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Otruševec",
    "mbr": 325082,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Podvrh",
    "mbr": 325104,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Poklek",
    "mbr": 325112,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Rakitje",
    "mbr": 325139,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Rakov Potok",
    "mbr": 325147,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Rakovica",
    "mbr": 325155,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Rude",
    "mbr": 325163,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Samobor",
    "mbr": 325171,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Slani Dol",
    "mbr": 325180,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Strmec Samoborski",
    "mbr": 325198,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Šipacki Breg",
    "mbr": 325201,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Vrbovec",
    "mbr": 325210,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Stupnik",
    "mbr": 335517,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Klokocevec",
    "mbr": 337030,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Otok Samoborski",
    "mbr": 337048,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Bregana",
    "mbr": 337056,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Jazbina-Lug",
    "mbr": 337064,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Vrhovcak",
    "mbr": 337072,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Perivoj",
    "mbr": 337099,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Kalinovica",
    "mbr": 337714,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Mala Gorica",
    "mbr": 337722,
    "okn": "Samobor",
    "puk": "Zagreb"
  },
  {
    "ko": "Salnik",
    "mbr": 333204,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Bedenica",
    "mbr": 335789,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Blaškovec",
    "mbr": 335797,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Blaževdol",
    "mbr": 335819,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Helena",
    "mbr": 335827,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Hrastje",
    "mbr": 335835,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Hrnjanec",
    "mbr": 335843,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Komin",
    "mbr": 335851,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Laktec",
    "mbr": 335860,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Novo Mjesto",
    "mbr": 335878,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Obrež",
    "mbr": 335886,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Orešje",
    "mbr": 335894,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Paukovec",
    "mbr": 335908,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Psarjevo",
    "mbr": 335916,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Radoišce",
    "mbr": 335924,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Tomaševec",
    "mbr": 335932,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Zelina",
    "mbr": 335959,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Žitomir",
    "mbr": 336467,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Gornji Vinkovec",
    "mbr": 336475,
    "okn": "Sveti Ivan Zelina",
    "puk": "Zagreb"
  },
  {
    "ko": "Auguštanovec",
    "mbr": 331406,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Bukevje",
    "mbr": 331414,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Bukovcak",
    "mbr": 331422,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Buševec",
    "mbr": 331449,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Cerovski Vrh",
    "mbr": 331457,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Cvetkovic Brdo",
    "mbr": 331465,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Lazina Cicka",
    "mbr": 331473,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Crnkovec",
    "mbr": 331481,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Donja Lomnica",
    "mbr": 331490,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Donje Podotocje",
    "mbr": 331503,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Drnek",
    "mbr": 331511,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Dubranec",
    "mbr": 331520,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Gornje Podotocje",
    "mbr": 331538,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Gornji Hruševec",
    "mbr": 331546,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Gradici",
    "mbr": 331554,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Gustelnica",
    "mbr": 331562,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Hotnja",
    "mbr": 331589,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Kosnica",
    "mbr": 331597,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Kozjaca",
    "mbr": 331619,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Kravarsko",
    "mbr": 331627,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Kuce",
    "mbr": 331635,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Kurilovec",
    "mbr": 331643,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Lekneno",
    "mbr": 331651,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Lijevi Štefanki",
    "mbr": 331660,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Lukavec",
    "mbr": 331678,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Lukinic Brdo",
    "mbr": 331686,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Micevec",
    "mbr": 331694,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Mraclin",
    "mbr": 331708,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Novo Brdo",
    "mbr": 331716,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Novo Cice",
    "mbr": 331724,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Obrezina",
    "mbr": 331732,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Pleso",
    "mbr": 331759,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Podvornica",
    "mbr": 331767,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Pokupsko",
    "mbr": 331775,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Poljana Cicka",
    "mbr": 331783,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Ribnica",
    "mbr": 331791,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Roženica",
    "mbr": 331805,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Ruca",
    "mbr": 331813,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Staro Cice",
    "mbr": 331821,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Strmec Bukevski",
    "mbr": 331830,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Šcitarjevo",
    "mbr": 331848,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Šiljakovina",
    "mbr": 331856,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Turopoljski Lug",
    "mbr": 331864,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Veleševec",
    "mbr": 331872,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Velika Buna",
    "mbr": 331899,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Velika Gorica",
    "mbr": 331902,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Velika Mlaka",
    "mbr": 331929,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Vukomeric",
    "mbr": 331937,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Vukovina",
    "mbr": 331945,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Zguric Brdo",
    "mbr": 331953,
    "okn": "Velika Gorica",
    "puk": "Zagreb"
  },
  {
    "ko": "Badinec",
    "mbr": 332917,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Bolc",
    "mbr": 332925,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Brezine",
    "mbr": 332933,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Brezje",
    "mbr": 332941,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Cugovec",
    "mbr": 332950,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Dijaneš",
    "mbr": 332968,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Dubrava",
    "mbr": 332976,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Novaki",
    "mbr": 332984,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Farkaševac",
    "mbr": 332992,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Fuka",
    "mbr": 333000,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Gostovic",
    "mbr": 333018,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Gradec",
    "mbr": 333026,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Habjanovac",
    "mbr": 333034,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Haganj",
    "mbr": 333042,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Hruškovica",
    "mbr": 333069,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Hudovo",
    "mbr": 333077,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Kabal",
    "mbr": 333085,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Lonjica",
    "mbr": 333093,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Marinkovac",
    "mbr": 333107,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Mlaka",
    "mbr": 333115,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Negovec",
    "mbr": 333123,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Paruževac",
    "mbr": 333131,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Podjales",
    "mbr": 333140,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Preseka",
    "mbr": 333166,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Rakovec",
    "mbr": 333174,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Velika",
    "mbr": 333182,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Samoborec",
    "mbr": 333212,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Nova Kapela",
    "mbr": 333239,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Stari Glog",
    "mbr": 333247,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Tkalec",
    "mbr": 333255,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Tucenik",
    "mbr": 333263,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Lovrecka Varoš",
    "mbr": 333271,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Vrbovec",
    "mbr": 333280,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Pirakovec",
    "mbr": 333298,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Vukšinac",
    "mbr": 333301,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Zabrde",
    "mbr": 333310,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Zetkan",
    "mbr": 333328,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Zvonik",
    "mbr": 333336,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Vinkovec",
    "mbr": 336483,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Valetic",
    "mbr": 336491,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Luka",
    "mbr": 336505,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Brcevec",
    "mbr": 336513,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Poljanski Lug",
    "mbr": 336521,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Poljana",
    "mbr": 336530,
    "okn": "Vrbovec",
    "puk": "Zagreb"
  },
  {
    "ko": "Jakovlje",
    "mbr": 304808,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Bistransko Podgorje",
    "mbr": 335657,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Brdovec",
    "mbr": 335665,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Donja Bistra",
    "mbr": 335673,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Dubravica",
    "mbr": 335681,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Gornja Bistra",
    "mbr": 335690,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Kraj",
    "mbr": 335703,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Kupljenovo",
    "mbr": 335711,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Laduc",
    "mbr": 335720,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Pluska",
    "mbr": 335738,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Prosinec",
    "mbr": 335746,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Pušca",
    "mbr": 335754,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  },
  {
    "ko": "Zaprešic",
    "mbr": 335762,
    "okn": "Zaprešic",
    "puk": "Zagreb"
  }
]