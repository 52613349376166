import Control from 'ol/control/Control.js';
import locale from '../lang/hr.json';
import { IMAGIS } from '../index.js';
import Chart from 'chart.js/auto'
import 'chartjs-adapter-dayjs-3'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Select from 'ol/interaction/Select.js'
import { Style, Icon, Text, Stroke, Fill } from 'ol/style.js'

export class Telemetry extends Control {
    constructor(options = {}) {
        dayjs.extend(utc)
        const element = document.createElement('div');
        element.className = 'accordion-item';
        element.innerHTML = `
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#meter-cmms-accordion-item">
           <i class="fa-regular fa-meter-droplet" style="margin-right: 5px;"></i> ${locale.telemetryTitle}
           </button>
        </h2>
        <div id="meter-cmms-accordion-item" class="accordion-collapse collapse ${options.show ? 'show' : ''}" data-bs-parent="#index-accordion">
          <div class="accordion-body px-1"></div>
        </div>`;

        super({
            target: options.target,
            element
        })

        // options
        this.sensorsName = options.sensorsName || 'sensors'; // sensor layer name property
        this.selectionDistance = options.selectionDistance || 30 // distance for selection with map center, 30 m

        // binds for event functions
        this.handleOnShow = this.handleOnShow.bind(this);
        this.handleOnHide = this.handleOnHide.bind(this);

        // when is accordion-item shown/hidden
        this.accordionCollapse = this.element.querySelector('.accordion-collapse')

        // body to put content in
        this.accordionBody = element.querySelector('.accordion-body');
        this.accordionBody.innerHTML = `
        <div class="data-container d-none">
            <canvas class="telemetry"></canvas>
            <div class="single-feature d-none">
                <a class="btn download-measure-data mb-2">
                    <i class="fa-regular fa-folder-arrow-down"></i>
                    <span class="small"> Preuzmi mjerenja</span>
                </a>
                <table class="table table-borderless table-sm data-flow" 
                    style="
                    user-select: text;
                    -webkit-user-select: text;
                    -webkit-touch-callout: default;"
                >
                    <tr>
                        <td>${locale.telemetryStateStart}</td>
                        <td class="state-start">m3</td>
                    </tr>
                    <tr>
                        <td>${locale.telemetryStateEnd}</td>
                        <td class="state-end">m3</td>
                    </tr>
                    <tr>
                        <td>${locale.telemetryStateDiff}</td>
                        <td class="state-diff">m3</td>
                    </tr>
                    <tr>
                        <td>${locale.telemetryMin}</td>
                        <td class="state-min">l/s</td>
                    </tr>
                    <tr>
                        <td>${locale.telemetryMax}</td>
                        <td class="state-max">l/s</td>
                    </tr>
                </table>
                <table class="table table-borderless table-sm data-pressure-level">
                    <tr>
                        <td>${locale.telemetryStateStart}</td>
                        <td class = "state-start"></td>
                    </tr>
                    <tr>
                        <td>${locale.telemetryStateEnd}</td>
                        <td class="state-end"></td>
                    </tr>
                    <tr>
                        <td>${locale.telemetryMeanValue}</td>
                        <td class="state-mean"></td>
                    </tr>
                </table>
            </div>
            <div class="date-time mt-2 w-75">
                <div class="input-group input-group-sm mb-2">
                    <label class="input-group-text">Od</label>
                    <input type="datetime-local" class="input-start form-control">
                </div>
                <div class="input-group input-group-sm mb-2">
                    <label class="input-group-text">Do</label>
                    <input type="datetime-local" class="input-end form-control">
                </div>
                </div>
        </div>
        <h5 class="no-data-message">${locale.telemetrySelectSensor}</h5>
        `;
        const canvas = this.element.querySelector('.telemetry')
        this.chart = new Chart(canvas, this.chartCfg())
        this.color = {
            'Razina vode (cm)': 'green',
            'Tlak vode (bar)': 'red',
            'Protok vode (l/s)': 'blue'
        }
        this.timeFormat = 'YYYY-MM-DDTHH:mm'
        this.inputStartDate = element.querySelector('.input-start')
        this.inputEndDate = element.querySelector('.input-end')
        this.inputStartDate.value = dayjs().subtract(1, 'days').format(this.timeFormat)
        this.inputEndDate.value = dayjs().format(this.timeFormat)
        this.noDataMessage = this.element.querySelector('.no-data-message')
        this.dataContainer = this.element.querySelector('.data-container')
        this.singleFeature = this.element.querySelector('.single-feature')
        this.downloadMeasureData = this.element.querySelector('.download-measure-data')

        this.dataFlow = this.element.querySelector('.data-flow')
        this.flowStateStart = this.dataFlow.querySelector('.state-start')
        this.flowStateEnd = this.dataFlow.querySelector('.state-end')
        this.flowStateDiff = this.dataFlow.querySelector('.state-diff')
        this.flowStateMin = this.dataFlow.querySelector('.state-min')
        this.flowStateMax = this.dataFlow.querySelector('.state-max')

        this.dataPressureLevel = this.element.querySelector('.data-pressure-level')
        this.dataPressureLevelStateStart = this.dataPressureLevel.querySelector('.state-start')
        this.dataPressureLevelStateEnd = this.dataPressureLevel.querySelector('.state-end')
        this.dataPressureLevelStateMean = this.dataPressureLevel.querySelector('.state-mean')

        this.inputStartDate.addEventListener('change', () => this.handleChart())
        this.inputEndDate.addEventListener('change', () => this.handleChart())
    }
    handleChart() {
        this.chart.data.datasets = []
        const properties = []
        const promises = []
        this.features.forEach((feature, index) => {
            const id = feature.get('sensor_id')
            const unitName = feature.get('unit_name')
            properties.push({
                id,
                unitName,
                name: feature.get('entity_name'),
                color: this.generateColorHues(this.color[unitName], this.features.getLength())[index]
            })
            promises.push(IMAGIS.api.getTelemetry({
                id,
                startDate: dayjs(this.inputStartDate.value).utc().format(this.timeFormat),
                endDate: dayjs(this.inputEndDate.value).utc().format(this.timeFormat)
            }))
        })
        Promise.all(promises)
            .then(r => {
                r.forEach((x, i) => {
                    if (x.length > 0) {
                        this.chart.data.datasets.push({
                            data: x.map((v) => {
                                return {
                                    x: v.record_date,
                                    y: v.sensor_value
                                }
                            }),
                            label: properties[i].name,
                            borderColor: properties[i].color
                        })
                    }
                })
                this.chart.update()
                //if one feat fill data to download and table
                if (this.features.length > 1) return
                // measure data for download
                let textToSave = ''
                const dataset = this.chart.data.datasets[0]
                if (!dataset) return
                textToSave += `${dataset.label}\n`
                dataset.data.forEach(data => {
                    const date = data.x.toLocaleString('hr')
                    const value = data.y.toLocaleString('hr')
                    textToSave += `${date};${value}\n`
                })
                this.downloadMeasureData.href = 'data:attachment/text,' + encodeURI(textToSave)
                this.downloadMeasureData.download = `${dataset.label}.csv`
                // table
                const feature = this.features.item(0)
                const id = feature.get('sensor_id')
                const unitName = feature.get('unit_name')
                if (unitName === 'Protok vode (l/s)') {
                    this.dataPressureLevel.classList.add('d-none')
                    this.dataFlow.classList.remove('d-none')
                    // fetch start, end flowmeter state
                    const startToEnd = [r[0].at(0), r[0].at(-1)]
                    const promises = []
                    startToEnd.forEach(x => {
                        promises.push(IMAGIS.api.getTelemetryQuery({ id, queryDate: x.record_date }))
                    })
                    Promise.all(promises)
                        .then(r => {
                            const start = r[0][0].sensor_value
                            const end = r[1][0].sensor_value
                            const diff = end - start
                            const maxMin = this.getMinMax()
                            this.flowStateStart.textContent = `${start} m3`
                            this.flowStateEnd.textContent = `${end} m3`
                            this.flowStateDiff.textContent = `${diff} m3`
                            this.flowStateMin.textContent = `${maxMin[0]} l/s`
                            this.flowStateMax.textContent = `${maxMin[1]} l/s`
                        })
                } else {
                    this.dataPressureLevel.classList.remove('d-none')
                    this.dataFlow.classList.add('d-none')
                    const u = unitName === 'Tlak vode (bar)' ? { unitName: 'bar', unitCoef: 1 }
                        : { unitName: 'cm', unitCoef: 0.001 }
                    let sum = 0
                    r[0].forEach(d => {
                        sum += d.sensor_value
                    })
                    const mean = Math.round(Math.round(sum / r[0].length * 100) / 100 * u.unitCoef)
                    this.dataPressureLevelStateStart.textContent = `${r[0].at(0).sensor_value * u.unitCoef} ${u.unitName}`
                    this.dataPressureLevelStateEnd.textContent = `${r[0].at(-1).sensor_value * u.unitCoef} ${u.unitName}`
                    this.dataPressureLevelStateMean.textContent = `${mean} ${u.unitName}`
                }
            })
    }
    // handle accordion-item shown
    handleOnShow() {
        this.map.addInteraction(this.select)
        console.log('telemetry shown',this.map.getInteractions())
    }
    //handle acoordinon-item hidden
    handleOnHide() {
        this.map.removeInteraction(this.select)
        console.log('telemetry hiden',this.map.getInteractions())
    }
    // style sensors accoding to activity
    styleActivity() {
        IMAGIS.api.telemetryTest()
            .then(r => {
                if (r.code) return
                r.forEach(x => {
                    const feature = this.sensors.getSource().getFeatures()
                        .find(feature => feature.get('sensor_id') === x.sensor_id)
                    const style = (feature, resolution) => {
                        if (resolution >= 17) return new Style(null)
                        return new Style({
                            image: new Icon({
                                src: 'assets/sensorQ.png',
                                color: 'rgba(169, 169, 169, 1)',// just tints color
                                opacitiy: 0.5,
                                scale: 0.5,
                                declutterMode: true
                            }),
                            text: new Text({
                                text: feature.get('entity_name'),
                                font: "normal normal 10px Arial",
                                stroke: new Stroke({
                                    color: 'white',
                                    width: 2
                                }),
                                fill: new Fill({
                                    color: 'black'
                                }),
                                offsetY: 24
                            })
                        })
                    }
                    feature.setStyle(
                        style
                    );
                })
            })
    }
    // what to do when control added to map
    setMap(map) {
        super.setMap(map);
        this.map = map;
        this.sensors = map.getAllLayers().find(x => x.get('name') === this.sensorsName);
        this.select = new Select({
            layers: [this.sensors]
        })
        // what happens on show accordion-item
        this.element.addEventListener('shown.bs.collapse', this.handleOnShow)
        // accordion item is initialy shown
        if (this.accordionCollapse.classList.contains('show')) this.handleOnShow()
        // what happens on hide accordion-item
        this.element.addEventListener('hidden.bs.collapse', this.handleOnHide)
        this.select.on('select', () => {
            this.features = this.select.getFeatures()
            this.noDataMessage.classList.toggle('d-none', this.features.getLength() > 0);
            this.dataContainer.classList.toggle('d-none', this.features.getLength() === 0);
            if (this.features.getLength() > 0) this.handleChart()
            if (this.features.getLength() > 1) this.singleFeature.classList.add('d-none')
            else this.singleFeature.classList.remove('d-none')
        })

        this.styleActivity()
    }
    chartCfg() {
        return {
            type: 'line',
            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        onHover: function (event) {
                            event.chart.canvas.style.cursor = 'pointer'
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 4,
                        hoverRadius: 4
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            major: {
                                enabled: true
                            },
                            font: function (context) {
                                if (context.tick && context.tick.major) {
                                    return {
                                        weight: 'bold',
                                        size: '15'
                                    }
                                }
                            }
                        },
                        type: 'time',
                        time: {
                            unit: 'hour',
                            stepSize: 1,
                            tooltipFormat: 'DD.MM HH:mm',
                            displayFormats: {
                                hour: 'HH:mm',
                                day: 'DD.MM.'
                            }
                        }
                    },
                    y: {
                        type: 'linear',
                        point: { radius: 0 },
                        display: true,
                        position: 'left',
                        title: {
                            display: false
                        }
                    }
                }
            }
        }
    }
    generateColorHues(baseColor, numShades) {
        let baseHue
        switch (baseColor.toLowerCase()) {
            case 'red':
                baseHue = 0 // Red hue starts at 0
                break
            case 'blue':
                baseHue = 240 // Blue hue starts at 240
                break
            case 'green':
                baseHue = 120 // Green hue starts at 120
                break
            default:
                console.error('Unsupported color')
                return
        }
        const shades = []
        for (let i = 0; i < numShades; i++) {
            // Calculate the hue for the color range
            const hue = baseHue + (i * (60 / numShades)) // 60-degree range for variation
            shades.push(`hsl(${hue}, 100%, 50%)`) // Full saturation and 50% lightness
        }
        return shades
    }
    getMinMax() {
        const data = this.chart.data.datasets[0].data
        let minValue = Infinity
        let maxValue = -Infinity
        for (let item of data) {
            if (item.y < minValue) { minValue = item.y }
            if (item.y > maxValue) { maxValue = item.y }
        }
        minValue = Math.round(minValue * 100) / 100
        maxValue = Math.round(maxValue * 100) / 100
        return [minValue, maxValue]
    }
}

