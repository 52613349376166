// convert SDF files from MapGuide project to geojson and loading them to map as vector layers
import locale from '../lang/hr.json';
import Control from 'ol/control/Control';
import Modal from 'bootstrap/js/dist/modal';

// !! TODO: not finished
export class SdfConverter extends Control {
    constructor(options = {}) {
        const element = document.createElement('div');
        element.className = 'modal';
        element.innerHTML = `
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header" style="border:none">
                    <h5 class="modal-title" id="propertiesModalLabel">${locale.SdfConverterTitle}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="sdf-converter-resource-id" class="form-label">${locale.SdfConverterResourceId}</label>
                        <input type="text" class="form-control" id="sdf-converter-resource-id" placeholder="vikSplit/Data/vodoopskrba3">
                    </div>
                    <div>
                        <label for="sdf-converter-floatingSelect">Select classes</label>
                        <select class="form-select" id="sdf-converter-floatingSelect" size="3" style="height:auto;">
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select> 
                    </div>
                </div>
                <div class="modal-footer" style="border:none">
                    <span id="errorReport" class="text-danger"></span>
                    <button type="button" class="btn btn-secondary">
                        <i class="fa-regular fa-download"></i>
                    </button>
                    <button type="button" class="btn btn-danger" id="applyChangesBtn">${locale.SdfConverterApply}</button>
                </div>
            </div>
        </div>
        `;
        super({
            target: document.body,
            element
        });

        this.modal = new Modal(this.element);
        this.previousState = null;

        // Event listeners for buttons
       
    }

    toggle() {
        this.modal.toggle();
    }
}