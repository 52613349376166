import LegendControl from 'ol-ext/control/Legend.js';
import LegendComponent from 'ol-ext/legend/Legend.js';
import VectorLayer from 'ol/layer/Vector';
import { Style, Circle, Fill, Stroke, Text } from 'ol/style';
import { IMAGIS } from '../index.js';
import locale from '../lang/hr.json';

export function legend() {
    const legendComponent = new LegendComponent({
        title: locale.legendTitle,
    })
    getItems(legendComponent)
    IMAGIS.map.getView().on('change:resolution', evt => {
        legendComponent.getItems().clear()
        getItems(legendComponent)
        legendComponent.refresh()
    })
    const legendControl = new LegendControl({ legend: legendComponent })
    const button = legendControl.element.querySelector('button')
    button.innerHTML = '<i class="fa-regular fa-list-tree"></i>'
    IMAGIS.map.addControl(legendControl)

    legendControl.on('select', evt => {
        if (evt.item.get('categoryHeader')) {
            const layers = IMAGIS.map.getAllLayers().filter(x => x.get('category') === evt.item.get('category'))
            layers.forEach(layer => {
                layer.setVisible(!evt.item.get('visible'))
            })
            evt.item.set('visible', !evt.item.get('visible'))
            const items = legendComponent.getItems().getArray().filter(x => x.get('category') === evt.item.get('category'))
            items.forEach(item => {
                item.set('textStyle', evt.item.get('visible') ? new Text({ font: '16px sans-serif' })
                    : new Text({ font: 'italic lighter 15px Arial' }))
            })
            legendComponent.refresh()
        }
        if (evt.item.get('name')) {
            const layer = IMAGIS.map.getAllLayers().find(x => x.get('name') === evt.item.get('name'))
            layer.setVisible(!layer.getVisible())
            evt.item.set('textStyle', layer.getVisible() ? new Text({ font: '16px sans-serif' })
                : new Text({ font: 'italic lighter 15px Arial' }))
            legendComponent.refresh()
        }
    })
}
function getItems(legendComponent) {
    const vectorLayers = IMAGIS.map.getLayers().getArray()
        .filter(x => x instanceof VectorLayer && x.get('category') !== 'system')
        .sort((a, b) => a.category - b.category)
        .sort((a, b) => b.index - a.index)
    vectorLayers.forEach(layer => {
        let categoryItem = legendComponent.getItems()
            .getArray().find(x => x.get('category') === layer.get('category'))
        if (!categoryItem) {
            categoryItem = {
                title: locale[layer.get('category')],
                category: layer.get('category'),
                categoryHeader: true,
                visible: true
            }
            legendComponent.addItem(categoryItem)
        }
        const feature = layer.getSource().getFeatures()[0]
        const resolution = IMAGIS.map.getView().getResolution()
        let style = layer.getStyle()
        if (typeof style === 'function') { style = style(feature, resolution) }
        const item = {
            title: locale[layer.get('name')],
            name: layer.get('name'),
            category: layer.get('category'),
            categoryHeader: false,
            style,
            textStyle: layer.getVisible() ? new Text({ font: '16px sans-serif' })
                : new Text({ font: 'italic lighter 15px Arial' }),
            feature
        }
        if (item.style && item.style.length !== 0) {
            legendComponent.addItem(item)
        }
    })
}