import Control from 'ol/control/Control.js';
import locale from '../lang/hr.json';
import Select from 'ol/interaction/Select.js'

export class Properties extends Control {
    constructor(options = {}) {
        // generate accordion-item
        const element = document.createElement('div');
        element.className = 'accordion-item';
        element.innerHTML = `
        <h2 class="accordion-header">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#properties-accordion-item">
            <i class="fa-regular fa-table-list" style="margin-right: 5px;"></i> <span class="title">${locale.propertiesTitle}</span>
            </button>
        </h2>
        <div id="properties-accordion-item" class="accordion-collapse collapse ${options.show ? 'show' : ''}" data-bs-parent="#index-accordion">
            <div class="accordion-body px-1"></div>
        </div>`;
        super({
            target: options.target,
            element
        });
        this.accordionBody = element.querySelector('.accordion-body');
        this.accordionBody.innerHTML = `

        <!-- Layer select -->
        <div class="form-floating layer-select-container mb-3 d-none">
            <select class="form-select layer-select"></select>
            <label>${locale.propertiesLayersSelect}</label>
        </div>

        <!-- Properties table -->
         <table class="table table-hover table-sm d-none" style="
            user-select: text; <-- make text selectable -->
            -webkit-user-select: text;
            -webkit-touch-callout: default;">
            <thead>
                <tr>
                    <th>${locale.propertiesProperty}</th>
                    <th>${locale.propertiesValue}</th>
                </tr>
            </thead>
            <tbody class="table-body"></tbody>
        </table>

        <!-- Nothing selected message -->
     <h5 class="no-data-message">${locale.propertiesNoSelect}</h5>
    `
        //html items
        this.accordionCollapse = this.element.querySelector('.accordion-collapse')
        this.accordionTitle = this.element.querySelector('.title')
        this.noData = this.accordionBody.querySelector('.no-data-message')
        this.table = this.accordionBody.querySelector('.table')
        this.layerSelectContainer = this.accordionBody.querySelector('.layer-select-container')
        this.layerSelect = this.accordionBody.querySelector('.layer-select')
        this.tableBody = this.accordionBody.querySelector('.table-body')


        // get or make select interaction
        this.select = options.select || new Select({
            hitTolerance: 10
        })

        // binds for event functions
        this.handleOnShow = this.handleOnShow.bind(this);
        this.handleOnHide = this.handleOnHide.bind(this);
        this.handleOnLayerSelect = this.handleOnLayerSelect.bind(this)
    }
    // event handlers
    //  accordion-item shown
    handleOnShow() {
        this.map.addInteraction(this.select)
        this.handleSelect() // this.select may have features from before
    }
    //acoordinon-item hidden
    handleOnHide() {
        this.map.removeInteraction(this.select)
    }
    // one or more features selected
    handleSelect() {
        this.classifySelectByLayer()
        if (this.items.length === 0) {
            this.noData.classList.remove('d-none')
            this.table.classList.add('d-none')
            this.layerSelectContainer.classList.add('d-none')
            return
        }
        this.noData.classList.add('d-none')
        this.table.classList.remove('d-none')
        this.layerSelectContainer.classList.remove('d-none')
        this.fillLayerSelect()// fill with one or more
        this.fillTableBody()

    }
    // refill table body
    handleOnLayerSelect() {
        this.fillTableBody()
    }

    // on control added to map
    setMap(map) {
        super.setMap(map)
        this.map = map
        // what happens on show accordion-item
        this.element.addEventListener('shown.bs.collapse', this.handleOnShow)
        // if accordion item initialy shown
        if (this.accordionCollapse.classList.contains('show')) this.handleOnShow()
        // what happens on hide accordion-item
        this.element.addEventListener('hidden.bs.collapse', this.handleOnHide)
        // select
        this.select.on('select', (evt) => this.handleSelect())
        this.items = []
    }

    // fill table body with rows
    fillTableBody() {
        const empty = [-1, '1999-12-31T23:00:00+00:00', '']
        this.tableBody.innerHTML = ''
        // read layer from layersSelect
        const item = this.items.find(x => x.layer.get('name') === this.layerSelect.value)
        const ref = item.features[0].getProperties()
        item.features.forEach(feature => {
            for (const [key, value] of Object.entries(ref)) {
                if (value !== feature.get(key)) {
                    ref[key] = locale.propertiesVaries
                }
            }
        })
        Object.keys(ref).forEach(key => {
            let valueText = empty.includes(ref[key]) ? locale.unknown : ref[key]
            if (ref[key] === true) valueText = '✔'
            if (ref[key] === false) valueText = '✖'
            if (key.includes('_date')) { valueText = this.convertToLocal(ref[key], false) || ref[key] }
            const className = valueText === locale.propertiesVaries ? "text-wrap text-secondary" : "text-wrap"
            if (key !== 'geometry') {
                const row = `
                <tr>
                    <td>${locale[key] || key}</td>
                    <td class="${className}">${valueText}</td>
                </tr>
               `
                this.tableBody.innerHTML += row
                
            }
        })
       

    }
    // fill layer select with layer names
    fillLayerSelect() {
        this.layerSelect.innerHTML = ''
        this.items.forEach(x => {
            const name = locale[x.layer.get('name')] || x.layer.get('name')
            this.layerSelect.add(new Option(`${name} (${x.features.length})`, x.layer.get('name')))
        })
        this.layerSelect.addEventListener('change', this.handleOnLayerSelect)
    }
    // select clasified by layers: [{layer:layer,fetaures:[]},..]
    classifySelectByLayer() {
        this.items = []
        this.select.getFeatures().getArray().forEach(feature => {
            const featureLayer = this.select.getLayer(feature)
            const layer = this.items.find(x => x.layer === featureLayer)
            if (!layer) this.items.push({ layer: featureLayer, features: [feature] })
            else layer.features.push(feature)
        })
    }
    convertToLocal(isoString, showTime = true) {
        // Skraćivanje mikrosekundi na milisekunde
        let modifiedIsoString = isoString.replace(/\.(\d{3})\d+/, '.$1');

        // Kreiranje novog Date objekta
        let date = new Date(modifiedIsoString);

        // Provjera je li datum ispravan
        if (isNaN(date)) {
            console.error('Nevažeći datum');
            return null;
        }

        // Opcije za formatiranje datuma
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        };

        // Ako je showTime true, uključujemo vrijeme
        if (showTime) {
            options.hour = '2-digit';
            options.minute = '2-digit';
            options.second = '2-digit';
        }

        // Formatiranje u lokalni string
        let localDateString = date.toLocaleString(undefined, options);

        return localDateString;
    }

}