import Control from 'ol/control/Control.js';
import locale from '../lang/hr.json';
import { Style, Circle, Fill, Stroke } from 'ol/style';
import { IMAGIS } from '../index.js';
import GeoJSON from 'ol/format/GeoJSON.js'
import { containsCoordinate } from 'ol/extent';
import { toLonLat } from 'ol/proj';
import { Toast } from 'bootstrap';

export class MetersCmms extends Control {
  constructor(options = {}) {
    // generate accordion-item
    const element = document.createElement('div');
    element.className = 'accordion-item';
    element.innerHTML = `
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#meter-cmms-accordion-item">
       <i class="fa-regular fa-meter-droplet" style="margin-right: 5px;"></i> ${locale.metersCmmsTitle}
       </button>
    </h2>
    <div id="meter-cmms-accordion-item" class="accordion-collapse collapse ${options.show ? 'show' : ''}" data-bs-parent="#index-accordion">
      <div class="accordion-body px-1"></div>
    </div>`;
    super({
      target: options.target,
      element
    });
    // options
    this.linkedMetersName = options.linkedMetersName || 'meters'; // layer with meters name
    this.resolution = options.resolution || 1 // max resolution (1:4000) to show meters in style
    this.selectionDistance = options.selectionDistance || 30 // distance for selection with map center, 30 m

    // binds for event functions
    this.handleOnShow = this.handleOnShow.bind(this);
    this.handleOnHide = this.handleOnHide.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCenterChange = this.handleCenterChange.bind(this);
    this.handleMapCenterSelection = this.handleMapCenterSelection.bind(this);
    this.visibility = this.visibility.bind(this)

    // when is accordion-item shown/hidden
    this.accordionCollapse = this.element.querySelector('.accordion-collapse')

    const showUpload = IMAGIS.api.getUserRole().includes("admin")
    // body to put content in
    this.accordionBody = element.querySelector('.accordion-body');
    this.accordionBody.innerHTML = `
     <!-- Hide !is-old features -->
    <div class="form-check form-switch m-2">
        <label class="form-check-label">
        <input class="form-check-input border-dark visibility-option" type="checkbox" >${locale.metersCmmsHideIsOldFeatures}
        </label>
    </div>
      <!-- Switch meters features selection method -->
    <div class="form-check form-switch mx-2">
     <label class="form-check-label">
      <input class="form-check-input border-dark map-center-selection" type="checkbox">${locale.metersCmmsSelect}
      </label>
    </div>
    ${showUpload ? `
     <!-- Upload CSV -->
  <input id="upload" type="file"/>
  <a class="btn upload-csv my-2">
      <i class="fa-regular fa-folder-arrow-up"></i>
      <span class="small"> Učitaj novi csv</span>
  </a>` : ''}
           <!-- Download csv -->
         <a class="btn download-csv my-2">
            <i class="fa-regular fa-folder-arrow-down"></i>
            <span class="small"> Preuzmi csv</span>
        </a>
      <!-- Features near map view center -->
    <div class= "nearest-content d-none">
      <div class="nearest-header my-2">${locale.metersCmmsNearistSelector}</div>
      <div class="nearest list-group"></div>
    </div>
      <!-- Selected feature tables section -->
    <div class="tables my-3 d-none">
    <div>
      <strong class="selected-info">${locale.metersCmmsSelectedProperties}</strong>
        <!-- Selected feature data table -->
      <table class="table properties-table table-primary table-striped table-sm">
          <tr>
              <td>${locale.point_code}</td>
              <td class="point_code"></td>
          </tr>
          <tr>
              <td>${locale.code_name}</td>
              <td class="code_name"></td>
          </tr>
          <tr>
              <td>${locale.meter_value}</td>
              <td class="meter_value"></td>
          </tr>
          </table>
          <button type="button" class="btn btn-link btn-sm more">${locale.metersCmmsMore}...</button>
          <table class="table table-secondary table-stripe aux-table table-sm d-none" >
          <tr>
              <td>${locale.hodograph_id}</td>
              <td class="hodograph_id"></td>
          </tr>
          <tr>
              <td>${locale.partner_name}</td>
              <td class="partner_name"></td>
          </tr>
          <tr>
              <td>${locale.address_name}</td>
              <td class="address_name"></td>
          </tr>
          <tr>
              <td>${locale.diameter_name}</td>
              <td class="diameter_name"></td>
          </tr>
          <tr>
              <td>${locale.calibration_date}</td>
              <td class="calibration_date"></td>
          </tr>
          <tr>
              <td>${locale.has_sewer}</td>
              <td class="has_sewer"></td>
          </tr>
          <tr>
              <td>${locale.has_hydrant}</td>
              <td class="has_hydrant"></td>
          </tr>
          <tr>
              <td>${locale.point_geometry}</td>
              <td class="point_geometry"></td>
          </tr>
          <tr>
              <td>${locale.note_content}</td>
              <td class="note_content"></td>
          </tr>
        </table>
      </div>
      <!-- Selected old meter feature inputs table -->
    <div class="is-old">
        <div class="mb-2"><strong>${locale.meterCmmsInputTableHeader}</strong></div>

        <!-- Last Read -->
        <div class="form-floating mb-1">
            <input type="number" class="form-control last_read" value="30" min="0">
            <div class="invalid-feedback">${locale.meterCmmsWarningPositiveNumber}</div>
            <label><strong>${locale.last_read}</strong></label>
        </div>

        <!-- Replacement Date -->
        <div class="form-floating mb-3">
            <input type="date" class="form-control replacement_date" value="${new Date().toISOString().split('T')[0]}">
            <label><strong>${locale.replacement_date}</strong></label>
        </div>

        <!-- New Code Name -->
        <div class="form-floating mb-3">
            <input type="text" class="form-control code_name" value="0">
            <div class="invalid-feedback">${locale.meterCmmsWarningRequired}</div>
            <label><strong>${locale.metersCmmsNewCodeName}</strong></label>
        </div>

        <!-- New Calibration Date -->
        <div class="form-floating mb-3">
            <input type="date" class="form-control calibration_date" value="${new Date().toISOString().split('T')[0]}">
            <label><strong>${locale.metersCmmsNewCalibrationDate}</strong></label>
        </div>
        <div class="d-flex gap-2 mb-3">
            <button type="button" class="btn btn-outline-primary btn-sm flex-grow-1 subtract-year">
            ${locale.metersCmmsNewCalibrationDateSubtractYear}</button>
             <button type="button" class="btn btn-outline-primary btn-sm flex-grow-1 add-year">
            ${locale.metersCmmsNewCalibrationDateAddYear}</button>
        </div>

        <!-- Meters CMMS New Read -->
        <div class="form-floating mb-3">
            <input type="number" class="form-control meter_value" value="0" min="0">
            <div class="invalid-feedback">${locale.meterCmmsWarningPositiveNumber}</div>
            <label><strong>${locale.metersCmmsNewRead}</strong></label>
        </div>

        <!-- Diameter Name -->
        <div class="input-group mb-3">
          <div class="form-floating">
            <input type="text" class="form-control diameter_name" placeholder="Type something...">
            <label><strong>${locale.diameter_name}</strong></label>
          </div>
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"></button>
          <div class="dropdown-menu dropdown-menu-end" style="max-height: 300px; overflow-y: auto;">
            <div class="dropdown-item" data-value="1/2">1/2</div>
            <div class="dropdown-item" data-value="3/4">3/4</div>
            <div class="dropdown-item" data-value="5/4">5/4</div>
            <div class="dropdown-item" data-value="6/4">6/4</div>
            <div class="dropdown-item" data-value="1">1</div>
            <div class="dropdown-item" data-value="2">2</div>
            <div class="dropdown-item" data-value="65">65</div>
            <div class="dropdown-item" data-value="80">80</div>
            <div class="dropdown-item" data-value="100">100</div>
            <div class="dropdown-item" data-value="125">125</div>
            <div class="dropdown-item" data-value="200">200</div>
          </div>
      </div>

        <!-- Worker Name -->
        <div class="form-floating mb-3">
            <input type="text" class="form-control worker_name"
                value="${IMAGIS.api.keycloak.idTokenParsed.preferred_username}">
            <div class="invalid-feedback">${locale.meterCmmsWarningRequired}</div>
            <label><strong>${locale.worker_name}</strong></label>
        </div>

        <!-- Save -->
        <div class="d-flex justify-content-center">
            <button type="button" class="save btn btn-primary w-75">${locale.metersCmmsSave}</button>
        </div>
         <!-- Toast for save ok -->
        <div class="toast align-items-center toast-info mt-3">
          <div class="d-flex">
            <div class="toast-body  text-success">
            <i class="fa-regular fa-thumbs-up"></i>
              ${locale.meterCmmsSucess}.
            </div>
            <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast"></button>
          </div>
        </div>
    </div>
    `
    this.visibilityOption = this.accordionBody.querySelector('.visibility-option')
    this.visibilityOption.addEventListener('change', this.visibility)
    this.mapCenterSelection = this.accordionBody.querySelector('.map-center-selection')
    this.mapCenterSelection.addEventListener('change', this.handleMapCenterSelection)
    this.nearestContent = this.accordionBody.querySelector('.nearest-content')
    this.nearestFeaturesList = this.nearestContent.querySelector('.nearest')
    //csv link
    this.link = this.accordionBody.querySelector('.download-csv')
    this.uploadLink = this.accordionBody.querySelector('.upload-csv')
    //tables
    this.tables = this.accordionBody.querySelector('.tables')
    this.propertiesTable = this.tables.querySelector('.properties-table')
    this.isOld = this.accordionBody.querySelector('.is-old')
    this.auxTable = this.accordionBody.querySelector('.aux-table')
    this.save = this.accordionBody.querySelector('.save')
    this.sucess = this.accordionBody.querySelector('.send_ok')
    const toastInfo = this.accordionBody.querySelector('.toast-info')
    this.toastBootstrap = Toast.getOrCreateInstance(toastInfo)
    this.inputDiameterName = this.isOld.querySelector('.diameter_name')
    // save data event
    this.save.addEventListener('click', () => this.handleSaveData())

    // manage selected feature properties display tables
    const more = this.accordionBody.querySelector('.more')
    more.addEventListener('click', () => {
      this.auxTable.classList.toggle('d-none')
      if (this.auxTable.classList.contains('d-none')) more.textContent = locale.metersCmmsMore + '...'
      else more.textContent = locale.metersCmmsLess + '...'
    })

    // manage diameter_name input to update the input value when an item is clicked
    this.isOld.querySelectorAll('.dropdown-item').forEach((item) => {
      item.addEventListener('click', () => {
        this.inputDiameterName.value = item.getAttribute('data-value');
      });
    });

    // validate last_read input on input (just for info)
    const lastReadInput = this.isOld.querySelector('.last_read');
    const lastReadInvalidFeedback = lastReadInput.nextElementSibling;
    const initialFeedbackMessage = lastReadInvalidFeedback.textContent; // Store initial message
    lastReadInput.addEventListener('input', () => {
      if (this.feature) {
        const meterValue = this.feature.get('meter_value');
        const intValue = parseInt(lastReadInput.value)
        if (!isNaN(intValue) && intValue <= meterValue + 30 && intValue > 0) {
          lastReadInput.classList.remove('is-invalid');
          lastReadInvalidFeedback.textContent = initialFeedbackMessage; // Reset to initial message only when valid
        } else {
          lastReadInvalidFeedback.textContent = locale.meterCmmsWarningNumberOver30; // Update the warning message
          lastReadInput.classList.add('is-invalid');
        }
      }
    })

    // add/subtract one year to replacement_date input by buttons
    const calibrationDate = this.isOld.querySelector('.calibration_date');
    const addYear = this.isOld.querySelector('.add-year');
    const subtractYear = this.isOld.querySelector('.subtract-year');
    let date = new Date(calibrationDate.value);
    const currentYear = new Date().getFullYear()
    const updateButtonStates = () => {
      const selectedYear = new Date(calibrationDate.value).getFullYear();
      // Disable the add button if the year is greater than currentYear + 1
      addYear.disabled = selectedYear >= currentYear + 5;
      // Disable the subtract button if the year is less than currentYear - 1
      subtractYear.disabled = selectedYear <= currentYear - 5;
    }
    addYear.addEventListener('click', () => {
      const selectedDate = new Date(calibrationDate.value);
      selectedDate.setFullYear(selectedDate.getFullYear() + 1);
      calibrationDate.value = selectedDate.toISOString().split('T')[0];
      updateButtonStates(); // Update button states after changing the date
    })
    subtractYear.addEventListener('click', () => {
      const selectedDate = new Date(calibrationDate.value);
      selectedDate.setFullYear(selectedDate.getFullYear() - 1);
      calibrationDate.value = selectedDate.toISOString().split('T')[0];
      updateButtonStates(); // Update button states after changing the date
    })

    if (showUpload) {
      this.fileInput = this.accordionBody.querySelector('#upload')
      this.fileInput.addEventListener('change', async (event) => {
        const file = event.target.files[0];  // Get the first selected file
        IMAGIS.api.setCsvUpload(file)
      });

      this.uploadLink.addEventListener('click', async (event) => {
        event.preventDefault();
        document.querySelector('#upload').click();
      });
    }



  }

  // handle select meter features by map center or selectionPoint on mobile
  handleMapCenterSelection() {
    if (this.mapCenterSelection.checked) {
      this.nearestContent.classList.remove('d-none')
      this.handleCenterChange()// initial map center select
      // activate map events to change map center
      this.map.getView().on('change:center', this.handleCenterChange)
      // deactivate map events to select meter feature
      this.map.un('click', this.handleClick);
    } else {
      this.nearestContent.classList.add('d-none')
      if (this.feature) this.feature.setStyle(null)// remove previous selected feature style
      this.feature = undefined
      // deactivate map events to change map center
      this.map.getView().un('change:center', this.handleCenterChange)
      // activate map events to select meter feature
      this.map.on('click', this.handleClick);
    }
  }

  // handle accordion-item shown
  handleOnShow() {
    if (this.map.getView().getResolution() > this.resolution) console.log('show: reduce resolution')
    this.metersLayerStyle = this.meters.getStyle()
    this.addIsOldProperty()
    this.setStyle() // set style for thematic isOld true/false
    // activate map events to select meter feature

    this.map.on('click', this.handleClick);
    // initial state of handle map selection
    this.handleMapCenterSelection()
  }

  //handle acoordinon-item hidden
  handleOnHide() {
    if(this.meters)this.meters.setStyle(this.metersLayerStyle)
   if(this.feature) this.feature.setStyle(null)
    this.removeIsOldProperty()
    // deactivate map events to select meter feature
    this.map.un('click', this.handleClick);
    // deactivate map events to change map center
    this.map.getView().un('change:center', this.handleCenterChange)
  }

  // handle select meters feature by map click/touchstart
  handleClick(evt) {
    const features = this.map.getFeaturesAtPixel(evt.pixel, {
      layerFilter: (layer => layer === this.meters),
      hitTolerance: 50
    })
    if (this.feature) this.feature.setStyle(null)// remove previous selected feature style
    this.feature = features[0]
    if (this.feature) this.feature.setStyle(this.setStyleSelected())
    this.useSelect()
  }

  // handle select meters feature by selectionPoint
  handleCenterChange() {
    let selectionPoint = this.map.getView().getCenter();
    // find metars within this.selectionDistance from selectionPoint
    const distance = this.selectionDistance
    const extent = [
      selectionPoint[0] - distance, selectionPoint[1] - distance,
      selectionPoint[0] + distance, selectionPoint[1] + distance
    ];
    const metersInExtent = [];// array of meters in distance from center or selection point (on mobile screen)
    this.meters.getSource().forEachFeature((feature) => {
      const featureCoords = feature.getGeometry().getCoordinates();
      if (containsCoordinate(extent, featureCoords)) {
        metersInExtent.push(feature);
      }
    })
    // add metersInExtent in nearestFeaturesList html
    this.nearestFeaturesList.innerHTML = ''
    let featureSelected = null
    const activateFeature = (listItem, feature) => {
      // Deactivate the previously selected item and feature
      const activeItem = this.nearestFeaturesList.querySelector('.active');
      if (activeItem) activeItem.classList.remove('active');
      // Set the new active item and feature
      listItem.classList.add('active');
      featureSelected = feature;
      if (this.feature) this.feature.setStyle(null)// remove previous selected feature style
      this.feature = featureSelected;
      if (this.feature) this.feature.setStyle(this.setStyleSelected())
      this.useSelect()
    };
    metersInExtent.forEach((feature, index) => {
      const listItem = document.createElement('a')
      listItem.className = 'list-group-item list-group-item-action list-group-item-light';
      listItem.textContent = feature.get('point_code')
      listItem.dataset.index = index
      if (index === 0) {
        activateFeature(listItem, feature);
      }
      listItem.addEventListener('click', () => {
        if (featureSelected !== feature) {
          activateFeature(listItem, feature);
        }
      });
      this.nearestFeaturesList.appendChild(listItem)
    })
  }

  // handle save input changes to server
  handleSaveData() {
    const formData = {};
    const container = this.element.querySelector('.is-old');
    formData.last_read = container.querySelector('.last_read').value;
    formData.replacement_date = container.querySelector('.replacement_date').value;
    formData.code_name = container.querySelector('.code_name').value;
    formData.calibration_date = container.querySelector('.calibration_date').value;
    formData.meter_value = container.querySelector('.meter_value').value;
    formData.diameter_name = container.querySelector('.diameter_name').value;
    formData.worker_name = container.querySelector('.worker_name').value;
    console.log('Form data to save:', formData)
    const geoJson = new GeoJSON({
      featureProjection: IMAGIS.map.getView().getProjection()
    })
    if (!this.validation()) return
    IMAGIS.api.setMeter(this.feature.get('object_id'), formData)
      .then((r) => {
        IMAGIS.api.getGeoJsonData(this.meters.get('name'))
          .then(data => {
            const updatedFeature = geoJson.readFeatures(data)
              .find(x => x.get('point_code') === this.feature.get('point_code'))
            this.addIsOldProperty(updatedFeature)
            this.meters.getSource().removeFeature(this.feature)
            this.meters.getSource().addFeature(updatedFeature)
            this.feature = updatedFeature
            if (this.feature) this.feature.setStyle(this.setStyleSelected())
            this.useSelect()
            this.csvDownload()
            // all features in source (for visibility)
            this.features = this.meters.getSource().getFeatures()
            this.toastBootstrap.show()
          })
      })
  }

  // what to do when control added to map
  setMap(map) {
    super.setMap(map);
    this.map = map;
    this.meters = map.getAllLayers().find(x => x.get('name') === this.linkedMetersName);
    // set initial state if accordion-item is shown
    this.trimDN() // remove empty spaces in 'diameter_name' of all meter features, allways keept
    // what happens on show accordion-item
    this.element.addEventListener('shown.bs.collapse', this.handleOnShow)
    // accordion item is initialy shown
    if (this.accordionCollapse.classList.contains('show')) this.handleOnShow()
    // what happens on hide accordion-item
    this.element.addEventListener('hidden.bs.collapse', this.handleOnHide)
    // all features in source (for visibility)
    this.features = this.meters.getSource().getFeatures()
    // download csv link
    this.csvDownload()
  }

  // new meters visibility option on isOld property
  visibility() {
    const source = this.meters.getSource()
    const oldFeatures = source.getFeatures().filter(x => x.get('isOld'))
    source.clear()
    if (this.visibilityOption.checked) {
      source.addFeatures(oldFeatures)
    } else {
      source.addFeatures(this.features)
    }
    this.handleMapCenterSelection()
  }

  // download csv link 
  csvDownload() {
    IMAGIS.api.csvExport()
      .then(jsonArray => {
        // fit format issues
        jsonArray.forEach(x => {
          x.coordinates = x.point_geometry.coordinates
          //LatLon -> LonLat
          x.coordinates = [x.coordinates[1], x.coordinates[0]]
          delete x.point_geometry
          delete x.object_id
          // set yyyymmddhhmmss format per usr req
          x['record_date'] = this.compactDateTime(x['record_date'])
          x['calibration_date'] = this.compactDateTime(x['calibration_date'])
          if (x['record_date'].substring(0, 4) === '9999') x['record_date'] = ''
          if (x['calibration_date'].substring(0, 4) === '9999') x['calibration_date'] = ''
          x['diameter_name'] = x['diameter_name'].trim()
        })
        // Extract headers from the first object in the array
        let headers = Object.keys(jsonArray[0]);
        // Convert each object to a CSV row using original headers
        const csvRows = jsonArray.map(row =>
          headers.map(field => {
            let value = row[field];
            // Convert arrays (like coordinates) to strings
            if (Array.isArray(value)) {
              value = value.join(','); // Use comma to join array elements
            }
            // Escape quotes, and wrap strings in double quotes if they contain semicolons
            if (typeof value === 'string') {
              value = value.replace(/"/g, '""'); // Escape double quotes
              if (value.includes(';')) {
                value = `"${value}"`; // Wrap the string in double quotes if it contains semicolons
              }
            }
            return value;
          }).join(';') // Join each row with a semicolon
        );
        const renamedHeaders = headers.map(header => locale[header] || header);
        let csvContent = [renamedHeaders.join(';'), ...csvRows].join('\n');
        // Replace the original header row with the renamed header row in the CSV content
        csvContent = csvContent.replace(headers.join(';'), renamedHeaders.join(';'));
        this.link.href = 'data:attachment/text,' + encodeURI(csvContent)
        const name = `vodomjeri-${new Date().toISOString().split('T')[0]}`
        this.link.download = `${name}.csv`
      })
  }
  // for csvDownload: iso8601 datetime format conversion to yyyymmddhhmmss (compact datetime format)
  compactDateTime(isoDateStr) {
    if (isoDateStr === '') return
    const date = new Date(isoDateStr);
    // Function to pad single-digit numbers with a leading zero
    const padZero = (num) => num.toString().padStart(2, '0');
    // Extract the date components
    const year = date.getUTCFullYear();
    const month = padZero(date.getUTCMonth() + 1);  // Months are zero-based, so add 1
    const day = padZero(date.getUTCDate());
    const hours = padZero(date.getUTCHours());
    const minutes = padZero(date.getUTCMinutes());
    const seconds = padZero(date.getUTCSeconds());
    // Concatenate the date components into the desired format
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  // what to do with selected feature make tables and inputs
  useSelect() {
    //
    console.log('feature selected:', this.feature)
    //
    if (this.feature) {
      this.tables.classList.remove('d-none')
      const properties = this.feature.getProperties()
      Object.keys(properties).forEach(key => {
        const tdElement = this.propertiesTable.querySelector(`.${key}`)
        let value = properties[key]
        if (key === 'calibration_date') value = value.split('T')[0]
        if (value === true) value = 'Da'
        if (value === false) value = 'Ne'
        if (tdElement) { tdElement.textContent = value; }//auxTable
        const tdAuxElement = this.auxTable.querySelector(`.${key}`)
        if (tdAuxElement) { tdAuxElement.textContent = value; }
        // add diameter_name to input table
        if (key === 'diameter_name') {
          this.inputDiameterName.value = value || '1/2'
        }
      })
      const lonLat = toLonLat(this.feature.getGeometry().getCoordinates())
      const tdAuxLatLonElement = this.auxTable.querySelector('.point_geometry')
      tdAuxLatLonElement.textContent = `${lonLat[1].toFixed(4)},${lonLat[0].toFixed(4)}`
      // add diameter_name to input table

    } else {
      this.tables.classList.add('d-none') // hide tables
    }

  }

  // inputs validation
  validation() {
    let isValid = true
    // not a number || negative number
    const lastReadInput = this.isOld.querySelector('.last_read')
    const intValue = parseInt(lastReadInput.value)
    if (isNaN(intValue) || intValue < 0) {
      lastReadInput.classList.add('is-invalid');
      isValid = false
    } else {
      lastReadInput.classList.remove('is-invalid');
    }
    const codeNameInput = this.isOld.querySelector('.code_name')
    if (codeNameInput.value === '') {
      codeNameInput.classList.add('is-invalid');
      isValid = false
    } else {
      codeNameInput.classList.remove('is-invalid');
    }
    const meterValueInput = this.isOld.querySelector('.meter_value')
    const meterValue = parseInt(meterValueInput.value)
    if (isNaN(meterValue) || meterValue < 0) {
      meterValueInput.classList.add('is-invalid');
      isValid = false
    } else {
      meterValueInput.classList.remove('is-invalid');
    }
    // empty string
    const workerNameInput = this.isOld.querySelector('.worker_name');
    if (workerNameInput.value === '') {
      workerNameInput.classList.add('is-invalid');
      isValid = false
    } else {
      workerNameInput.classList.remove('is-invalid');
    }
    return isValid
  }

  // set style of selected feature
  setStyleSelected() {
    return (feature, resolution) => {
      const minResolution = 0; // Closest zoom level
      const maxResolution = 1; // Approximate resolution for 1:4000 scale
      const oldStyle = new Style({
        image: new Circle({
          radius: 14,
          fill: new Fill({ color: 'rgba(255, 0, 0, 0.4)' }),
          stroke: new Stroke({ color: 'white', width: 3 })
        }),
      });
      const newStyle = new Style({
        image: new Circle({
          radius: 10,
          fill: new Fill({ color: 'rgba(0, 255, 0, 0.5)' }),
          stroke: new Stroke({ color: 'white', width: 3 })
        }),
      });

      if (resolution < minResolution || resolution > maxResolution) {
        return null; // Hide features outside the desired scale range
      }

      const isOld = feature.get('isOld');
      return isOld ? oldStyle : newStyle;
    }
  }

  // set style for thematic layer on isOld true/false property
  setStyle() {
    // Define the style function directly
    const styleFunction = (feature, resolution) => {
      const minResolution = 0;
      const maxResolution = this.resolution; // Approximate resolution for 1:4000 scale
      // Define styles based on the feature's property
      const oldStyle = new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({ color: 'red' }),
        }),
      });
      const newStyle = new Style({
        image: new Circle({
          radius: 5,
          fill: new Fill({ color: 'green' }),
        }),
      });
      // Return null if the resolution is out of bounds
      if (resolution < minResolution || resolution > maxResolution) {
        return null; // No style applied outside the desired resolution range
      }
      // Check the feature's 'isOld' property to determine the style
      const isOld = feature.get('isOld');
      return isOld ? oldStyle : newStyle;
    };

    // Apply the style function to the layer or feature source
    this.meters.setStyle(styleFunction);
  }

  // add & remove feature property (isOld,true) for non calibrated meters or meter if parameter feature is present
  addIsOldProperty(feature) {
    const currentDate = new Date();
    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(currentDate.getFullYear() - 5);
    if (!feature) {
      this.meters.getSource().getFeatures().forEach(feature => {
        const calibrationDateStr = feature.get('calibration_date');
        const calibrationDate = new Date(calibrationDateStr);
        feature.set('isOld', calibrationDate < fiveYearsAgo);
      });
    } else {
      const calibrationDateStr = feature.get('calibration_date');
      const calibrationDate = new Date(calibrationDateStr);
      feature.set('isOld', calibrationDate < fiveYearsAgo);
    }
  }
  removeIsOldProperty() {
    this.meters.getSource().getFeatures().forEach(feature => {
      feature.unset('isOld')
    })
  }

  // delete empty spaces in 'diameter_name' of all features
  trimDN() {
    this.meters.getSource().getFeatures()
      .forEach(x => x.set('diameter_name', x.get('diameter_name').trim()))
  }
}