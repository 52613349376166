{
    "tilesErrAlert":"Greška kod učitavanja sloja:",
    "underlay":"Podloga",
    "water":"Vodoopskrba",
    "sewage":"Odvodnja",
    "gas":"Plin",
    "zoomOut": "Udaljiti prikaz",
    "zoomIn": "Približiti prikaz",
    "rotate": "Resetirati rotaciju",
    "link":"Dodati/ukloniti trajnu vezu",
    "logoff": "Odjava",
    "adjust": "Prilagoditi",
    "pipes": "Cjevovod",
    "junctions": "Spoj",
    "tanks": "Vodospremnik",
    "reservoirs": "Izvorište",
    "valves": "Ventil",
    "gates": "Zasun",
    "buildings": "Područja zgrada",
    "protective_pipe": "Zaštitna cijev",
    "sensors": "Senzor",
    "pumps": "Pumpa",
    "dma": "Mjerna zona",
    "meters": "Mjerno mjesto",
    "hydrants":"Hidrant",
    "hydrant_lines":"Hidrantski vod",
    "saddles":"Priključno sedlo",
    "searchPlaceHolder": "Ime objekta ili toponim",
    "configEditorTitle": "Svojstva IMAGIS-a",
    "configEditorClose": "Napustiti",
    "configEditorApplyChanges": "Primijeniti",
    "configEditorUndoChanges": "Vratiti",
    "SdfConverterTitle": "Pretvarač MapGuide izvora podataka u GeoJson",
    "SdfConverterApply": "Primjeniti",
    "SdfConverterClose": "Napustiti",
    "SdfConvertMenuText": "MapGuide SDF",
    "SdfConverterMenuText": "MapGuide SDF",
    "SdfConverterResourceId": "MapGude Resource ID (putanja iz Maestra)",
    "metersCmmsTitle": "Održavanje vodomjera",
    "metersCmmsHideIsOldFeatures":"Sakriti kalibrirane vodomjere",
    "meterCmmsInputTableHeader":"Upis kod zamjene vodomjera",
    "meterCmmsWarningPositiveNumber":"Broj mora biti veći od 0",
    "meterCmmsWarningNumberOver30":"Broj je neispravan ili je veći od očitanja + 30m3",
    "meterCmmsWarningRequired":"Obavezno polje",
    "metersCmmsNearistSelector":"Odabrati broj potrošnog mjesta",
    "metersCmmsNewCodeName":"Serijski broj novog vodomjera",
    "metersCmmsNewCalibrationDate":"Datum na novom vodomjeru",
    "metersCmmsNewCalibrationDateAddYear":"Dodati godinu",
    "metersCmmsNewCalibrationDateSubtractYear":"Oduzeti godinu",
    "metersCmmsNewRead":"Početno stanje na novom vodomjeru",
    "metersCmmsSelect": "Odabir na osnovu položaja središta karte",
    "metersCmmsMore":"Više",
    "metersCmmsLess":"Manje",
    "metersCmmsSelectedProperties":"Svojstva odabranog",
    "meterCmmsSucess":"Vaši podaci su uspješno poslani",
    "point_code": "Br. potrošnog mjesta",
    "hodograph_id":"Br. u hodogramu",
    "partner_name":"Naziv partnera",
    "address_name":"Adresa",
    "code_name": "Br. vodomjera",
    "diameter_name": "DN vodomjera",
    "calibration_date": "Datum kalibracije",
    "meter_value": "Zadnje očitanje",
    "has_sewer":"Kanalizacija da/ne",
    "has_hydrant":"Hidrant da/ne",
    "worker_name": "Radnik",
    "last_read":"Zadnje stanje na starom vodomjeru",
    "point_geometry":"Koordinate",
    "replacement_date":"Datum zamjene",
    "record_date":"Datum zamjene",
    "note_content":"Napomena",
    "coordinates":"Koordinate",
    "metersCmmsSave": "Spremiti",
    "telemetryTitle": "Mjerenja",
    "telemetrySelectSensor": "Odabrati senzor...",
    "telemetryDateFrom": "Od",
    "telemetryDateTo": "Do",
    "telemetryDownloadMeasures": "Preuzeti mjerenja",
    "telemetryStateStart": "Početno stanje",
    "telemetryStateEnd": "Završno stanje",
    "telemetryMeanValue": "Srednja vrijednost",
    "telemetryStateDiff": "Razlika",
    "telemetryMin": "Minimalni protok",
    "telemetryMax": "Maksimalni protok",
    "cadastreTitle": "Pretraga katastra",
    "cadastreBasic": "Osnovno",
    "cadastreUse": "Uporaba",
    "cadastreOwner": "Upisane osobe",
    "cadastreParcelNo": "Upisati broj katastarske čestice",
    "cadastreNoParcel": "Katastarska čestica ne postoji",
    "cadastreOffice": "Područni ured za katastar",
    "cadastreSector": "Odjel za katastar nekretnina",
    "cadastreParcelNumber": "Broj katastarske čestice",
    "cadastreCadMunicipalityRegNumName": "Katastarska općina",
    "cadastrePossessionSheetNumber": "Posjedovni list",
    "cadastreOwnerSheetNumber": "Vlasnički list",
    "cadastrePossessionSheetCount": "Broj detaljnih listova",
    "cadastreOKN": "OKN ",
    "cadastreKc": "kč ",
    "cadastreKo": "ko ",
    "cadastreCopyToClipboard": "Kopirati u međuspremnik",
    "cadastreCopied": "Kopirano",
    "cadastreAddress": "Adresa",
    "cadastreName": "Naziv",
    "cadastreType": "Vrsta",
    "cadastreOwnership": "Vlasništvo",
    "cadastreArea": "Površina",
    "cadastreLrUnitNumber":"ZK uložak br.",
    "propertiesTitle":"Svojstva",
    "propertiesSelectMulti":"Svi u odabranoj točki",
    "propertiesSelectMethod":"Višestruki odabir",
    "propertiesNoSelect":"Odabrati...",
    "propertiesLayersSelect":"Prostorni sloj",
    "propertiesProperty":"Svojstvo",
    "propertiesValue":"Vrijednost",
    "propertiesVaries":"*VARIRA*",
    "entity_name": "Naziv",
    "reservoir_name": "Izvorište",
    "hydraulic_head": "Hidraulička visina",
    "is_active": "U radu",
    "source_date": "Datum upisa",
    "source_name":"Naziv izvora",
    "elevation": "Visina",
    "network_name": "Mreža",
    "category_name": "Kategorija",
    "description_name": "Opis",
    "origin_name": "Izvor podatka",
    "author_name": "Autor podataka",
    "accuracy_name": "Točnost podataka",
    "build_year": "Godina izgradnje",
    "altitude": "H_mnm",
    "junction_name": "Spoj",
    "material_name": "Materijal",
    "zone_name": "Lokacija",
    "authority_name": "Lokalna uprava",
    "chamber_tally": "Broj komora",
    "max_level": "Max. razina",
    "min_level": "Min. razina",
    "tank_name": "Vodospremnik",
    "volume": "Volumen",
    "is_manhole": "U oknu",
    "nominal_diameter": "DN",
    "pressure": "PN",
    "terrain_elevation": "Kota terena",
    "valve_name": "Ventil",
    "pipe_name": "Cijev",
    "sensor_code": "Kod",
    "sensor_id": "ID",
    "sensor_name": "Mjerenje",
    "unit_name": "Jedinica",
    "capacity": "Protok",
    "max_head": "Visina dobave",
    "pump_name": "Pumpa",
    "suction_head": "NPSH",
    "areas":"Građevine i površine",
    "area_name": "Ime zone",
    "area_id": "Broj zone",
    "area_id_in": "Broj zone ulaz",
    "area_id_out": "Broj zone izlaz",
    "meter_code": "Broj mjernog mjesta",
    "meter_id":"ID",
    "unknown":"Nepoznato",
    "print":"Tisak",
    "printTitle":"Tisak",
    "printPortrait":"Portret",
    "printLandscape":"Krajolik",
    "printPageSize":"Format papira",
    "printFormatInfo":"Vrijeme potrebno za tisak pri kvalitetnijoj slici može potrajati...",
    "printMargins":"Margine",
    "printMarginNone":"Bez margina",
    "printMarginSmall":"Male margine",
    "printMarginLarge":"Velike margine",
    "printScale":"Mjerilo",
    "printScalePlaceholder":"Unesi mjerilo",
    "printDpiScreen":"Rezolucija ekrana",
    "printDpiDoubleScreen":"Dvostruka rezolucija ekrana",
    "printDpiPrint":"Rezolucija za tisak",
    "printDpi":"Kvaliteta slike",
    "printSaveToJpeg":"Preuzmi JPEG",
    "printSaveToPng":"Preuzmi png",
    "printSaveToPdf":"Preuzmi pdf",
    "printSaveAs":"Preuzmi sliku kao",
    "printInfo":"Radimo na tisku...",
    "printCorsError":"Security error: Tisak nije moguć uslijed CORS politike DGU.",
    "printError":"Greška",
    "styleEditorTitle":"Uređenje stila",
    "legendTitle":"Legenda"
}