import LayerGroup from "ol/layer/Group.js";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile.js";
import XYZ from 'ol/source/XYZ';
import TileWMS from "ol/source/TileWMS.js";
import BingMaps from "ol/source/BingMaps";
import Collection from 'ol/Collection.js';

export class TileGroup extends LayerGroup {
  constructor(options = {}) {
    options.name = options.name || "tiles";
    super({ ...options });
    this.edcDofUrl = options.edcDofUrl;
    this.edcKcUrl = options.edcKcUrl;
    this.setLayers(this.createLayers(options))
  }

  createLayers(options) {
    return new Collection([
      this.osmLayer(options),
      this.bingLayer(options),
      this.dofLayerSeperate(options),
      new LayerGroup({
        name: "DGU KP",
        permalink: "kp",
        visible: options.visible === "kp",
        layers: [
          this.dofLayer(options),
          this.kcLayer(options)
        ]
      })
    ]);
  }

  // layers
  osmLayer(options) {
    return new TileLayer({
      name: "OSM",
      permalink: "osm",
      cacheSize: 2048,
      visible: options.visible === "osm",
      source: new OSM()
    });
  }

  bingLayer(options) {
    return new TileLayer({
      name: "Bing maps",
      permalink: "bing",
      visible: options.visible === "bing",
      source: new BingMaps({
        placeholderTiles: false,
        key: "Asy8J51JJ7llyF3k35bhsNUUXZpJ1Sx2XM1AVrVlm1fffX_V-k68gi8VtjOrM68b",
        imagerySet: "Aerial",
      })
    });
  }

  dofLayerSeperate(options) {
    return new TileLayer({
      name: "DOF",
      permalink: "dof",
      visible: options.visible === "dof",
      source: this.dguDofSource()
    });
  }

  dofLayer(options) {
    return new TileLayer({
      visible: true,
      source: this.dguDofSource()
    });
  }

  kcLayer(options) {
    return new TileLayer({
      visible: true,
      minZoom: 14,
      source: this.dguKcSource()
    });
  }

  // sources
  dguDofSource() {
    if (this.edcDofUrl) {
      return new XYZ({ 
        attributions:'<a href="https://dgu.gov.hr/" target="_blank">© Državna Geodetska Uprava</a>',
        url: this.edcDofUrl ,
        maxZoom:18,
        crossOrigin: "anonymous"
      });
    }
    return new TileWMS({
      attributions:'<a href="https://dgu.gov.hr/" target="_blank">© Državna Geodetska Uprava</a>',
      url: 'https://geoportal.dgu.hr/wms?',
      params: { layers: 'DOF_TOPONIMI' },
      crossOrigin: "anonymous"
    });
  }

  dguKcSource() {
    if (this.edcKcUrl) {
      return new XYZ({ 
        attributions:'<a href="https://dgu.gov.hr/" target="_blank">© Državna Geodetska Uprava</a>',
        url: this.edcKcUrl ,
        crossOrigin: "anonymous",
        maxZoom:18
      });
    }
    return new TileWMS({
      attributions:'<a href="https://dgu.gov.hr/" target="_blank">© Državna Geodetska Uprava</a>',
      url: "https://api.uredjenazemlja.hr/services/inspire/cp_wms/ows?",
      params: { layers: "cp:CP.CadastralParcel" },
      crossOrigin: "anonymous"
    });
  }
}

