{
    "controls": "controls",
    "resources": "resources",
    "properties": "properties",
    "geostyle": "geostyle",
    "telemetry": "rpc/get_telemetry",
    "meter": "rpc/get_measurement/",
    "meters": "meters",
    "meters_all": "meters_all",
    "meters_csv": "meters_csv",
    "metersDma": "rpc/meters_dma",
    "sensorsArea": "rpc/set_meter_area/",
    "assets": "assets/",
    "cadastre": "cadastre",
    "meters_export": "meters_export",
    "telemetry_test": "telemetry_test",
    "upload_csv": "rpc/new_csv_data"
}