import Control from 'ol/control/Control';
import TileLayer from 'ol/layer/Tile';
import LayerGroup from 'ol/layer/Group';
import api from '../config/api.json'; // Import the API configuration directly
import { createPopper } from '@popperjs/core'

export class TileSwitcher extends Control {
    constructor(options = {}) {
        const element = document.createElement('div');
        element.className = 'tile-switcher ol-control ol-unselectable dropdown';
        super({
            target: options.target,
            element
        });

        // Store the icon configuration passed during initialization
        this.iconMap = options.iconMap || {
            'OSM': `osm.png`,
            'Bing': `bing.png`,
            'DGU DOF': `dof.png`,
            'DGU KP': `cp.png`,
        };
        // Create the dropdown structure
        element.innerHTML = `
                <button class="btn btn-light d-flex align-items-center justify-content-center p-0" type="button" id="layersDropdown" data-bs-toggle="dropdown">
                    <img src="${api.assets}${this.iconMap['dof'] || 'bing.png'}" style="width: 90%; height: 90%;">
                </button>
                <ul class="dropdown-menu">
                </ul>
           
        `;

        // Dropdown hover functionality
        const dropdownMenu = element.querySelector('.dropdown-menu');
        const popperInstance = createPopper(element, dropdownMenu, {
            placement: 'bottom-start',
            modifiers: [{
                name: 'flip',
                options: {
                    fallbackPlacements: ['top', 'right', 'left'],
                },
            }],
        });
        let timeout;
        element.addEventListener('mouseenter', () => {
            clearTimeout(timeout);
            dropdownMenu.classList.add('show');
            popperInstance.update();
        });
        element.addEventListener('mouseleave', () => {
            timeout = setTimeout(() => {
                dropdownMenu.classList.remove('show');
            }, 300);
        });
        dropdownMenu.addEventListener('mouseenter', () => {
            clearTimeout(timeout);
        });
    }

    setMap(map) {
        // If the control is being removed, clean up
        if (this.map && !map) {
            this.cleanup();
        }
        super.setMap(map);
        this.map = map;
        // Populate the dropdown with initial layers
        if (this.map) {
            this.populateDropdown(this.element.querySelector('.dropdown-menu'));
        }
    }

    populateDropdown(dropdownMenu) {
        dropdownMenu.innerHTML = ''; // Clear existing items
        const tilesGroup = this.getTilesGroup();
        if (tilesGroup) {
            tilesGroup.getLayers().forEach((layer) => {
                if (layer && (layer instanceof TileLayer || layer instanceof LayerGroup)) {
                    this.addLayerToDropdown(layer, dropdownMenu);
                }
            });
            // Mark the currently visible layer
            this.updateDropdownVisibility();
        }
    }

    getTilesGroup() {
        let tilesGroup = null;
        if (!this.map || !this.map.getLayers()) return null; // Check if map and layers exist
        this.map.getLayers().forEach((layer) => {
            if (layer instanceof LayerGroup && layer.get('name') === 'tiles') {
                tilesGroup = layer;
            }
        });
        return tilesGroup;
    }

    addLayerToDropdown(layer, dropdownMenu) {
        const layerName = layer.get('name');
        if (layerName) {
            const listItem = document.createElement('li');
            listItem.innerHTML = `
                <a class="dropdown-item" href="#" data-layer="${layerName}">
                    <img src="${this.getIconForLayer(layerName)}" alt="${layerName} Layer">
                    ${layerName}
                </a>
            `;
            dropdownMenu.appendChild(listItem);
            listItem.querySelector('.dropdown-item').addEventListener('click', (e) => {
                e.preventDefault();
                this.toggleLayerVisibility(layer);
            });
        }
    }

    updateDropdown() {
        const dropdownMenu = this.element.querySelector('.dropdown-menu');
        this.populateDropdown(dropdownMenu); // Repopulate dropdown with current layers
    }

    getIconForLayer(layerName) {
        // Prepend the api.assets path to the icon filenames
        return `${api.assets}${this.iconMap[layerName] || 'bing.png'}`;
    }

    toggleLayerVisibility(selectedLayer) {
        const tilesGroup = this.getTilesGroup();
        if (tilesGroup) {
            tilesGroup.getLayers().forEach((layer) => {
                // Provjeri da li je sloj ili LayerGroup, i postavi samo njegovu vidljivost
                if (layer === selectedLayer) {
                    layer.setVisible(true);  // Postavi odabrani sloj kao vidljiv
                } else {
                    layer.setVisible(false); // Sakrij sve ostale slojeve
                }
            });
            // Ažuriraj dropdown kako bi prikazao trenutno vidljivi sloj
            this.updateDropdownVisibility();
        }
    }
    setGroupVisibility(group, isVisible) {
        group.setVisible(isVisible);
        group.getLayers().forEach((subLayer) => {
            subLayer.setVisible(isVisible);
        });
    }
    updateDropdownVisibility() {
        const tilesGroup = this.getTilesGroup();
        if (tilesGroup) {
            const dropdownMenu = this.element.querySelector('.dropdown-menu');
            const items = dropdownMenu.querySelectorAll('.dropdown-item');

            items.forEach((item) => {
                const layerName = item.getAttribute('data-layer');
                const layer = this.getLayerByName(layerName);

                if (layer && this.isLayerVisible(layer)) {
                    // Mark the item as selected 
                    item.style.fontWeight = 'bold';
                    item.style.backgroundColor = '#f0f0f0';
                    item.style.color = '#007bff';
                } else {
                    // Reset the style for non-selected items
                    item.style.fontWeight = 'normal';
                    item.style.backgroundColor = '';
                    item.style.color = '';
                }
            });
        }
    }

    getLayerByName(name) {
        const tilesGroup = this.getTilesGroup();
        if (tilesGroup) {
            let targetLayer = null;
            tilesGroup.getLayers().forEach((layer) => {
                if (layer.get('name') === name) {
                    targetLayer = layer;
                }
            });
            return targetLayer;
        }
        return null;
    }

    isLayerVisible(layer) {

        return layer.getVisible();
    }
    cleanup() {
        // Remove event listeners
        const dropdown = this.element.querySelector('.layer-switcher');
        const dropdownMenu = this.element.querySelector('.dropdown-menu');
        dropdown.removeEventListener('mouseenter', this.showDropdownMenu.bind(this));
        dropdown.removeEventListener('mouseleave', this.scheduleHideDropdownMenu.bind(this));
        dropdownMenu.removeEventListener('mouseenter', this.clearDropdownTimeout.bind(this));
        // Clear the dropdown menu
        dropdownMenu.innerHTML = '';
    }
}
