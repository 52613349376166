import locale from "../lang/hr.json";
import Map from "ol/Map.js";
import View from "ol/View.js";
import VectorSource from "ol/source/Vector.js";
import VectorLayer from "ol/layer/Vector.js";
import { TileGroup } from './tileGroup.js'
import { Circle, Icon, RegularShape } from "ol/style";
import jsPDF from "jspdf";
import { IMAGIS } from '../index.js';

// alternative: https://github.com/camptocamp/inkmap

/* example p value:
bbox: [1837764.758587633, 5347224.169654297, 1862999.1700350929, 5383541.950203108]
contentType: "image/png"
dpi: "300"
format: "A4"
formatDim: {width: 210, height: 297}
map: Map 
margin: "5"
orientation: "portrait"
resolution: 76.43702828517625
scale: "288896"
*/

export function print(p) {
  document.body.style.cursor = "wait";
  const target = generateTarget(p);
  const map = generateMap(p, target);

  map.getView().fit(p.bbox, {
    callback: render(map, p, target),
    padding: Array(4).fill((p.margin / 25.4) * 96),
  });
}
function render(map, p, target) {
  map.once("rendercomplete", () => {
    const canvas = getCanvas(map, p);
    const margin = (p.margin / 25.4) * 96;
    if (["image/png", "image/jpeg"].includes(p.contentType)) {
      try {
        canvas.toBlob((blob) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.href = url;
          link.download = `imagis-map.${p.contentType.split("/")[1]}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          target.remove();
          document.body.style.cursor = "default";
          p.map.renderSync()
        });
      } catch (error) {
        if (error.name === "SecurityError") {
          alert(`${locale.printCorsError}`);
        } else {
          alert(`${locale.printError} ${error}`);
        }
      }
    }
    if (p.contentType === "application/pdf") {
      try {
        canvas.toBlob((blob) => {
          const img = new Image();
          const url = URL.createObjectURL(blob);
          img.src = url;
          img.onload = () => {
            const pdf = new jsPDF({
              orientation: p.orientation,
              unit: "px",
              format: [img.width + 2 * margin, img.height + 2 * margin],
            });
            pdf.addImage(img, "PNG", margin, margin, img.width, img.height);
            pdf.save("imagis-map.pdf");
            URL.revokeObjectURL(url);
            target.remove();
            document.body.style.cursor = "default";
            p.map.renderSync()
          };
        });
      } catch (error) {
        if (error.name === "SecurityError") {
          alert(`${locale.printCorsError}`);
        } else {
          alert(`${locale.printError} ${error}`);
        }
      }
    }
  });
}
function getCanvas(map, p) {
  const mapCanvas = document.createElement("canvas");
  mapCanvas.width = map.getSize()[0];
  mapCanvas.height = map.getSize()[1];
  const mapContext = mapCanvas.getContext("2d");
  Array.prototype.forEach.call(
    document.querySelectorAll(".ol-layer canvas"),
    function (canvas) {
      if (canvas.width > 0) {
        const opacity = canvas.parentNode.style.opacity;
        mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
        const transform = canvas.style.transform;
        // Get the transform parameters from the style's transform matrix
        const matrix = transform
          .match(/^matrix\(([^\(]*)\)$/)[1]
          .split(",")
          .map(Number);
        // Apply the transform to the export map context
        CanvasRenderingContext2D.prototype.setTransform.apply(
          mapContext,
          matrix
        );
        mapContext.drawImage(canvas, 0, 0);
      }
    }
  );
  mapContext.globalAlpha = 1;
  mapContext.setTransform(1, 0, 0, 1, 0, 0);
  return mapCanvas;
}
function generateMap(p, target) {
  const map = new Map({
    target,
    view: new View({
      projection: p.map.getView().getProjection(),
      center: p.map.getView().getCenter(),
      resolution: p.resolution,
      rotation: 0,
      enableRotation: false,
    }),
    controls: [],
    interactions: [],
    layers: getLayers(p)
  });
  return map;
}
function getLayers(p) {
  const tileLayer = p.map
    .getLayers().getArray().find(x => x.get('type') === 'tile')
  const newTiles = new TileGroup({
    edcDofUrl: IMAGIS.properties.edcDofUrl,
    edcKcUrl: IMAGIS.properties.edcKcUrl
  }).getLayers().getArray()
  const newTileLayer = newTiles.find(x => x.get('name') === tileLayer.get('name'))
  newTileLayer.setVisible(true)
  const vectorLayers = p.map
    .getAllLayers()
    .filter((x) => x instanceof VectorLayer);
  const newLayers = [newTileLayer];
  vectorLayers.forEach((x) => {
    const features = x.getSource().getFeatures();
    const style = adjustStyleForResolution(x.getStyle(), p);
    const visible = x.getVisible();
    newLayers.push(
      new VectorLayer({
        source: new VectorSource({ features }),
        style: style,
        visible: visible,
      })
    );
  });
  return newLayers;
}
function adjustStyleForResolution(style, p) {
  const scale = p.dpi / 96;
  if (typeof style === "function") {
    return (feature, resolution) => {
      let style_ = style(feature, resolution);
      if (!style_) return null;
      calculateStyle(style_, scale);
      return style_;
    };
  }
  return calculateStyle(style, scale);
}
function calculateStyle(style, scale) {
  if (!Array.isArray(style)) style = [style];
  style.forEach((s) => {
    if (!s) return;
    const stroke = s.getStroke();
    if (stroke) stroke.setWidth(stroke.getWidth() * scale);
    const text = s.getText();
    if (text) {
      text.setFont(calculateFontSize(text.getFont(), scale));
      if (text.getOffsetX()) text.setOffsetX(text.getOffsetX() * scale);
      if (text.getOffsetY()) text.setOffsetY(text.getOffsetY() * scale);
      if (text.getScale()) text.setScale(text.getScale() * scale);
      if (text.getStroke())
        text.getStroke().setWidth(text.getStroke().getWidth() * scale);
      if (text.getPadding())
        text.setPadding(text.getPadding().map((p) => p * scale));
      if (text.getBackgroundStroke())
        text
          .getBackgroundStroke()
          .setWidth(text.getBackgroundStroke().getWidth() * scale);
    }
    const image = s.getImage();
    if (
      image instanceof Icon ||
      image instanceof Circle ||
      image instanceof RegularShape
    ) {
      if (image.getDisplacement())
        image.setDisplacement(image.getDisplacement().map((d) => d * scale));
      if (image.getScale()) image.setScale(image.getScale() * scale);
    }
    if (image instanceof Circle || image instanceof RegularShape) {
      if (image.getStroke())
        image.getStroke().setWidth(image.getStroke().getWidth() * scale);
    }
  });
  return style;
}
function calculateFontSize(font, scale) {
  const fontSizeRegex = /(\d+)px/;
  const match = font.match(fontSizeRegex);
  if (match) {
    const fontSize = parseInt(match[1]);
    const newFontSize = fontSize * scale;
    return font.replace(fontSizeRegex, `${newFontSize}px`);
  }
  return font;
}
function generateTarget(p) {
  const paperWidthPixels = (p.formatDim.width / 25.4) * p.dpi;
  const paperHeightPixels = (p.formatDim.height / 25.4) * p.dpi;
  const target = document.createElement("div");
  target.className = "print-map-target";
  target.style.cssText = `width:${paperWidthPixels}px;height:${paperHeightPixels}px;`;
  document.body.appendChild(target);
  return target;
}
