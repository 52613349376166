import locale from "../lang/hr.json";
import Control from "ol/control/Control";
import OpenLayersParser from 'geostyler-openlayers-parser'
import locale from '../lang/hr.json'
import { IMAGIS } from '../index.js';

export class StyleEditor extends Control {
  constructor(options = {}) {
    const element = document.createElement("div");
    element.className = "style-editor";
    element.innerHTML = `
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#style-editor-accordion-item">
           <i class="fa-regular fa-palette" style="margin-right: 5px;"></i> ${locale.styleEditorTitle}
           </button>
        </h2>
        <div id="style-editor-accordion-item" class="accordion-collapse collapse ${options.show ? 'show' : ''}" data-bs-parent="#index-accordion">
          <div class="accordion-body px-1">
          Uredi stil karte</div>
        </div>`;

    super({
      target: options.target,
      element
    })
    this.body = this.element.querySelector('.accordion-body')
    this.parser = new OpenLayersParser()
    //icon canvas dimensions
    this.size = { width: 50, height: 40 }
    this.state = {
      files: [],
      index: 0,
      ruleIndex: 0,
      symbolizerIndex: 0,
      textareaDisplay: false
    }
  }
  setMap(map) {
    super.setMap(map)
    this.map = map
    this.state.files = IMAGIS.properties.files
    this.renderHTML()
  }
  renderHTML() {
    this.body.replaceChildren(this.generateHTML())
    //this.attachEventListeners()
  }
  generateHTML() {
    const file = this.state.files[this.state.index]
    const layerSelector = document.createElement('select')
    layerSelector.className = 'form-select form-select-sm mb-2'
    layerSelector.id = 'style-editor-select'
    this.state.files.forEach(g => layerSelector.add(new Option(locale[g.name], g.name)))
    layerSelector.selectedIndex = this.state.index
    return layerSelector
  }

}